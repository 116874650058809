import React from 'react';
import { ReactComponent as StreakIcon } from '../../../images/icons/Fire_Streak.svg';
import styles from '../UserProgress.module.css';

// Login Streak Tile Component for Progress Page
export const LoginStreak = ({
  currentStreak
}) => {
  return (
    <div className={styles.streakTileContainer}>
      <div className={styles.streakTile}>
        <div className={styles.streakIconContainer}>
          <StreakIcon width={50} height={50} />
        </div>
        <div className={styles.streakContentContainer}>
          <span className={styles.streakLabel}>7-Day Login Streak</span>
          <div className={styles.progressBarContainer}>
            <div className={styles.streakProgressBar}>
              <div 
                className={styles.streakProgressFill}
                style={{ 
                  width: `${Math.min((currentStreak / 7) * 100, 100)}%`,
                  background: `linear-gradient(to right, ${'#4bbc4e'}, ${'#6BA543'})` // green, greenFlat
                }}
              />
            </div>
            <span className={styles.streakValue}>{currentStreak}/7</span>
          </div>
        </div>
      </div>
    </div>
  );
};
