import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styles from "./OrganizationDocumentsList.module.css";
import { FaTrash, FaArrowsAlt } from "react-icons/fa";
import { connect } from "react-redux";
import { portalApiMap } from "../../../../store/actions/portalAction.js";
import DocumentModal from "../../../documentModal/DocumentModal";
import { GeneralPagination } from "../../../pagination/generalPagination/generalPagination";


const OrganizationDocumentList = ({ portalApiMap, organizationId, selectedCategory }) => {
  const [documents, setDocuments] = useState([]);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [documentLabel, setDocumentLabel] = useState("");
  const [file, setFile] = useState(null);

  // Pagination states
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1); // total pages

  // When organizationId or selectedCategory changes, load page 1
  useEffect(() => {
    if (organizationId && selectedCategory) {
      fetchDocuments(1);
    }
  }, [organizationId, selectedCategory]);

  // Fetch documents including pagination
  const fetchDocuments = async (pageNumber = 1) => {
    try {
    
      const { getPortalOrganizationDocumentsByCategory } = await portalApiMap();
      // Pass limit (25) and the current page to the API route
      const response = await getPortalOrganizationDocumentsByCategory(
        organizationId,
        selectedCategory,
        25,
        pageNumber
      );
      console.log(response);
      
      if (
        response.data &&
        response.data.message === "SUCCESS" &&
        response.data.result
      ) {
        // Set documents from the API response
        setDocuments(response.data.result.documents || []);
        // Assume your API returns the total number of pages (or adjust accordingly)
        setPages(response.data.result.pages || 1);
        setPage(pageNumber);
      } else {
        setDocuments([]);
        setPages(1);
        setPage(pageNumber);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
      alert("Error fetching documents: " + error.message);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!documentLabel || !selectedCategory || !file) {
      alert("Please select a category, provide a document label, and upload a PDF.");
      return;
    }

    try {
      const { postUploadOrganizationDocument } = await portalApiMap();
      await postUploadOrganizationDocument(organizationId, documentLabel, selectedCategory, file);
      alert("Document uploaded successfully!");

      setUploadModalOpen(false);
      setDocumentLabel("");
      setFile(null);

      // Refresh the current page after upload
      fetchDocuments(page);
    } catch (error) {
      alert("Error uploading document: " + error.message);
    }
  };

  const handleDeleteDocument = async (documentId) => {
    if (!organizationId || !documentId) {
      alert("Invalid organization or document.");
      return;
    }

    if (!window.confirm("Are you sure you want to delete this document?")) return;

    try {
      const { postDeleteOrganizationDocument } = await portalApiMap();
      await postDeleteOrganizationDocument(organizationId, documentId);
      alert("Document deleted successfully!");
      setDocuments(documents.filter(doc => doc.document_id !== documentId));
    } catch (error) {
      console.error("Error deleting document:", error);
      alert("Error deleting document: " + error.message);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const updatedDocuments = Array.from(documents);
    const [movedItem] = updatedDocuments.splice(result.source.index, 1);
    updatedDocuments.splice(result.destination.index, 0, movedItem);

    setDocuments(updatedDocuments);

    // Extract new order of document IDs
    const documentIds = updatedDocuments.map(doc => doc.document_id);

    // Send sorted order to backend
    try {
      const { postSortOrganizationDocuments } = await portalApiMap();
      const response = await postSortOrganizationDocuments(organizationId, selectedCategory, documentIds);
      console.log(response);
    } catch (error) {
      console.error("Error updating document order:", error);
      alert("Error updating document order: " + error.message);
    }
  };

  // Handle pagination change (react-paginate gives 0-based index)
  const handlePageChange = (selectedItem) => {
    const newPage = selectedItem.selected + 1;
    fetchDocuments(newPage);
  };

  return (
    <div className={styles.documentsSection}>
      <h2 className={styles.header}>
        Documents
        <button
          className={styles.addButton}
          onClick={() =>
            selectedCategory ? setUploadModalOpen(true) : alert("Please select a category before uploading.")
          }
          disabled={!selectedCategory}
        >
          +
        </button>
      </h2>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="documentsList">
          {(provided) => (
            <div className={styles.documentsList} {...provided.droppableProps} ref={provided.innerRef}>
              {documents.map((doc, index) => (
                <Draggable key={doc.document_id} draggableId={doc.document_id.toString()} index={index}>
                  {(provided) => (
                    <div
                      className={styles.documentItem}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <span
                        className={styles.dragHandle}
                        {...provided.dragHandleProps}
                        role="button"
                        tabIndex="0"
                        aria-label="Reorder document"
                      >
                        <FaArrowsAlt />
                      </span>
                      
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.documentName}
                      >
                        {doc.label}
                      </a>

                      <button
                        className={styles.deleteButton}
                        onClick={() => handleDeleteDocument(doc.document_id)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Render pagination if there's more than one page */}
      {pages > 1 && (
        <div className={styles.paginationContainer}>

        <GeneralPagination forcePage={page - 1} pages={pages} onPageChange={handlePageChange} />
        </div>
      )}

      <DocumentModal
        modalOpen={uploadModalOpen}
        setModalOpen={setUploadModalOpen}
        title="Upload New Document"
        fields={[
          { label: "Document Label", type: "text", value: documentLabel, onChange: (e) => setDocumentLabel(e.target.value) },
          { label: "Upload PDF", type: "file", accept: "application/pdf", onChange: handleFileChange },
        ]}
        handleSubmit={handleUpload}
        submitLabel="Upload"
      />
    </div>
  );
};

export default connect(null, { portalApiMap })(OrganizationDocumentList);
