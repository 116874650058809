import React from "react";
import { PrimaryButton } from "../../buttons/buttons";
import styles from "./AssessmentOptions.module.css";
import headerImage from '../../../images/AssessmentLandingPage.png';
const AssessmentOption = (props) => {
  const { goToPage, setCreateLifestyle } = props;

  const handleDoItNowClick = () => {
    setCreateLifestyle(true);
    goToPage(3);
  };

  const handleDoItLaterClick = () => {
    setCreateLifestyle(false);
    goToPage(13);
  };
  return (
    <div>
      <div className={styles.imgContainer}>
        {" "}
        <img
          src={headerImage}
          alt="Coming Soon"
        />
      </div>

        <p className={styles.alignCenter}>
        Discover your healthy lifestyle by getting a snapshot of your current habits. Take the 20-question lifestyle assessment now to learn more about what habits impact your health and where to start on your wellbeing journey.
        </p>



      <div className={styles.btnContainer}>
        <PrimaryButton
          style={{ fontSize: "14px" }}
          onClick={handleDoItNowClick}
          data-testID="startAssesmentButton"
        >
          Do it Now
        </PrimaryButton>

        <PrimaryButton
          style={{ fontSize: "14px" }}
          onClick={handleDoItLaterClick}
          data-testID="startAssesmentButton"
        >
          Do it Later
        </PrimaryButton>
      </div>
    </div>
  );
};

export default AssessmentOption;
