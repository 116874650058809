import React, { useState, useEffect, Suspense, lazy } from "react";

// routes
import {
  fetchPortalCustomerActiveUsers,
  fetchPortalCustomerActiveUsersTrends,
  fetchCustomerDashboardMessage,
} from "./../routes/DashboardPortalRoutes";

// redux
import { portalApiMap } from "../../../store/actions/portalAction";
import { connect } from "react-redux";

// utils
import useDebouncedEffect from "./../utils/useDebouncedEffect";
// components
import Loading from "../../Loading/Loading";

const DashboardHeader = lazy(() => import("./../components/DashboardHeader"));
const PortalBarChart = lazy(() => import("./../components/PortalBarChart"));
const DateRange = lazy(() => import("./../components/DateRange"));

const PortalDashboardAnalytics = ({ portalApiMap, location }) => {
  // Default date range: Last 30 days
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(new Date());

  // Data states for dashboard panels
  const [activeUsersData, setActiveUsersData] = useState([]);
  const [activeUsersTrends, setActiveUsersTrends] = useState(null);

  const [dashboardMessage, setDashboardMessage] = useState(null);

  // Global loading and error states
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRange, setSelectedRange] = useState("Last 30 Days");
  const [loading, setLoading] = useState(false);

  const debounceDelay = selectedRange === "Custom Range" ? 1200 : 0;

  // -------------------------
  // Group 1: Active Users & Trends (debounced)
  // -------------------------
  useDebouncedEffect(
    () => {
      setIsLoading(true);
      (async () => {
        try {
          const [activeUsers, trends] = await Promise.all([
            fetchPortalCustomerActiveUsers(
              portalApiMap,
              startDate.toISOString(),
              endDate.toISOString()
            ),
            fetchPortalCustomerActiveUsersTrends(
              portalApiMap,
              startDate.toISOString(),
              endDate.toISOString()
            ),
          ]);
          setActiveUsersData(Array.isArray(activeUsers) ? activeUsers : []);
          setActiveUsersTrends(trends);
        } catch (err) {
          console.error("Error fetching active users/trends:", err);
        } finally {
          setIsLoading(false);
        }
      })();
    },
    [selectedRange, startDate, endDate, portalApiMap],
    debounceDelay
  );

  // -------------------------
  // Group 5: Fetch Dashboard Message on route change
  // -------------------------
  useEffect(() => {
    const getDashboardMessage = async () => {
      try {
        setLoading(true);
        const response = await fetchCustomerDashboardMessage(portalApiMap);
        setDashboardMessage(response);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching dashboard message:", err);
      }
    };
    getDashboardMessage();
  }, [portalApiMap, location.pathname]);

  return (
    <Suspense
      fallback={
        <div style={{ textAlign: "center", padding: "50px" }}>
          <Loading />
        </div>
      }
    >
      {loading ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <Loading />
        </div>
      ) : error ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          Error: {error.message || "Error loading data"}
        </div>
      ) : (
        <>
          {/* Dashboard Header */}
          {dashboardMessage &&
            dashboardMessage.content &&
            dashboardMessage.headline && (
              <DashboardHeader dashboardMessage={dashboardMessage} />
            )}

          <DateRange
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setActiveUsersData={setActiveUsersData}
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
          />
          <PortalBarChart
            startDate={startDate}
            endDate={endDate}
            activeUsersData={activeUsersData}
            trendsData={activeUsersTrends}
            error={error}
            isLoading={isLoading}
          />
        </>
      )}
    </Suspense>
  );
};

const actions = { portalApiMap };

export default connect(null, actions)(PortalDashboardAnalytics);
