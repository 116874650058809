import React from 'react';
import { format } from 'date-fns';
import SelectVictoryImage from '../../../components/selectVictoryImage/selectVictoryImage';
import RankMedal from '../../../components/rankMedal/rankMedal';
import Badge from "../../../components/badge/badge";
import { ReactComponent as MonthlyVictoriesIcon } from '../../../images/icons/Monthly_Victories_Icon.svg';
import { ReactComponent as MonthlyBadgeIcon } from '../../../images/icons/Monthly_Badge_Icon.svg';
import { ReactComponent as AllTimeRankIcon } from '../../../images/icons/All_Time_Rank_Icon.svg';
import styles from '../UserProgress.module.css';

const lockImage = 'https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Locked200x200.png';

// Achievement Square Tiles Component (Monthly Victories, Monthly Badge, All Time Rank)
export const AchievementSquareTiles = ({
  selectedDate,
  recentVictory,
  selectedMonthScore,
  currentBadge,
  user,
  onVictoryPress,
  rankProgress
}) => {
  return (
    <div className={styles.squareTileRow}>
      {/* Monthly Victories Tile*/}
      <div 
        className={styles.squareTile}
        onClick={onVictoryPress}
        style={{ cursor: 'pointer' }}
      >
        <div className={styles.iconHabitBackground} style={{ backgroundColor: 'rgba(255, 190, 0, 0.15)' }} />
        <div className={styles.iconHabit}>
          <MonthlyVictoriesIcon width={17} height={18} />
        </div>
        <div className={styles.achievementTitleContainer}>
          <span className={styles.achievementTitle}>{format(selectedDate, 'MMMM')}</span>
          <span className={styles.achievementTitle} style={{ fontWeight: 600 }}>Victories</span>
        </div>
        {recentVictory ? (
          <div className={styles.recentVictoryContainer}>
            <SelectVictoryImage 
              typeId={recentVictory.victory.victory_type.id}
              victory={recentVictory}
              size={58}
            />
          </div>
        ) : (
          <div className={styles.tileMessageContainer}>
            <svg width="18" height="18" viewBox="0 0 24 24" fill="#6c757d">
              <path d="M19 5h-2V3H7v2H5c-1.1 0-2 .9-2 2v1c0 2.55 1.92 4.63 4.39 4.94.63 1.5 1.98 2.63 3.61 2.96V19H7v2h10v-2h-4v-3.1c1.63-.33 2.98-1.46 3.61-2.96C19.08 12.63 21 10.55 21 8V7c0-1.1-.9-2-2-2zM5 8V7h2v3.82C5.84 10.4 5 9.3 5 8zm14 0c0 1.3-.84 2.4-2 2.82V7h2v1z"/>
            </svg>
            <span className={styles.tileMessageText}>No victories yet this month</span>
          </div>
        )}
        <div className={styles.carrotIconContainer}>
          <svg width="20" height="20" viewBox="0 0 24 24" fill="#6c757d">
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
          </svg>
        </div>
      </div>
      
      {/* Monthly Badge Tile */}
      <div className={styles.squareTile}>
        <div className={styles.iconHabitBackground} style={{ backgroundColor: 'rgba(255, 190, 0, 0.15)' }} />
        <div className={styles.iconHabit}>
          <MonthlyBadgeIcon width={22} height={21} />
        </div>
        <div className={styles.achievementTitleContainer}>
          <span className={styles.achievementTitle}>{format(selectedDate, 'MMMM')}</span>
          <span className={styles.achievementTitle} style={{ fontWeight: 600 }}>Badge</span>
        </div>
        <div className={styles.progressContainer}>
          <div className={styles.progressRow}>
            {selectedMonthScore < 1500 && (
              <img 
                src={lockImage}
                alt="Locked Badge"
                className={styles.lockBadge}
              />
            )}
          </div>
          <span className={styles.progressValue}>{selectedMonthScore}/1500</span>
          <div className={styles.progressBar}>
            <div 
              className={styles.progressFill}
              style={{ 
                width: `${Math.min((selectedMonthScore / 1500) * 100, 100)}%`,
                background: '#ffbf00'
              }}
            />
          </div>
        </div>
      </div>

      {/* All Time Rank Tile*/}
      <div className={styles.squareTile}>
        <div className={styles.iconHabitBackground} style={{ backgroundColor: 'rgba(255, 190, 0, 0.15)' }} />
        <div className={styles.iconHabit}>
          <AllTimeRankIcon width={20} height={19} />
        </div>
        <div className={styles.achievementTitleContainer}>
          <span className={styles.achievementTitle}>All Time</span>
          <span className={styles.achievementTitle} style={{ fontWeight: 600 }}>Rank</span>
        </div>
        <div className={styles.progressContainer}>
          <div className={styles.progressRow}>
            <div className={styles.rankMedalLeft}>
              <RankMedal rank={user.game.rank_id} width={30} height={30} />
            </div>
            <div className={styles.rankMedalRight}>
              {user.game.rank_id < 4 ? (
                <RankMedal rank={user.game.rank_id + 1} width={30} height={30} />
              ) : (
                <div className={styles.maxRankIndicator}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="#ffbf00">
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
                  </svg>
                </div>
              )}
            </div>
          </div>
          <div className={styles.progressBar}>
            <div 
              className={styles.progressFill}
              style={{ 
                width: user.game.rank_id >= 4 ? '100%' : `${(rankProgress / 12) * 100}%`,
                background: '#ffbf00'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
