import React from 'react';
import { format, isAfter } from 'date-fns';
import SelectVictoryImage from '../../selectVictoryImage/selectVictoryImage';
import styles from '../UserProgress.module.css';

export const VictoryModal = ({ 
  isVisible, 
  onClose, 
  selectedDate, 
  monthlyVictories 
}) => {
  if (!isVisible) return null;

  return (
    <div className={styles.victoryModalContainer} onClick={onClose}>
      <div className={styles.victoryModalContent} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <span className={styles.modalTitle}>{format(selectedDate, 'MMMM')} Victories</span>
          <button 
            className={styles.closeButton}
            onClick={onClose}
          >
            <i className="fas fa-times" style={{ fontSize: '14px', color: '#6c757d' }}></i>
          </button>
        </div>
        {monthlyVictories.length > 0 ? (
          <div className={styles.victoryGrid}>
            {(() => {
              const groupedVictories = monthlyVictories.reduce((groups, victory) => {
                const victoryTypeId = victory.victory.victory_type.id;
                if (!groups[victoryTypeId]) {
                  groups[victoryTypeId] = {
                    type: victory.victory.victory_type,
                    victory: victory,
                    count: 0
                  };
                }
                groups[victoryTypeId].count++;
                return groups;
              }, {});
              return Object.values(groupedVictories).map((group) => (
                <div key={group.type.id} className={styles.victoryItem}>
                  <SelectVictoryImage
                    typeId={group.type.id}
                    victory={group.victory}
                    size={80}
                  />
                  <div className={styles.victoryCount}>
                    <span className={styles.victoryCountText}>{group.count}x</span>
                  </div>
                </div>
              ));
            })()}
          </div>
        ) : (
          <div className={styles.noVictoriesContainer}>
            <span className={styles.noVictoriesText}>No victories yet this month</span>
          </div>
        )}
      </div>
    </div>
  );
};

export const MonthPickerModal = ({ 
  isVisible, 
  onClose, 
  selectedDate,
  onPrevMonth,
  onNextMonth
}) => {
  if (!isVisible) return null;

  return (
    <div className={styles.historyModalContainer} onClick={onClose}>
      <div className={styles.historyModalContent} onClick={e => e.stopPropagation()}>
        <button 
          className={styles.closeButton}
          onClick={onClose}
        >
          <i className="fas fa-times" style={{ fontSize: '12px', color: '#6c757d' }}></i>
        </button>
        <div className={styles.monthPickerHeader}>
          <button 
            className={styles.monthNavButton}
            onClick={onPrevMonth}
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill="#6c757d">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"/>
            </svg>
          </button>
          <span className={styles.monthPickerTitle}>
            {format(selectedDate, 'MMMM yyyy')}
          </span>
          <button 
            className={styles.monthNavButton}
            onClick={onNextMonth}
            disabled={isAfter(selectedDate, new Date())}
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill={isAfter(selectedDate, new Date()) ? '#dbd8d8' : '#6c757d'}>
              <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};