import React, { useEffect } from "react";

// styles
import styles from './intro.module.css';

// components
import {PrimaryButton} from "../../buttons/buttons";
import HabitsBlock from "../../../images/habits_blocks.jpg";

const Intro = props => {
    // props
    const {user, next} = props;
    useEffect(() => {
        const script = document.createElement("script");
        script.textContent = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1380462626626098');
        fbq('track', 'PageView');
        fbq('track', 'StartTrial', {value: '0.00', currency: 'USD', predicted_ltv: '0.00'});
      `;
      
        document.body.appendChild(script);
        
        return () => {
          document.body.removeChild(script); // Cleanup on unmount
        };
      }, []);
    return (
        <div className={styles.fadeIn}>
            <h2 className={styles.name} data-testID="introName">Hi {user.first_name},</h2>
            <p className={styles.introHeading}>Are you ready to start your journey to <strong>healthier habits?</strong></p>
            <div>
                <img className={styles.clipboard} src={HabitsBlock} alt="" data-testID="habitImage"/>
            </div>
            <p className={styles.intro}>We researched which lifestyle habits are most effective for achieving the health goal you select. It’s daily habits that drive long-term results. Ready to identify your top goal?</p>
            <div className={styles.start}>
                <PrimaryButton onClick={() => next(2)} data-testID="startAssesmentButton">Choose Your Health Goal</PrimaryButton>
            </div>
            <div>
                <img height="1" width="1" style={{display: 'none'}} src="https://www.facebook.com/tr?id=1380462626626098&ev=PageView&noscript=1"/>
            </div>
        </div>
        
    )
};

export default Intro;