import React, { useState } from "react";

// styles
import styles from "./DateRange.module.css";

// routes
import {
  fetchPortalCustomerActiveUsers,
  fetchPortalCustomerActiveUsersTrends,
} from "../routes/DashboardPortalRoutes";

// redux
import { connect } from "react-redux";
import { portalApiMap } from "../../../store/actions/portalAction";

// icons
import portalCalander from "../../../images/portalCalander.png";

const DateRange = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  portalApiMap,
  setActiveUsersData,
  selectedRange,
  setSelectedRange,
  setActiveUsersTrends,
}) => {
  // Format date to YYYY-MM-DD for input value using local date parts
  const formatDateForInput = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  // Helper function to parse date input string as a local date
  const parseDateFromInput = (value) => {
    const [year, month, day] = value.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  const handleDateChange = (option) => {
    setSelectedRange(option);
    let newStartDate, newEndDate;

    switch (option) {
      case "Last 7 Days":
        newStartDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        newEndDate = new Date();
        break;
      case "Last 30 Days":
        newStartDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
        newEndDate = new Date();
        break;
      case "Last 90 Days":
        newStartDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
        newEndDate = new Date();
        break;
      case "Custom Range":
        return; // Let users manually select dates
      default:
        newStartDate = new Date();
        newEndDate = new Date();
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleSubmit = async () => {
    try {
      const data = await fetchPortalCustomerActiveUsers(
        portalApiMap,
        startDate.toISOString(),
        endDate.toISOString()
      );

      const trendsData = await fetchPortalCustomerActiveUsersTrends(
        portalApiMap,
        startDate.toISOString(),
        endDate.toISOString()
      );
      setActiveUsersTrends(trendsData);
      setActiveUsersData(Array.isArray(data) ? data : []);
    } catch (err) {
      console.error("Error fetching active users:", err);
    }
  };

  return (
    <div className={styles.dateRangeContainer}>
      {/* Right Section - Dropdown & (Conditional) Button */}
      <div className={styles.controls}>
        {/* Dropdown */}
        <div className={styles.dropdown}>
          <img src={portalCalander} alt="Portal Calander Icon" width={18} />
          <div className={styles.customSelectWrapper}>
            <select
              value={selectedRange}
              onChange={(e) => handleDateChange(e.target.value)}
              className={styles.customSelect}
            >
              <option>Last 7 Days</option>
              <option>Last 30 Days</option>
              <option>Last 90 Days</option>
              <option>Custom Range</option>
            </select>
          </div>
        </div>

        {/* Native HTML Date Picker (Only if Custom Range is selected) */}
        {selectedRange === "Custom Range" && (
          <div className={styles.datePickers}>
            <input
              type="date"
              value={formatDateForInput(startDate)}
              onChange={(e) => setStartDate(parseDateFromInput(e.target.value))}
              onKeyDown={e => e.preventDefault()}
            />
            <input
              type="date"
              value={formatDateForInput(endDate)}
              onChange={(e) => setEndDate(parseDateFromInput(e.target.value))}
              min={formatDateForInput(startDate)}
              onKeyDown={e => e.preventDefault()}

            />
          </div>
        )}
      </div>
    </div>
  );
};

const actions = {
  portalApiMap,
};

export default connect(null, actions)(DateRange);
