import React, {useState, useEffect} from 'react';

// styles
import styles from './consoleSignup.module.css';
import {organizationApi} from "../../../api/organizationApi";

const ConsoleSignup = props => {
    // local
    const [selected, setSelected] = useState(null);
    const [retailers, setRetailers] = useState([]);
    useEffect(() => {
        document.body.style.background = '#fff';
        return () => document.body.style.background = null;
    }, []);

    // get retail org list
    useEffect(() => {
        try {
            organizationApi().getRetailers()
                .then(({data: {result}}) => {
                    setRetailers(result);
                })
        } catch(e) {
            setRetailers([]);
        }
    })

    function onSubmit() {
        if (selected) {
            props.history.push(`/signup?orgId=${selected.organization_id}&inviteCode=${selected.code.code}&console=true`)
        }
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>Create your free* Treo account!</h1>
            <div className={`${styles.shadow} ${styles.greenBackground}`}>
                <h2 className={styles.subHeading}>Where did you purchase your Matrix product?</h2>
                <ul className={styles.list}>
                    {retailers.map(val => {
                        const dark = selected && selected.organization_id === val.organization_id;
                        return (
                            <li key={val.organization_id} className={`${styles.button} ${styles.shadow} ${dark ? styles.selected : ''}`} onClick={() => setSelected(val)}>
                                <button><img src={val.icon} alt=""/><span>{val.organization_name}</span></button>
                            </li>
                        )
                    })}
                </ul>
                <button onClick={onSubmit} className={`${styles.submit} ${styles.shadow}`}>Submit</button>
            </div>
            <p className={styles.disclaimer}>*Free for one month. No credit card required.</p>
        </div>
    )
}

export default ConsoleSignup;