import React, {useState} from 'react';
import axios from 'axios';

// styles
import styles from './memberPrivacy.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import PasswordEyeInput from "../../passwordEyeInput/passwordEyeInput";
import {PrimaryButton} from "../../buttons/buttons";

const MemberPrivacy = props => {
    // local
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [passwordChangeError, setPasswordChangeError] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    async function accountUpdate(e) {
        e.preventDefault();
        setSuccessMessage('');
        if (disableSubmit) return;
        setDisableSubmit(true);
        // validation
        // confirm password is at least 8 characters
        if (newPassword.length < 8) {
            setDisableSubmit(false);
            return setPasswordChangeError('Password must be at least 8 characters');
        }
        // verify passwords are the same
        if (newPassword !== confirmPassword) {
            setDisableSubmit(false);
            return setPasswordChangeError('Passwords do not match');
        }

        setPasswordChangeError('');
        try {
            await axios
                .post(`/user/${props.singleUser.user.email}/client-detail/add`, {password: newPassword})
        } catch(error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setPasswordChangeError(`Error: ${error.response.data.error}`);
            } else if (error.request) {
                // The request was made but no response was received
                setPasswordChangeError("No response received from the server.");
            } else {
                // Something happened in setting up the request that triggered an Error
                setPasswordChangeError("Error setting up the request.");
            }
        } finally {
            setDisableSubmit(false);
            setNewPassword('');
            setConfirmPassword('');
            setSuccessMessage('Password changed successfully.');
        }
    }

    return (
        <BoxContainer className='padding-standard'>
            <form onSubmit={e => accountUpdate(e)}>
                <div>
                    <h3 className={styles.heading}>Password Reset</h3>
                    <p className="text-info m-b:1">Only reset a password with a members knowledge</p>
                    <div className={styles.inputContainer}>
                        {successMessage ? <p className='text-success m:0'>{successMessage}</p> : null}
                        {passwordChangeError ? <p className='text-danger m:0'>{passwordChangeError}</p> : null}
                        <div>
                            <label htmlFor="newPassword">Enter New password:</label>
                            <PasswordEyeInput id='newPassword' value={newPassword}
                                              onChange={val => setNewPassword(val)}/>
                        </div>
                        <div>
                            <label htmlFor="confirmPassword">Confirm new password:</label>
                            <PasswordEyeInput id='confirmPassword' value={confirmPassword}
                                              onChange={val => setConfirmPassword(val)}/>
                        </div>
                        <div className={styles.buttonContainer}>
                            <PrimaryButton type='submit' disabled={disableSubmit}>Save</PrimaryButton>
                        </div>
                    </div>
                </div>
            </form>
        </BoxContainer>
    )
};

export default MemberPrivacy;