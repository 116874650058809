// npm modules
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from "axios";

// components
import PolicyComplianceView from "./policyComplianceView";
import ClientHeader from "../clientHeader/clientHeader";

const PolicyComplianceContainer = ({ user }) => {
    const [, setWellnessScore] = useState(null);

    // on mount scheduling
    useEffect(() => {
        axios.get('/score/wellness-score')
            .then(res=>setWellnessScore(res.data.results.score))
            .catch();
    }, []);

    return (
        <div className="client-container">
            <ClientHeader bypassRedirect backUrl={`/member/clientDash/${user.username}`}/>
            { user ?
                <PolicyComplianceView/>
                : null
            }
        </div>
    )
};

const mapStateToProps = ({ user }) => {
    return  { user };
};

PolicyComplianceContainer.propTypes = {
    user: PropTypes.object,
};

export default connect(mapStateToProps)(PolicyComplianceContainer);