import React, {useState} from 'react';

// styles
import styles from './passwordEyeInput.module.css';

// images & icons
import {ReactComponent as EyeOpenIcon} from '../../images/eye_open.svg';
import {ReactComponent as EyeClosedIcon} from '../../images/eye_closed.svg';

const PasswordEyeInput = ({value, onChange, ...props}) => {
    const [revealed, setRevealed] = useState(false);
    return (
        <div className={styles.container}>
            <div className={styles.eyeContainer} onClick={() => setRevealed(!revealed)}>
                {revealed ?
                    <EyeOpenIcon className={styles.eye}/>
                    :
                    <EyeClosedIcon className={styles.eye}/>
                }
            </div>
            <input {...props} type={revealed ? 'text' : 'password'} value={value} onChange={e => onChange(e.target.value)} />
        </div>
    )
}

export default PasswordEyeInput;