// src/components/portalCard/PortalCard.js
import React from "react";
import clsx from "clsx"; // Helps merge class names
import styles from "./PortalCard.module.css"; // Default styles

const PortalCard = ({ children, className, style }) => {
  return (
    <div className={clsx(styles.cardContainer, className)} style={style}>
      {children}
    </div>
  );
};

export default PortalCard;
