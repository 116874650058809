import React from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Button, Modal } from 'react-bootstrap';
import _ from 'underscore';

// styles
import styles from './subHeader.module.css';

// actions
import {  clearSingleUserState, fetchSelf } from "../../../actions/action_user";
import { getUserWellnessScore } from "../../../actions/action_wellnessScore";

// components
import PhotoContainer from "./container";
import Error from '../../ErrorMessage/Error';
import ClientStatus from "./clientStatus";

class SubHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         user : {},
         tabStyle1: true,
         tabStyle2: true,
         tabStyle3: true,
         tabStyle4: true,
         tabStyle5: true,
         tabStyle6: true,
         tabStyle7: true,
         tabStyle8: true,
         tabStyle9: true,
         tabStyle10: true,
         clientImg: '',
         score:'',
         lifetimeScore: '',
         subscriptionType: '',
         show: false,
         show2: false,
         show3: false,
         alertStyle: true,
         alert: '',

            //datepicker stuff
            date:null,
            message:null,
            startDateDisabled:true,

            //subscription type select
            subscription_types : null,
            subscription : null,
            sub_confirmed : null,

            //modal for start date promt
            modal_startDate : false,
        };
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleDismiss2 = this.handleDismiss2.bind(this);
    this.handleShow2 = this.handleShow2.bind(this);
    this.handleDismiss3 = this.handleDismiss3.bind(this);
    this.handleShow3 = this.handleShow3.bind(this);
    this.permissionAlert=this.permissionAlert.bind(this);
    }

    handleDismiss() {
      this.setState({ show: false });
    }

    handleShow() {
      this.setState({ show: true });
    }

    handleDismiss2() {
      this.setState({ show2: false });
    }

    handleShow2() {
      this.setState({ show2: true });
    }

    handleDismiss3() {
      this.setState({ show3: false });
    }

    handleShow3() {
      this.setState({ show3: true });
    }


    componentDidMount() {
      this.props.fetchSelf();
      //get info on user related to tile currently being viewed
        let { user } = this.props.singleUser;
        user.subscription_type !== null ?
            this.setState({subscriptionType:user.subscription_type}) :
            this.setState({subscriptionType:"not available"});
        this.setState({subscription : {id : user.subscription_id, subsciption : user.subscription_type}})
        this.props.getUserWellnessScore(this.props.username)


      this.setState({tabStyle1: false})
      axios.get(`/user/client/single/${this.props.username}`)
        .then(res => {
          this.setState({expiration:res.data.user.expiration});
          this.setState({clientImg:res.data.user.profile_image});
          this.setState({user:res.data.results});

        })
        .catch(err=>console.log(err));

      axios.get(`/score/wellness-score/${this.props.username}`)
        .then(res => {

          this.setState({score:res.data.results.score});

        })
        .catch(err=>console.log(err));

      //grab subscription types
        axios.get('/user/subscriptionTypes')
            .then(({data})=>{
                this.setState({subscription_types : data.results})
            });

      this.permissionCheck();
    }

    componentDidUpdate(prevProps, prevState){
      if(!_.isEqual(this.state, prevState) || !_.isEqual(this.props, prevProps)){
        this.permissionCheck();
      }
    }

    permissionCheck(){
      if(this.props.singleUser && this.props.user){
        if(!this.props.singleUser.user.expiration && this.props.user.permission_id > 1){
          this.setState({startDateDisabled:false})
        }
        else if(this.props.singleUser.user.expiration && this.props.user.permission_id === 3){
          this.setState({startDateDisabled:false})
        }else{
          this.setState({startDateDisabled: true});
        }
      }

    }

    wellnessScoreCap(){

      if(this.props.wellnessScore){
        if(this.props.wellnessScore.score > 100){
          return(
            100
            )
        }
        else{
          return(this.props.wellnessScore ? this.props.wellnessScore.score : null)
        }
      }
    }

    permissionAlert(){
      if(this.props.singleUser && this.props.user){
        if(this.props.singleUser.user.expiration && this.props.user.permission_id === 2){
          this.setState({message:"Only admins are allowed to change start dates."})
        }
      }
    }

    renderDatePicker=()=>{
        if(this.props.singleUser){
            let date;
            if(this.state.date){
                //date has been changed
                return(
                    <div>
                        <div style={{display:'inline-block'}}>
                        <input
                            type='date'
                            disabled={this.state.startDateDisabled}
                            value={this.state.date}
                            onChange={this.changeDate}
                        />
                        </div>
                        <button className='btn' onClick={()=>this.postStartDate(this.state.date)}>Confirm</button>
                    </div>
                )
            }else{
                if(this.props.singleUser.user.start_date){
                    date = moment(this.props.singleUser.user.start_date).format('YYYY-MM-DD');
                }else{
                    date=null;
                }

                return <input
                    type='date'
                    disabled={this.state.startDateDisabled}
                    value={date}
                    onChange={this.changeDate}/>
            }
        }
    };

    changeDate=(e)=>{
        let date = e.target.value
        date = moment(date).add(1, 'day').hour(2).minutes(0).seconds(0);
        console.log(date.format('YYYY-MM-DD'));
        this.setState({date: date.format('YYYY-MM-DD'), message:null});
    };

    postStartDate=(date)=>{
        let that=this;
        if(this.props.singleUser){
            let url = `/user/start-date/${this.props.singleUser.user.username}`;
            let options = {date};
            this.showStartDateModal();
            axios.put(url, options)
                .then(()=>{
                    this.props.fetchSingleUser(this.props.username);
                    that.setState({date:null, message:null});
                })
                .catch(e=>{
                    that.setState({message:e.response.data.error});
                });
        }
    };

  deleteClientStep1(){
    this.setState({show:true})
  }

  deleteClientStep2(){
    this.setState({show:false})
    this.setState({show2:true})
  }

  deleteClientStep3(){
    this.setState({show:false})
    this.setState({show2:false})
    axios.put(`/user/remove-user/${this.props.username}`)
      .then((res)=>{
        if(res.data.message === 'SUCCESS'){
        this.setState({ show3: true });
        this.setState({ alert: 'success' });
        this.setState({ alertStyle: true });
        this.props.history.push({
            pathname: `/dashboard`,
        })
      }})
      .catch(e=>{
          console.log(e)
          this.setState({ show3: true });
          this.setState({alert:'error'});
          this.setState({ alertStyle: false });
      });
  }

  showStartDateModal=()=>{
      this.setState({modal_startDate : true});
  }

  closeStartDateModal=()=>{
      this.setState({modal_startDate: false});
  };

  renderBirthday(){
        if (this.props.singleUser){
            if (this.props.singleUser.user.birthday){
                return `${moment().diff(moment(this.props.singleUser.user.birthday), 'years')} years old `;
            } else {
                return 'Birthday not set ';
            }
        }
        return null;
  }

  renderFriendlyLastActive(){
        if (this.props.singleUser){
            if (this.props.singleUser.user.last_login){
                return `${moment().diff(moment(this.props.singleUser.user.last_login), 'days')} days ago `;
            } else {
                return 'Not active yet';
            }
        }
        return null;
  }

  renderFriendlyExpiration(){
        if (this.props.singleUser){
            if (this.props.singleUser.user.expiration){
                return `${moment(this.props.singleUser.user.expiration).diff(moment(), 'days')} days `;
            } else {
                return 'Not active yet';
            }
        }
        return null;
  }

  calcWeeks = () => {
        if (this.props.singleUser) {
            if (moment(this.props.singleUser.user.start_date).isValid()) {
                const duration = moment.duration(moment().diff(this.props.singleUser.user.start_date));
                const weeks = duration.asWeeks();
                return <span>Week {Math.round(weeks)}</span>
            }
        }
    }

  renderLifetimeScore = (score) => {
        return score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
    const { singleUser } = this.props;
    const member = singleUser ? singleUser.user : null;
    return(
            <div className="">
          <div className="page-header-container">
                  <div className="container">
                        <div className="row">
                          <div className="col-sm-12">
                            <nav>
                              <ol className="breadcrumb ">
                                <li className="breadcrumb-item underline "><a href="/dashboard" className="text-light">Dashboard</a></li><span className="arrow"></span>
                                <li className="breadcrumb-item underline "><a href="/dashboard/ClientSearch" className="text-light">Members</a></li><span className="arrow"></span>
                                <li className="breadcrumb-item text-light active">{this.props.singleUser ? this.props.singleUser.user.first_name + ' ': null} {this.props.singleUser ? this.props.singleUser.user.last_name : null}</li>
                              </ol>
                            </nav>
                          </div>
                          <div className="col-sm-12 col-md-9 margin-top">
                              <div className="col-xs-12 col-sm-3 col-md-3">
                                  <div className="profile-image admin-dash--summary">
                                    <PhotoContainer
                                    image={this.state.clientImg}
                                    url_path={`client/${this.props.username}`}
                                    score={this.state.score ? this.state.score : null}
                                    />

                                  </div>
                              </div>
                              <div className="col-xs-12 col-sm-9 col-md-9">
                              	  <div className="--profile-container">
										<div className="profile-name">
											<h1 className="name">
												{member ? member.first_name + ' ': null}
												{member ? member.last_name : null}
                                                {member.demo_member ? <span className="badge-warning">Demo Account</span> : null}
											</h1>
                                            {member.daxko_home_branch ? <span className={styles.branch}>Branch: {member.daxko_home_branch}</span> : null}
                                            { member && member.organization && member.organization.id ?
                                                <Link to={`/dashboard/organization/${member.organization.id}/overview`}  className={styles.orgId}>
                                                    {member.organization.name}({member.organization.id})
                                                </Link>
                                                :null
                                            }
                                            <div className={`${styles.statusContainer} account-details`}>
                                                <div className={styles.statusSubcontainer}>
                                                    <span className={styles.statusLabels}>Start Date</span>
                                                    <div onClick={this.permissionAlert} className="start-datepicker">
                                                        {this.renderDatePicker()}
                                                        <Error message={this.state.message}/>
                                                    </div>
                                                </div>
                                                <div className={styles.statusSubcontainer}>
                                                    <span className={styles.statusLabels}>Status</span>
                                                    { this.props.singleUser ? <ClientStatus client={this.props.singleUser}/> : null}
                                                    {this.calcWeeks()}
                                                </div>
                                            </div>
										</div>									  
                                      <div className="row account-details-callouts">
                                          <div className="col-xs-6 col-sm-3 text-center margin-top-2x">
											  <h3 className="no-margin">
                                                  {this.props.singleUser ? this.renderLifetimeScore(this.props.singleUser.user.lifetime_wellness_score) : ''}
                                                  ({this.wellnessScoreCap()})</h3>
                                              <div className='unit-label'>Wellness Score</div>
                                              <div className="unit-added-details">Lifetime (Today)</div>
                                          </div>
                                          <div className="col-xs-6 col-sm-3 text-center margin-top-2x">
                                              <h3 className="no-margin">												  
												   {this.props.singleUser ? moment(this.props.singleUser.user.last_login).format('MM/D/YYYY') : null}
											  </h3>
											  <div className="unit-label">Last Active</div>
											  <div className="unit-added-details">{this.renderFriendlyLastActive()}</div>
                                          </div>
										  <div className="col-xs-6 col-sm-3 text-center margin-top-2x">									  
											  <h3 className="no-margin">
												{this.props.singleUser ?
												  this.props.singleUser.user.expiration ?
												  moment(this.props.singleUser.user.expiration).calendar() :
												  'Select a start date'
												: null}
											  </h3>
											  <div className="unit-label">Expiration Date</div>
											  <div className="unit-added-details">{this.renderFriendlyExpiration()}</div>
										  </div>
										  <div className="col-xs-6 col-sm-3 text-center margin-top-2x">
											  <h3 className="no-margin">
										  		{this.renderBirthday()}
											  </h3>
											  <div className="unit-label">Member Age</div>
											  <div className="unit-added-details">
												  Birthday: {this.props.singleUser ? moment(this.props.singleUser.user.birthday).format('MM/D/YYYY') : null}												  
											  </div>
										  </div>
                                      </div>                                                                            
                                  </div>
                                  </div>
                              </div>

							<div className="col-xs-12 col-md-3 margin-top">
                                <div className="col-xs-6 col-md-12 text-center margin-top">
                                      <Link to={'/client/'+this.props.username+'/inbox'} className='btn primary'>
                                          + Message Member
                                      </Link>
                                </div>
                                <div className="col-xs-6 col-md-12 text-center margin-top">
                                      <button onClick={()=>this.deleteClientStep1()} className='btn primary logout add--icon margin-bottom-med' data-testID='deleteMemberBtn'>
                                           Delete Member
                                      </button>
                                      {this.state.show
                                        ? <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
                                            <h4 className="text-center">Please confirm you want to delete this member.</h4>
                                            <p className="text-center">
                                              <Button onClick={()=>this.deleteClientStep2()} className="margin-right" bsStyle="danger !important" data-testID='mDeleteBtn'>YES, DELETE CLIENT</Button>

                                              <Button className="margin-left" onClick={this.handleDismiss}>CANCEL</Button>
                                            </p>
                                          </Alert>
                                        :null
                                      }
                                      {this.state.show2
                                        ? <Alert bsStyle="danger" onDismiss={this.handleDismiss2}>
                                            <h4 className="text-center">This will delete {this.props.singleUser.user.first_name} {this.props.singleUser.user.last_name}</h4>
                                            <p className="text-center">
                                              <Button onClick={()=>this.deleteClientStep3()} className="margin-right" bsStyle="danger !important" data-testID='mDelConfimBtn'>DELETE {this.props.singleUser.user.first_name}</Button>
                                              <Button className="margin-left" onClick={this.handleDismiss2}>CANCEL</Button>
                                            </p>
                                          </Alert>
                                        :null
                                      }
                                     {this.state.show3
                                        ?  <Alert className="save-profile-alert" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss3}>
                                              <p>{this.state.alert}</p>
                                            </Alert>
                                        : null
                                      }
                                </div>
							</div>
                          </div>
                        </div>
                  </div>
                <Modal show={this.state.modal_startDate} onHide={this.closeStartDateModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Start Date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Start dates signify when a member will begin using treo</p>
                        <h4><strong>Note: </strong>Start dates always submit as the date <strong>after</strong> you set them</h4>
                        <p>Example: If you click on the 1st of the month the date will default to the 2nd at the start of the day to compensate for any lost time the member may have experienced from starting later in the day.</p>
                    </Modal.Body>
                </Modal>
            </div>
      );
  }
};

function mapStateToProps({singleUser, wellnessScore, user}) {
    return {
        singleUser, wellnessScore, user
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        getUserWellnessScore,
        clearSingleUserState,
        fetchSelf
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubHeader));
