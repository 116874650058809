import React from "react";

// styles
import styles from "./healthGoal.module.css";

// components
import WeightLossIcon from "../../../images/icon-pathwayreport-primary_weightloss.png";
import BodyCompIcon from "../../../images/icon-pathwayreport-primary_bodycomp.png";
import BrainHealthIcon from "../../../images/icon-pathwayreport-primary_brainhealth.png";
import EnergyIcon from "../../../images/icon-pathwayreport-primary_energy.png";
import ActivityIcon from "../../../images/icon-pathwayreport-primary_activity.png";
import FoodChoicesIcon from "../../../images/icon-pathwayreport-primary_foodchoices.png";
import BetterBloodPressureIcon from "../../../images/icon-pathway-bbp.png";
import BetterBloodSugarIcon from "../../../images/icon-pathway-bbs.png";
import { HEALTH_GOALS } from "../../../serverVariables/healthGoals";
import { PrimaryButton } from "../../buttons/buttons";
import { useState } from "react";

const HealthGoal = (props) => {
  // props
  const { next, selectedGoal, setSelectedGoal} = props;


  const handleGoalClick = (goal) => {
    setSelectedGoal(goal);
  };

  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.heading} data-testID="healthGoal">
          WHICH <strong>HEALTH GOAL</strong> IS MOST IMPORTANT TO YOU RIGHT NOW?
        </h2>
        <div
          onClick={() => handleGoalClick(HEALTH_GOALS.LASTING_WEIGHT_LOSS)}
          className={`${styles.goalContainer} ${
            selectedGoal === HEALTH_GOALS.LASTING_WEIGHT_LOSS
              ? styles.selected
              : ""
          }`}
          data-testID="weightLossBtn"
        >
          {" "}
          <img src={WeightLossIcon} alt="Weight Loss" />
          <span>I want to achieve lasting weight loss.</span>
        </div>

        <div
          onClick={() => handleGoalClick(HEALTH_GOALS.STRONGER_LEANER_BODY)}
          className={`${styles.goalContainer} ${
            selectedGoal === HEALTH_GOALS.STRONGER_LEANER_BODY
              ? styles.selected
              : ""
          }`}
          data-testID="leanBodyBtn"
        >
          <img src={BodyCompIcon} alt="Stronger and Leaner" />
          <span>I want to build a stronger & leaner body.</span>
        </div>

        <div
          onClick={() =>
            handleGoalClick(HEALTH_GOALS.IMPROVE_HEART_COGNITIVE_HEALTH)
          }
          className={`${styles.goalContainer} ${
            selectedGoal === HEALTH_GOALS.IMPROVE_HEART_COGNITIVE_HEALTH
              ? styles.selected
              : ""
          }`}
          data-testID="brainHealthBtn"
        >
          <img src={BrainHealthIcon} alt="Brain Health" />
          <span>I want to improve my heart and cognitive health.</span>
        </div>

        <div
          onClick={() => handleGoalClick(HEALTH_GOALS.MORE_ENERGY_LESS_STRESS)}
          className={`${styles.goalContainer} ${
            selectedGoal === HEALTH_GOALS.MORE_ENERGY_LESS_STRESS
              ? styles.selected
              : ""
          }`}
          data-testID="energyBtn"
        >
          <img src={EnergyIcon} alt="Energy" />
          <span>I want more energy and less stress each day.</span>
        </div>

        <div
          onClick={() => handleGoalClick(HEALTH_GOALS.PHYSICAL_ACTIVITY_HABIT)}
          className={`${styles.goalContainer} ${
            selectedGoal === HEALTH_GOALS.PHYSICAL_ACTIVITY_HABIT
              ? styles.selected
              : ""
          }`}
          data-testID="phyActivityBtn"
        >
          <img src={ActivityIcon} alt="Activity" />
          <span>
            I want to make physical activity a regular part of my life.
          </span>
        </div>

        <div
          onClick={() => handleGoalClick(HEALTH_GOALS.HEALTHIER_FOOD_CHOICES)}
          className={`${styles.goalContainer} ${
            selectedGoal === HEALTH_GOALS.HEALTHIER_FOOD_CHOICES
              ? styles.selected
              : ""
          }`}
          data-testID="foodChoicesBtn"
        >
          <img src={FoodChoicesIcon} alt="Food Choices" />
          <span>I want to make healthier daily food choices.</span>
        </div>

        <div
          onClick={() => handleGoalClick(HEALTH_GOALS.BETTER_BLOOD_PRESSURE)}
          className={`${styles.goalContainer} ${
            selectedGoal === HEALTH_GOALS.BETTER_BLOOD_PRESSURE
              ? styles.selected
              : ""
          }`}
          data-testID="betterBPBtn"
        >
          <img src={BetterBloodPressureIcon} alt='"Blood Pressure' />
          <span>
            I want to adopt lifestyle changes that improve my blood pressure.
          </span>
        </div>

        <div
          onClick={() => handleGoalClick(HEALTH_GOALS.BETTER_BLOOD_SUGAR)}
          className={`${styles.goalContainer} ${
            selectedGoal === HEALTH_GOALS.BETTER_BLOOD_SUGAR
              ? styles.selected
              : ""
          }`}
          data-testID="betterBSBtn"
        >
          <img src={BetterBloodSugarIcon} alt="about" />
          <span>
            I want to adopt lifestyle changes that improve my blood sugar.
          </span>
        </div>

        <div
          style={{
            marginTop: 20,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PrimaryButton
            onClick={() => next(4)}
            data-testID="startAssesmentButton"
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default HealthGoal;
