import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import JsBarcode from "jsbarcode";
import { Modal } from "react-bootstrap";

// styles
import styles from "./tileTrack.module.css";

// actions
import { recordContentView } from "../../../store/actions/pathwaysActions";
import { checkAlerts } from "../../../store/actions/alertActions";
import {
  getDailyScore,
  fetchMonthlyScore,
  setChangedScoreFlag,
} from "../../../store/actions/wellnessScoreActions";

// components
import { Link } from "react-router-dom";
import ExternalIcon from "../../../images/icon-external.png";
import { ReactComponent as PDFIcon } from "../../../images/file-pdf-solid.svg";
import { ReactComponent as BarcodeIcon } from "../../../images/icons/barcode.svg";
import { CONTENT_TYPES } from "../../../serverVariables/contentTypes";
import { PrimaryButtonSm } from "../../buttons/buttons";
import DaxkoSignup from "../../daxkoSignup/daxkoSignup";

const TileTrack = (props) => {
  // props
  const {
    icon,
    title,
    subcategories = [],
    content = [],
    daxkoScope,
    barcode,
    openThankyou,
    setOpenThankyou,
    contentTypeId,
    dataCy,
  } = props;
  // state
  const { user } = props;
  // actions
  const {
    checkAlerts,
    getDailyScore,
    fetchMonthlyScore,
    setChangedScoreFlag,
  } = props;
  // local
  const track = useRef(null);
  const [overflow, setOverflow] = useState(false);
  const [showBarcode, setShowBarcode] = useState(false);
  const [showConnect, setShowConnect] = useState(false);
  const [redirectToDaxko, setRedirectToDaxko] = useState(false);
  const [showThankyou, setShowThankyou] = useState(false);

  useEffect(() => {
    showOverflow();
    window.addEventListener("resize", showOverflow);
    return () => window.removeEventListener("resize", showOverflow);
  }, []);

  // watcher for forcing the barcode modal to show
  useEffect(() => {
    if (openThankyou && !showThankyou) {
      setShowThankyou(true);
    }
  }, [openThankyou]);

  function showOverflow() {
    setOverflow(isOverflown());
  }

  function isOverflown() {
    if (!track || !track.current) return;
    return (
      track.current.scrollHeight > track.current.clientHeight ||
      track.current.scrollWidth > track.current.clientWidth
    );
  }

  async function registerView(contentId) {
    try {
      const res = await recordContentView(contentId);
      if (res && res.result.alert_waiting) {
        checkAlerts();
        setChangedScoreFlag(parseInt(res.result.score.score));
        await getDailyScore();
        fetchMonthlyScore();
      }
    } catch (e) {
      console.log(e);
    }
  }

  function showNew() {
    return (
      <span className={styles.new}>
        <span className={styles.newDot} />
        New
      </span>
    );
  }

  function showTM(val) {
    val = val.toLowerCase();
    if (
      val === "climbmill" ||
      val === "ascent trainer" ||
      val.indexOf("virtual active") > -1
    ) {
      return <span className={styles.trademark}>&#8482;</span>;
    }
  }

  function closeBarcode() {
    setShowBarcode(false);
  }
  return (
    <div>
      <div className={styles.featuredHeading}>
        <img className={styles.icon} src={icon} alt="" />
        <h5>{title}</h5>
      </div>
      <div
        className={`${styles.featuredTrack} ${overflow ? styles.overflow : ""}`}
        ref={track}
      >
        {/* Decide if a member should see the barcode to scan */}
        {barcode ? (
          <span
            onClick={() => setShowBarcode(barcode)}
            className={`square-tile ${styles.fullWidth} ${styles.titleCenter} ${styles.featuredItem} ${styles.barcodeTile}`}
          >
            <h5 className={`${styles.title} ${styles.text}`}>Check In</h5>
            <BarcodeIcon className={styles.barcodeIcon} />
          </span>
        ) : null}

        {/* Decide if a member should see the option to connect their daxko account */}
        {daxkoScope && !barcode ? (
          <span
            onClick={() => setShowConnect(true)}
            className={`square-tile ${styles.fullWidth} ${styles.titleCenter} ${styles.featuredItem} ${styles.barcodeTile}`}
          >
            <h5 className={`${styles.title} ${styles.text}`}>Connect</h5>
            <img src='https://treo.s3.us-east-2.amazonaws.com/assets/the-y-logo.png' alt='' className={styles.barcodeIcon} />
          </span>
        ) : null}
        {subcategories.map((sub, i) => {
          let link = `/member/on-demand/${sub.subcategory_id}`;
          if (contentTypeId === CONTENT_TYPES.FEATURED)
            link = `/member/featured/${sub.subcategory_id}`;
          if (contentTypeId === CONTENT_TYPES.ORGANIZATION)
            link = `/member/featured/${sub.subcategory_id}`;
          return (
            <Link
              key={sub.subcategory_id}
              style={{ backgroundImage: `url(${sub.tile_image_url})` }}
              className={`square-tile ${styles.links} ${styles.fullWidth} ${
                styles.titleCenter
              } ${styles.featuredItem} ${i === 0 ? "tour-featured-tile" : ""}`}
              to={link}
              data-testID={i === 0 ? dataCy : ""}
            >
              {sub.new_flag ? showNew() : null}
              <h5 className={`${styles.title} ${styles.text}`}>
                {sub.subcategory_name}
                {showTM(sub.subcategory_name)}
              </h5>
              <span className={styles.overlay} />
            </Link>
          );
        })}
        {content.map((item, j) => {
          if (item.content_format === "pdf") {
            let title = item.content_title;
            if (title.length > 25) title = title.substr(0, 25) + "...";
            return (
              <Link
                style={{ backgroundImage: `url(${item.thumbnail})` }}
                className={`square-tile ${styles.links} ${styles.fullWidth} ${styles.titleCenter} ${styles.featuredItem}`}
                to={`/member/featured/pdf/${item.content_id}`}
                data-testID={j === 0 ? dataCy : ""}
              >
                {item.new_flag ? showNew() : null}
                <span className={`${styles.title} ${styles.external}`}>
                  <PDFIcon />
                </span>
                <h5 className={`${styles.title} ${styles.text}`}>{title}</h5>
                <span className={styles.overlay} />
              </Link>
            );
          }
        })}
        {content.map((item, k) => {
          if (item.content_format === "link") {
            return (
              <ContentLink
                key={item.content_id}
                content={item}
                showNew={showNew}
                registerView={registerView}
                preventRedirect={user.kiosk}
                dataCy={k === 0 ? dataCy : ""}
              />
            );
          }
          if (item.content_format === "app link") {
            return (
              <AppLink
                key={item.content_id}
                content={item}
                showNew={showNew}
                registerView={registerView}
              />
            );
          }
        })}
      </div>

      {/* Modal that shows the barcode that can be scanned at a member location */}
      <Modal show={showBarcode} onHide={closeBarcode}>
        <Barcode barcode={barcode} close={closeBarcode} />
      </Modal>

      {/* Modal that shows the option to connect a members account to daxko*/}
      <Modal show={showConnect} onHide={() => setShowConnect(false)}>
        <div className={styles.barcodeModal}>
          <div className={styles.barcodeExit} onClick={()=>setShowConnect(false)}>
            &times;
          </div>


          {redirectToDaxko && daxkoScope ?
              <DaxkoSignup scope={daxkoScope}/>
              :
            <>
              <p className={`${styles.connectHeading} margin-bottom-med`}>Connect to your Green Bay YMCA account to be able to achieve Check-In Victories and gain quick access to a YMCA barcode scan card.</p>
              <PrimaryButtonSm onClick={() => setRedirectToDaxko(true)} className='margin-bottom-25'>Do it now</PrimaryButtonSm>
              <p>(You will navigate to Green Bay YMCA, login, and return here)</p>
            </>
          }
          </div>
      </Modal>

      {/* Modal that shows the option to connect a members account to daxko*/}
      <Modal show={showThankyou} onHide={() => setShowThankyou(false)}>
        <div className={styles.barcodeModal}>
          <div className={styles.barcodeExit} onClick={()=>setShowConnect(false)}>
            &times;
          </div>


          <p className={`${styles.connectHeading} margin-bottom-20`}>You are now connected!</p>
          <p className={`${styles.connectHeading} margin-bottom-med`}>Thank You!</p>
          <PrimaryButtonSm onClick={() => setShowThankyou(false)} className='margin-bottom-25'>Close</PrimaryButtonSm>
          </div>
      </Modal>
    </div>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

const actions = {
  checkAlerts,
  getDailyScore,
  fetchMonthlyScore,
  setChangedScoreFlag,
};
export default connect(mapStateToProps, actions)(TileTrack);

function AppLink({ content, showNew, registerView }) {
  // local
  const [warning, setWarning] = useState(false);

  function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "IOS";
    }

    return "unknown";
  }

  function attemptRedirect() {
    const OS = getMobileOperatingSystem();
    if (OS === "IOS") {
      setTimeout(() => {
        window.location.href = content.ios_store_link;
      }, 2000);
      window.location.href = content.ios_app_link;
    }
    if (OS === "Android") {
      setTimeout(() => {
        window.location.href = content.android_store_link;
      }, 2000);
      window.location.href = content.android_app_link;
    }
  }

  if (content.link_disclaimer) {
    return (
      <div
        style={{ backgroundImage: `url(${content.thumbnail})` }}
        className={`square-tile ${styles.links} ${styles.fullWidth} ${styles.titleCenter} ${styles.featuredItem}`}
      >
        <div
          className="t-a:c"
          onClick={() => {
            setWarning(true);
          }}
        >
          {content.new_flag ? showNew() : null}
          <h5 className={`${styles.title} ${styles.text}`}>
            {content.content_title}
          </h5>
          <span className={`${styles.title} ${styles.external}`}>
            External <img src={ExternalIcon} alt="" />
          </span>
          <span className={styles.overlay} />
        </div>
        <Modal
          show={warning}
          onHide={() => setWarning(false)}
          className={styles.warning}
        >
          <div className="padding-15 t-a:c">
            <p>
              <i className="fas fa-exclamation-triangle" /> Following this link
              opens a new browser tab and sends you to a website outside of the
              Treo Platform
            </p>
            <button
              className={styles.primaryLink}
              onClick={() => {
                setWarning(false);
                attemptRedirect();
              }}
            >
              Continue
            </button>
          </div>
        </Modal>
      </div>
    );
  }
  return (
    <div
      style={{ backgroundImage: `url(${content.thumbnail})` }}
      className={`square-tile ${styles.links} ${styles.fullWidth} ${styles.titleCenter} ${styles.featuredItem}`}
      onClick={() => {
        registerView(content.content_id);
        attemptRedirect();
      }}
    >
      {content.new_flag ? showNew() : null}
      <h5 className={`${styles.title} ${styles.text}`}>
        {content.content_title}
      </h5>
      <span className={`${styles.title} ${styles.external}`}>
        External <img src={ExternalIcon} alt="" />
      </span>
      <span className={styles.overlay} />
    </div>
  );
}

function ContentLink({ ...props }) {
  const { content, showNew, registerView, preventRedirect, dataCy } = props;
  // local
  const [warning, setWarning] = useState(false);

  if (preventRedirect) {
    return (
      <div
        style={{ backgroundImage: `url(${content.thumbnail})` }}
        className={`square-tile ${styles.links} ${styles.fullWidth} ${styles.titleCenter} ${styles.featuredItem}`}
        data-testID={dataCy || null}
      >
        <div
          className="t-a:c"
          onClick={() => {
            setWarning(true);
          }}
        >
          {content.new_flag ? showNew() : null}
          <h5 className={`${styles.title} ${styles.text}`} data-testID="test">
            {content.content_title}{" "}
          </h5>
          <span className={`${styles.title} ${styles.external}`}>
            External <img src={ExternalIcon} alt="" />
          </span>
          <span className={styles.overlay} />
        </div>
        <Modal
          show={warning}
          onHide={() => setWarning(false)}
          className={styles.warning}
        >
          <div className="padding-15 t-a:c">
            <p>
              <i className="fas fa-exclamation-triangle m-r:1" />
              External links are disabled for this kiosk display. Sign up today
              and get full access on your own device.
            </p>
            <PrimaryButtonSm onClick={() => setWarning(false)}>
              Close
            </PrimaryButtonSm>
          </div>
        </Modal>
      </div>
    );
  }
  if (content.link_disclaimer) {
    return (
      <div
        style={{ backgroundImage: `url(${content.thumbnail})` }}
        className={`square-tile ${styles.links} ${styles.fullWidth} ${styles.titleCenter} ${styles.featuredItem}`}
      >
        <div
          className="t-a:c"
          onClick={() => {
            setWarning(true);
          }}
        >
          {content.new_flag ? showNew() : null}
          <h5 className={`${styles.title} ${styles.text}`}>
            {content.content_title}
          </h5>
          <span className={`${styles.title} ${styles.external}`}>
            External <img src={ExternalIcon} alt="" />
          </span>
          <span className={styles.overlay} />
        </div>
        <Modal
          show={warning}
          onHide={() => setWarning(false)}
          className={styles.warning}
        >
          <div className="padding-15 t-a:c">
            <p>
              <i className="fas fa-exclamation-triangle" /> Following this link
              opens a new browser tab and sends you to a website outside of the
              Treo Platform
            </p>
            <a
              className={styles.primaryLink}
              href={content.content}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setWarning(false);
                registerView(content.content_id);
              }}
            >
              Continue
            </a>
          </div>
        </Modal>
      </div>
    );
  }
  return (
    <a
      style={{ backgroundImage: `url(${content.thumbnail})` }}
      className={`square-tile ${styles.links} ${styles.fullWidth} ${styles.titleCenter} ${styles.featuredItem}`}
      href={content.content}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => registerView(content.content_id)}
      data-testID={dataCy || null}
    >
      {content.new_flag ? showNew() : null}
      <h5 className={`${styles.title} ${styles.text}`}>
        {content.content_title}
      </h5>
      <span className={`${styles.title} ${styles.external}`}>
        External <img src={ExternalIcon} alt="" />
      </span>
      <span className={styles.overlay} />
    </a>
  );
}

function Barcode({ barcode, close }) {
  useEffect(() => {
    JsBarcode("#barcode", barcode, {
      format: "CODE39",
      lineColor: "#000",
      width: 2,
      height: 80,
      displayValue: true,
    });
  }, []);

  return (
    <div className={styles.barcodeModal}>
      <div className={styles.barcodeExit} onClick={close}>
        &times;
      </div>
      <h4>Scan the barcode below</h4>
      <svg id="barcode" />
    </div>
  );
}
