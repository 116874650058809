import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { portalApiMap } from "../../../../store/actions/portalAction.js";
import { FaTrashAlt } from "react-icons/fa"; // Import modern trash icon
import styles from "./OrganizationCategoriesMenu.module.css";
import DocumentModal from "../../../documentModal/DocumentModal.js";
import Loading from "../../../Loading/Loading.jsx";

const OrganizationCategoriesMenu = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [loading, setLoading] = useState(false);
  const { portalApiMap, organizationId, categories, setCategories, selectedCategory, setSelectedCategory } = props;

  // Fetch categories from API
  const fetchCategories = async () => {
    setLoading(true);
    try {
      const { getPortalOrganizationDocumentCategories } = await portalApiMap();
      const response = await getPortalOrganizationDocumentCategories(organizationId);
      const fetchedCategories = response.data.result || [];

      setCategories(fetchedCategories);

      // ✅ Set first category as the default selected category if not already set
      if (fetchedCategories.length > 0 && !selectedCategory) {
        setSelectedCategory(fetchedCategories[0].category_id);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Handle Category Creation
  const handleCreateCategory = async () => {
    if (!textInput.trim()) {
      alert("Category name is required.");
      return;
    }

    try {
      const { postCreateOrganizationDocumentCategory } = await portalApiMap();
      await postCreateOrganizationDocumentCategory(organizationId, textInput);
      alert("Category created successfully!");
      setTextInput("");
      setModalOpen(false);
      fetchCategories(); // Refresh categories
    } catch (error) {
      alert("Error creating category: " + error.message);
    }
  };

  // Handle Category Deletion
  const handleDeleteCategory = async (categoryId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this category?");
    if (!confirmDelete) return;

    try {
      const { postDeleteOrganizationDocumentCategory } = await portalApiMap();
      await postDeleteOrganizationDocumentCategory(organizationId, categoryId);
      alert("Category deleted successfully!");
      fetchCategories(); // Refresh categories
    } catch (error) {
      alert("Error deleting category: " + error.message);
    }
  };

  return (
    <div className={styles.sidebar}>
      {/* Header */}
      <h2 className={styles.header}>
        Categories
        <button className={styles.addButton} onClick={() => setModalOpen(true)}>+</button>
      </h2>

      {/* Category List */}
      <div className={styles.categoryCard}>
        {loading ? (
          <div><Loading /> </div>
        ) : categories.length > 0 ? (
          categories.map((category) => (
            <div key={category.category_id} className={styles.categoryItemContainer}>
              <span
                className={`${styles.categoryItem} ${category.category_id === selectedCategory ? styles.selectedCategory : ''}`}
                onClick={() => setSelectedCategory(category.category_id)}
              >
                {category.name}
              </span>
              <button className={styles.deleteButton} onClick={() => handleDeleteCategory(category.category_id)}>
                <FaTrashAlt />
              </button>
            </div>
          ))
        ) : (
          <p>No categories available.</p>
        )}
      </div>

      {/* Dynamic Modal for Category Creation */}
      <DocumentModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title="Create New Category"
        fields={[
          {
            label: "Category Name",
            type: "text",
            placeholder: "Enter category name...",
            value: textInput,
            onChange: (e) => setTextInput(e.target.value),
          },
        ]}
        handleSubmit={handleCreateCategory}
        submitLabel="Create"
      />
    </div>
  );
};

export default connect(null, { portalApiMap })(OrganizationCategoriesMenu);
