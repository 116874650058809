import React, {useState, useEffect, useRef, useCallback} from 'react';
import ReactPlayer from 'react-player';
import {connect} from 'react-redux';

// styles
import "video-react/dist/video-react.css";
import styles from './audioPlayer.module.css';

// actions
import {recordViewChunk} from "../../store/actions/contentActions";
import {checkAlerts} from "../../store/actions/alertActions";
import {getDailyScore, setChangedScoreFlag} from "../../store/actions/wellnessScoreActions";

// components
import {ReactComponent as PlayIcon} from '../../images/play.svg';
import {ReactComponent as PauseIcon} from '../../images/pause.svg';
import {ReactComponent as JumpForward} from '../../images/jump_forward.svg';
import {ReactComponent as JumpBack} from '../../images/jump_back.svg';

const AudioPlayer = props => {
    //props
    const {title, src, timeCallback, playingCallback, identifier, id} = props;
    // actions
    const {checkAlerts, getDailyScore, setChangedScoreFlag} = props;
    //local
    const [state, setState] = useState({
        playing: false,
        controls: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false
    });
    const [player, setPlayer] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const duration = useRef(0);
    const currentTime = useRef(0);
    const [ended, setEnded] = useState(false);
    const progressBar = useRef();
    const [session] = useState(`${identifier}-${new Date().getTime()}`);
    const interval = useRef(null);
    const [initialView, setInitialView] = useState(false);

    const notify = useCallback(async (start, duration, total) => {
        try{
             const chunk = await recordViewChunk(id, start, duration, session, total);
             if (chunk.alert_waiting) {
                 checkAlerts();
                 getDailyScore();
                 setChangedScoreFlag(parseInt(chunk.daily_score.score.replace(',', '')));
             }
        } catch(e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        if (!isPlaying && interval.current) {
            clearInterval(interval.current);
            interval.current = null;
            return;
        }
        if (ended.current && interval.current) {
            clearInterval(interval.current);
            interval.current = null;
            return;
        }
        if (!ended.current && interval.current === null && isPlaying) {
            if (!initialView) {
                notify(0, 0, duration.current);
                setInitialView(true);
            }
            const seconds = 5
            interval.current = setInterval(() => {
                notify(Math.ceil(currentTime.current)-seconds, seconds, duration.current);
            }, seconds * 1000)
        }
        return () => {
            clearInterval(interval.current);
        }
    }, [isPlaying, ended, notify]);

    useEffect(() => {
        if(playingCallback && typeof playingCallback === 'function') playingCallback(isPlaying);
    }, [isPlaying]);


    const handlePause = () => {
        setIsPlaying(false);
    }

    const handlePlay = () => {
        setIsPlaying(true);
    }

    const handleEnded = () => {
        setIsPlaying(false);
        setEnded(true);
    }

    const handleProgress = state => {
        if (duration.current < 1) {
            duration.current = player.getDuration();
            progressBar.current.max = player.getDuration();
        }
        if(timeCallback && typeof timeCallback === 'function') timeCallback(state.playedSeconds);
        currentTime.current = state.playedSeconds;
        progressBar.current.value = state.playedSeconds;
        progressBar.current.style.setProperty('--seek-before-width', `${state.playedSeconds / (duration.current || 1) * 100}%`);
    }

    const togglePlayPause = () => {
        if (ended) {
            player.seekTo(0);
            setState({...state, playing: true});
            setIsPlaying(true);
            return setEnded(false);
        }
        const prevValue = isPlaying;
        setIsPlaying(!isPlaying);
        if (!prevValue) {
            setState({...state, playing: true});
        } else {
            setState({...state, playing: false});
        }
    }

    const calculateTime = (seconds) => {
        const minutes = Math.floor(seconds/60);
        const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const secs = Math.floor(seconds%60);
        const returnedSeconds = secs < 10 ? `0${secs}` : `${secs}`;
        return `${returnedMinutes}:${returnedSeconds}`;
    }

    const changeRange = () => {
        player.seekTo(parseFloat(progressBar.current.value));
        progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / (duration.current || 1) * 100}%`);
        currentTime.current = progressBar.current.value;
    }

    return (
        <div className={styles.player}>
            <div style={{width: '1px', height: '1px', position: 'absolute', left: '10000px', overflow: 'hidden'}} data-testID="audioVideoPlayer">
                <ReactPlayer
                    onProgress={handleProgress}
                    onEnded={handleEnded}
                    onPlay={handlePlay}
                    onPause={handlePause}
                    ref={obj => setPlayer(obj)}
                    playing={state.playing}
                    playsinline url={src}
                />
            </div>
            <div>
                <p className={styles.title}>{title}</p>
                <div>
                    <input
                        ref={progressBar}
                        className={styles.progressBar}
                        type="range"
                        step='.1'
                        defaultValue='0'
                        onChange={changeRange}/>
                    <div className={styles.timestamps}>
                        <span>{currentTime.current && !isNaN(currentTime.current) ? calculateTime(currentTime.current) : false}</span>
                        <span>{duration && !isNaN(duration.current) ? calculateTime(duration.current) : false}</span>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={() => player.seekTo(parseFloat(currentTime.current - 10))}
                        type='button'>
                        <span className={styles.back}>10</span>
                        <JumpBack/>
                    </button>
                    <button
                        className={styles.play}
                        type='button'
                        onClick={togglePlayPause}
                        data-testID="audioPlayBtn">{isPlaying ? <PauseIcon className={styles.pause}/> : <PlayIcon/>}</button>
                    <button
                        onClick={() => {
                            const endPoint = player.getDuration();
                            if (currentTime.current + 10 >= endPoint){
                                setEnded(false);
                                setIsPlaying(true);
                            }
                            player.seekTo(parseFloat(currentTime.current + 10))
                        }}
                        type='button'>
                        <span className={styles.forward}>10</span>
                        <JumpForward/>
                    </button>
                </div>
            </div>
        </div>
    )
};

const actions = {
    checkAlerts,
    getDailyScore,
    setChangedScoreFlag
}
export default connect(null, actions)(AudioPlayer);