import React from 'react';
import styles from './CustomerTile.module.css';
import { Glyphicon, Modal } from 'react-bootstrap';
import Avatar from "../../images/avatar-1577909_960_720.png";

const CustomerTile = ({   
    customer,
    remove,
    openRemoveUserModal,
    setOpenRemoveUserModal,
}) => {

    return (
        <div>
          {/* Removal Confirmation Modal */}
          {openRemoveUserModal && (
            <RemoveUserModal
              openRemoveUserModal={openRemoveUserModal}
              coachName={customer.name} // Ensure correct name format
              handleConfirm={remove}
              setOpenRemoveUserModal={setOpenRemoveUserModal}
            />
          )}

          {/* Customer Tile */}
          <div className={styles.customerTile}>
            <div className={styles.imageContainer}>
              <img
                className={styles.image}
                src={customer.profile_image || Avatar} // Defaults to Avatar if missing
                alt={`${customer.name} profile`}
              />
            </div>
            <div className={styles.infoContainer}>
              <h4 className={styles.name}>{customer.name}</h4> {/* Uses customer.name */}
            </div>
            <div className={styles.buttonContainer}>
              <span
                className={`${styles.remove} hover`}
                onClick={() => setOpenRemoveUserModal(true)} // ✅ Passing a function instead of calling immediately
              >
                <Glyphicon glyph="glyphicon glyphicon-remove-circle" /> Remove
              </span>
            </div>
          </div>
        </div>
    );
};

export default CustomerTile;



function RemoveUserModal({
  openRemoveUserModal,
  handleConfirm,
  setOpenRemoveUserModal,
  coachName,
}) {
  return (
    <Modal show={openRemoveUserModal} onHide={() => setOpenRemoveUserModal(false)}> {/* ✅ Fix closing modal */}
      <Modal.Header closeButton>
        <h4>WARNING</h4>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to remove {coachName}?</p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => setOpenRemoveUserModal(false)} className="btn btn-secondary">
          Cancel
        </button>
        <button onClick={handleConfirm} className="btn btn-danger">
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
}

