import React, {useState, useEffect} from 'react';
import {Route, Redirect, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {ShepherdTour} from 'react-shepherd';
import 'shepherd.js/dist/css/shepherd.css';

// hooks
import useDidMountEffect from "../../hooks/useDidMountEffect";

// context
import tourContext from '../../contexts/tourContext';

// actions
import {checkAlerts, fireAlert, skipAlert, clearAlert, acknowledgeAlert} from "../../store/actions/alertActions";

// components
import CoachScheduleContainer from "../coachSchedule/coachScheduleContainer";
import ClientAssessmentRoute from "../clientAssessment/clientAssessmentRoute";
import {mainTour} from "../tours/mainTour/mainTour";
import {ALERT_TYPES} from "../../serverVariables/alertTypes";
import ClientContainer from "./clientContainer/clientContainer";
import UserConfirm from "../userConfirm/userConfirm";

const tourOptions = {
    defaultStepOptions: {
        classes: 'tour-guide shadow-md bg-purple-dark',
        scrollTo: false,
        cancelIcon: {
            enabled: true
        },
        popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 15] } }]
        },
        when: {
            show() {
                const tour = this.getTour();
                const currentStepElement = this.getElement();
                const header = currentStepElement.querySelector('.shepherd-footer');
                const content = currentStepElement.querySelector('.shepherd-text');
                //create progress holder
                const progress = document.createElement('div');
                //create the progress bar
                const innerBar = document.createElement('span');
                //calculate the progress in percentages
                const progressPercentage = Math.floor(((tour.steps.indexOf(tour.currentStep) + 1)/tour.steps.length)*100) + '%';
                innerBar.innerText = progressPercentage;
                //add class to the progress holder
                progress.className='shepherd-progress-bar';
                //add the width dynamically
                innerBar.style.width=progressPercentage;
                //if it is only one button, expand progress holder
                if (document.getElementsByClassName('shepherd-button').length===1) {
                    progress.style.minWidth = '260px';
                }
                progress.appendChild(innerBar);
                if (header) {
                    header.after(progress);
                } else{
                    currentStepElement.classList.add('no-btn');
                    content.after(progress);
                }
            }
        }
    },
    useModalOverlay: true,

};

const ClientArea = (props) => {
    // props
    const {location, match} = props;
    // state
    const {appState, alertList, user} = props;
    // actions
    const {checkAlerts, fireAlert, skipAlert} = props;
    // local
    const [tour, setTour] = useState(mainTour);

    useEffect(() => {
        // only run check alerts if not on main or habits since
        // main and habits have their own check alerts functions
        if (!checkOnMainOrHabits()) checkAlerts();
    }, [appState.isLoggedIn]);

    // watches for new alerts and starts a cycle when alerts are added
    useDidMountEffect(() => {
        // figure out if we're either on the main dashboard or if we're on the habit builder
        if (alertList.length) {
            if(!checkOnMainOrHabits() && alertList[0].alert_type.name === ALERT_TYPES.MONTHLY_GOAL_MAIN) {
                skipAlert();
            } else {
                fireAlert();
            }
        }
    }, [alertList]);

    function checkOnMainOrHabits() {
        let onMainOrHabits = false;
        const pathname = window.location.pathname;
        const mainStringIndex = pathname.indexOf('/member/clientDash');
        if (mainStringIndex > -1) {
            const substr = pathname.substr(mainStringIndex+18);
             // eslint-disable-next-line no-unused-vars
            let maxSlashes = 2;
            if (substr[0] !== '/') maxSlashes = 1;
            let stringParts = substr.split('/');
            if(stringParts[stringParts.length-1] === '') stringParts.pop();
            if (stringParts[stringParts.length-1] === user.username ||stringParts[stringParts.length-1] === 'habits'){
                onMainOrHabits = true;
            }
        }
        return onMainOrHabits;
    }

    // redirect to login if user is not logged in
    if (!appState.isLoggedIn || !appState.appReady) {
        return (
            <Redirect to={{
                pathname: '/',
                state: {from: location}
            }}/>
        )
    }
    return (
           
        <>
            {/*Onboarding Routes*/}
            <Route path={`${match.url}/onboard-assessment`} component={ClientAssessmentRoute}/>
            <Route path={`${match.url}/onboard-confirm`} component={UserConfirm}/>
            <Route path={`${match.url}/onboard-schedule`} component={CoachScheduleContainer}/>


            {/*Client Routes*/}

            <tourContext.Provider value={{tourState: tour, setTour}}>
           
                <ShepherdTour steps={tour} tourOptions={tourOptions}>
                
                    <ClientContainer/>

                </ShepherdTour>
            
            </tourContext.Provider>
       
            </>
    )
}

function mapStateToProps({appState, alertList, user, alertSubscription, alertOptions}) {
    return {appState, alertList, user, alertSubscription, alertOptions};
}

const actions = {
    checkAlerts,
    fireAlert,
    skipAlert,
    clearAlert,
    acknowledgeAlert,
};

export default withRouter(connect(mapStateToProps, actions)(ClientArea));

