// auth variables
export const USER_AUTH = 'USER_AUTH';
export const APP_READY = 'APP_READY';
export const SESSION = 'SESSION';

// user variables
export const USER = 'USER';
export const COACH = 'COACH';

// wellness score variables
export const LIFETIME_SCORE = 'LIFETIME_SCORE';
export const DAILY_SCORE = 'DAILY_SCORE';
export const SCORE_CHANGED = 'SCORE_CHANGED';
export const WELLNESS_SCORE_HISTORY = 'WELLNESS_SCORE_HISTORY';
export const LIFETIME_SCORE_HISTORY = 'LIFETIME_SCORE_HISTORY';
export const MONTHLY_SCORE = 'MONTHLY_SCORE';

// pathways variables
export const SET_CLIENT_PATHWAY = 'SET_CLIENT_PATHWAY';
export const SET_CLIENT_PATHWAY_LIST = 'SET_CLIENT_PATHWAY_LIST';
export const RESET_PATHWAY = 'RESET_PATHWAY';

// tiles variables
export const SET_CLIENT_TILE = 'SET_CLIENT_TILE';
export const SET_CLIENT_TILE_LIST = 'SET_CLIENT_TILE_LIST';

// assessment variables
export const MEMBER_ASSESSMENTS = 'MEMBER_ASSESSMENTS';
export const SINGLE_MEMBER_ASSESSMENT = 'SINGLE_MEMBER_ASSESSMENT';

// alert variables
export const ADD_ALERT = 'ADD_ALERT';
export const FIRE_ALERT = 'FIRE_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const POP_ALERT_LIST = 'POP_ALERT_LIST';
export const ACKNOWLEDGE_ALERT = 'ACKNOWLEDGE_ALERT';
export const REORDER_ALERT_LIST = 'REORDER_ALERT_LIST';

// game variables
export const GET_BADGES = 'GET_BADGES';
export const SET_ALERT_OPTIONS = 'SET_ALERT_OPTIONS';

// community variables
export const GET_COMMUNITY_NOTIFICATIONS = 'GET_COMMUNITY_NOTIFICATIONS';

// multifactor variables
export const MULTIFACTOR_UPDATE = 'MULTIFACTOR_UPDATE';
export const MULTIFACTOR_RESET = 'MULTIFACTOR_RESET';