import React, { useState, useEffect, Suspense, lazy } from "react";
// routes
import {
  fetchPortalCustomerActiveUsers,
  fetchPortalCustomerActiveUsersTrends, 
  fetchPortalCustomerOnboardingStats,
  fetchPortalCustomerGenderStats,
  fetchPortalCustomerAgeStats,
  fetchPortalCustomerAdoptionStats,
  fetchCustomerDashboardMessage,
} from "./routes/DashboardPortalRoutes";

// redux
import { portalApiMap } from "../../store/actions/portalAction";
import { connect } from "react-redux";

// styles
import styles from "./PortalDashboard.module.css";

// components
import Loading from "../Loading/Loading";

// Lazy load components
const DashboardHeader = lazy(() => import("./components/DashboardHeader"));
const PortalBarChart = lazy(() => import("./components/PortalBarChart"));
const PortalProgressCard = lazy(() => import("./components/PortalProgressCard"));
const DashboardOnboardingAssessmentCard = lazy(() =>
  import("./components/DashboardOnboardingAssessmentCard")
);
const GenderStatsChart = lazy(() => import("./components/GenderStatsChart"));
const AverageAge = lazy(() => import("./components/AverageAge"));
const DateRange = lazy(() => import("./components/DateRange"));

const PortalDashboard = ({ portalApiMap }) => {
  // Default to Last 30 Days for active users fetch
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(new Date());

  // Active users data is fetched on mount (or on submit via DateRange)
  const [activeUsersData, setActiveUsersData] = useState([]);
  // New state for active users trends data
  const [activeUsersTrends, setActiveUsersTrends] = useState(null);

  // Other data that may change when the date range is changed
  const [onboardingStatsData, setOnboardingStatsData] = useState(null);
  const [genderData, setGenderData] = useState([]);
  const [ageStatsData, setAgeStatsData] = useState([]);
  const [adoptionStatsData, setAdoptionStatsData] = useState(null);

  // New state for the customer dashboard message
  const [dashboardMessage, setDashboardMessage] = useState(null);

  // Global loading and error states for "other" data
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRange, setSelectedRange] = useState("Last 30 Days")

// -----------------------------------------------------
// 1. Fetch Active Users Data on Date Change
// -----------------------------------------------------
// In your PortalDashboard component
useEffect(() => {
  setIsLoading(true);

  const debounceDelay = selectedRange === "Custom Range" ? 1200 : 0;
  const debounceTimer = setTimeout(() => {
    const loadActiveUsers = async () => {
      try {
        const data = await fetchPortalCustomerActiveUsers(
          portalApiMap,
          startDate.toISOString(),
          endDate.toISOString()
        );
        // Update with new data without clearing immediately
        setActiveUsersData(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error("Error fetching active users:", err);
      } finally {
        setIsLoading(false);
      }
    };

    loadActiveUsers();
  }, debounceDelay);

  return () => clearTimeout(debounceTimer);
}, [selectedRange, startDate, endDate]);



// -----------------------------------------------------
// 2. Fetch Active Users Trends Data on Date Change
// -----------------------------------------------------
useEffect(() => {
  const debounceDelay = selectedRange === "Custom Range" ?  1200 : 0;
  const debounceTimer = setTimeout(() => {
    const loadActiveUsersTrends = async () => {
      try {
        const trendsData = await fetchPortalCustomerActiveUsersTrends(
          portalApiMap,
          startDate.toISOString(),
          endDate.toISOString()
        );
        setActiveUsersTrends(trendsData);
      } catch (err) {
        console.error("Error fetching active users trends:", err);
      }
    };
    loadActiveUsersTrends();
  }, debounceDelay);

  return () => clearTimeout(debounceTimer);
}, [portalApiMap, selectedRange, startDate, endDate]);


  // -----------------------------------------------------
  // 3. Fetch Other Data (Onboarding, Gender, Age, Adoption, Dashboard Message)
  // -----------------------------------------------------
  useEffect(() => {
    const fetchOtherData = async () => {
      setLoading(true);
      setError(null);
      try {
        const [
          onboardingStats,
          genderStats,
          ageStats,
          adoptionStats,
          messageData
        ] = await Promise.all([
          fetchPortalCustomerOnboardingStats(portalApiMap),
          fetchPortalCustomerGenderStats(portalApiMap),
          fetchPortalCustomerAgeStats(portalApiMap),
          fetchPortalCustomerAdoptionStats(portalApiMap),
          fetchCustomerDashboardMessage(portalApiMap)
        ]);
        setOnboardingStatsData(onboardingStats);
        setGenderData(genderStats);
        setAgeStatsData(ageStats);
        setAdoptionStatsData(adoptionStats);
        setDashboardMessage(messageData);
      } catch (err) {
        console.error("Error fetching other data:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchOtherData();
  }, [portalApiMap]);

  return (
    <Suspense fallback={<div><Loading /></div>}>
      {loading ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <Loading />
        </div>
      ) : error ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          Error: {error.message || "Error loading data"}
        </div>
      ) : (
        <>
          {/* Dashboard Header */}
          <div>
            <DashboardHeader dashboardMessage={dashboardMessage} />
          </div>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setActiveUsersData={setActiveUsersData} 
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
          />

          {/* Active Users Chart */}
          <PortalBarChart
            startDate={startDate}
            endDate={endDate}
            activeUsersData={activeUsersData}
            trendsData={activeUsersTrends}
            // loading={loading}
            error={error}
            isLoading={isLoading}
          />

          <div className={styles.container}>
            <DashboardOnboardingAssessmentCard
              onboardingStats={onboardingStatsData}
              loading={loading}
              error={error}
            />
            <GenderStatsChart
              data={genderData}
              loading={loading}
              error={error}
            />

            {/* Average Age Component */}
            <AverageAge data={ageStatsData} loading={loading} error={error} />

            <PortalProgressCard
              title="Adoption"
              data={adoptionStatsData}
              loading={loading}
              error={error}
            />
          </div>
        </>
      )}
    </Suspense>
  );
};

const actions = {
  portalApiMap,
};

export default connect(null, actions)(PortalDashboard);
