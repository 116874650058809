import React, {useState} from 'react';

// styles
import styles from './section_4.module.css';

// components
import FemaleLayingDown from '../../../images/yoga_pose.jpg';
import MaleLayingDown from '../../../images/guy_lying_down.jpg';
import {PrimaryButton} from "../../buttons/buttons";

const Section_4_Intro = props => {
    // props
    const {next} = props;
    // local
    const [ready, setReady] = useState(0);
    return (
        <div className={`${ready === 2 ? styles.fadeIn : ''} ${styles.container}`}>
            <h2 className={styles.heading}>YOUR <strong>RECOVERY</strong> ROUTINE</h2>
            <img onLoad={() => setReady(prevState => prevState+1)} className={styles.image} src={FemaleLayingDown} alt="" data-testID="imgSleepLeft"/>
            <p className={styles.text}>Your sleep routine impacts how you feel and even how quickly you can gain strength. Tell us about your current recovery habits.</p>
            <img onLoad={() => setReady(prevState => prevState+1)} className={styles.image} src={MaleLayingDown} alt="" data-testID="imgSleepRight"/>
            <div className="t-a:c">
                <PrimaryButton onClick={next} data-testID="recBtn">Next</PrimaryButton>
            </div>
        </div>
    )
}
export default Section_4_Intro;