import { ReactComponent as SleepHabitIcon } from '../../images/icons/Sleep_Habit_Icon.svg';
import { ReactComponent as PhysicalActivityIcon } from '../../images/icons/Physical_Activity_Icon.svg';
import { ReactComponent as SprintIntensityIcon } from '../../images/icons/Sprint_Intensity_Icon.svg';
import { ReactComponent as StrengthBuildingIcon } from '../../images/icons/Strength_Building_Icon.svg';
import { ReactComponent as FruitsAndVegetablesIcon } from '../../images/icons/Fruits_And_Vegetables_Icon.svg';
import { ReactComponent as MealtimeSpacingIcon } from '../../images/icons/Mealtime_Spacing_Icon.svg';
import { ReactComponent as SweetenedFoodsIcon } from '../../images/icons/Sweetened_Foods_Icon.svg';
import { ReactComponent as EatingOccasionsIcon } from '../../images/icons/Eating_Occasions_Icon.svg';
import { ReactComponent as SweetenedDrinksIcon } from '../../images/icons/Sweetened_Drinks_Icon.svg';
import { ReactComponent as ProteinIcon } from '../../images/icons/Protein_Icon.svg';
import { ReactComponent as StressReleaseIcon } from '../../images/icons/Stress_Release_Icon.svg';

export const habitProgressIcons = {
  'eating occasions': EatingOccasionsIcon,
  'sweetened foods': SweetenedFoodsIcon,
  'sweetened drinks': SweetenedDrinksIcon,
  'protein': ProteinIcon,
  'vegetables & fruit': FruitsAndVegetablesIcon,
  'mealtime spacing': MealtimeSpacingIcon,
  'sleep': SleepHabitIcon,
  'physical activity': PhysicalActivityIcon,
  'strength building activity': StrengthBuildingIcon,
  'sprint intensity': SprintIntensityIcon,
  'stress releasing activity': StressReleaseIcon
};

export const habitBackgroundColors = {
  'eating occasions': 'rgba(255, 115, 3, 0.15)',  // Orange
  'sweetened foods': 'rgba(107, 165, 67, 0.13)',   // Green
  'sweetened drinks': 'rgba(107, 165, 67, 0.13)',  // Green
  'protein': 'rgba(107, 165, 67, 0.13)',          // Green
  'vegetables & fruit': 'rgba(107, 165, 67, 0.13)', // Green
  'mealtime spacing': 'rgba(255, 190, 0, 0.15)',  // Yellow
  'sleep': 'rgba(78, 136, 161, 0.15)',            // Blue
  'physical activity': 'rgba(78, 136, 161, 0.15)', // Blue
  'strength building activity': 'rgba(78, 136, 161, 0.15)', // Blue
  'sprint intensity': 'rgba(78, 136, 161, 0.15)',  // Blue
  'stress releasing activity': 'rgba(78, 136, 161, 0.15)',  // Blue
};