import React, { useState, useRef, useEffect } from "react";
import styles from "./PortalHeader.module.css";
import { FaBell } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import bottomTriangle from "../../images/portalHeaderTriangle.png";
import Avatar from "../../images/avatar-1577909_960_720.png";
import logoutIcon from "../../images/portalLogout.png";
import { logout } from "../../store/actions/authActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const PortalHeader = ({
  companyName,
  pageTitle,
  userName,
  userRole,
  userImage,
  logout,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  // Create a ref for the user profile container
  const profileRef = useRef(null);

  const handleArrowClick = () => {
    setDropdownVisible((prev) => !prev);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    try {
      logout().then(() => window.location.assign("/"));
    } catch (e) {
      console.error(e);
    }
  };

  // Listen for clicks outside the user profile container to hide the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.headerContainer}>
        {/* Left Side - Company Name and Page Title */}
        <div className={styles.leftSection}>
          <p className={styles.companyName}>{companyName}</p>
          <h1 className={styles.pageTitle}>{pageTitle}</h1>
        </div>

        {/* Right Side - Notification and User Profile */}
        <div className={styles.rightSection}>
          {/* User Profile */}
          <div className={styles.userProfile} ref={profileRef}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={Avatar}
                alt={`${userName}'s profile`}
                className={styles.userImage}
              />
              <div className={styles.userInfo}>
                <p className={styles.userName}>{userName}</p>
                <p className={styles.userRole}>{userRole}</p>
              </div>
            </div>
            <span
              className={styles.dropdownArrowContainer}
              onClick={handleArrowClick}
            >
              <img
                src={bottomTriangle}
                alt="dropdown arrow"
                className={styles.dropdownArrow}
              />
            </span>

            {dropdownVisible && (
              <div className={styles.dropdownMenu}>
                <div className={styles.sidebarLogout} onClick={handleLogout}>
                  <img src={logoutIcon} width={28} height={32} alt="Log Out" />
                  <p className={styles.sidebarLinkText}>Log Out</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const actions = {
  logout,
};

export default withRouter(connect(null, actions)(PortalHeader));
