import React from "react";
import SkeletonText from "../Loading/SkeletonText";
import styles from "./PlanOption.module.css";

// Reusable PlanOption component
const PlanOption = ({
  title,
  subtitle,
  priceText,
  value,
  selectedOption,
  handleOptionChange,
  disabled,
  loading,
  hideDesktopTabs,
}) => (
  <div className={styles.alignSubscriptionContainerFlex}>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className={styles.planOptionTitle}>{title}</span>
      {subtitle && (
        <span className={styles.subscriptionsSubtitle}>{subtitle}</span>
      )}
    </div>
    <div>
      {priceText && (
        <div>
          <p className={styles.planOptionCurrency}>{priceText}</p>
        </div>
      )}
    </div>
    {!hideDesktopTabs && (
      <div>
        {loading ? (
          // Show skeleton loader for cube square when loading
          <label className={styles.radioLabel}>
            <span className={styles.skeletonPlaceholder}>
              <SkeletonText width="20px" height="20px" />
            </span>
          </label>
        ) : (
          <>
            <label className={styles.radioLabel} data-testID="radio-label">
              <input
                type="radio"
                value={value}
                checked={selectedOption && selectedOption.value === value}
                onChange={handleOptionChange}
                className={styles.radioInput}
                disabled={disabled}
                data-testID="radio-input"
              />
            </label>

            <span
              className={`${styles.radioPlaceholder} ${
                selectedOption && selectedOption.value === value
                  ? styles.checked
                  : ""
              }`}
            >
              {selectedOption && selectedOption.value === value && (
                <i className="fa fa-check"></i>
              )}
            </span>
          </>
        )}
      </div>
    )}
  </div>
);

export default PlanOption;
