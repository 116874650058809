// Dashboard.js
import React, { useState } from "react";
import styles from './OrganizationPortal.module.css';
import OrganizationPortalSideMenu from "./components/OrganizationPortalSideMenu";

import { BoxContainer } from "../../containers/boxContainer/boxContainer";
import CustomerPortalUsers from "./ screens/CustomerPortalUsers";
import PortalDashboardMessage from "./ screens/PortalDashboardMessage";
import Documentation from "./ screens/Documentation";

const Dashboard = (props) => {
  const menuItems = [
    { id: "customerPortalUsers", label: "Customer Portal Users" },
    { id: "portalDashboardMessage", label: "Portal Dashboard Message" },
    { id: "documentation", label: "Documentation" },
  ];

  const {organization} = props


  const [activePanel, setActivePanel] = useState("customerPortalUsers");


  const renderPanel = () => {
    switch (activePanel) {
      case "customerPortalUsers":
        return <CustomerPortalUsers organizationId={organization.id} />;
      case "portalDashboardMessage":
        return <PortalDashboardMessage organizationId={organization.id} />;
      case "documentation":
        return <Documentation organizationId={organization.id} />;
      default:
        return <div>Select a panel from the menu.</div>;
    }
  };

  return (
    <div>
      <h3>Customer Portal</h3>
      <BoxContainer className={styles.container}>
     
          {/* Left Side Menu */}
          <div>
            <OrganizationPortalSideMenu
              items={menuItems}
              initialActive="customerPortalUsers"
              onSelect={setActivePanel}
            />
          </div>

    
        
       {/* Main Content Area */}
       <div style={{width: "100%"}}>{renderPanel()}</div>

         

      </BoxContainer>
    </div>
  );
};

export default Dashboard;
