import React from 'react';
import { getHabitColor } from './ProgressUtils';
import styles from './UserProgress.module.css';

const getMaxValue = (habitTitle) => {
  switch(habitTitle.toLowerCase()) {
    case 'sweetened drinks':
    case 'sweetened foods':
      return 6;
    case 'eating occasions':
      return 8;
    default:
      return 6; // fallback
  }
};

export const DotProgress = ({ value, unit, habitTitle }) => {
  const maxValue = getMaxValue(habitTitle);
  const filledCircles = Math.min(Math.floor(value), maxValue);
  const habitColor = getHabitColor(habitTitle);

  return (
    <div className={styles.dotProgressContainer}>
      <span className={styles.dotProgressValue} style={{ color: habitColor }}>
        {(value || 0).toFixed(1)}
      </span>
      <div className={styles.dotProgressCircles}>
        {[...Array(maxValue)].map((_, index) => (
          <div
            key={index}
            className={`${styles.dotProgressCircle} ${index < filledCircles ? styles.dotProgressCircleFilled : styles.dotProgressCircleEmpty}`}
            style={{
              backgroundColor: index < filledCircles ? habitColor : 'transparent',
              borderColor: habitColor,
            }}
          />
        ))}
      </div>
      <span className={styles.dotProgressUnit} style={{ color: habitColor }}>
        {unit}
      </span>
    </div>
  );
};
