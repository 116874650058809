import React, {useState} from "react";
import {connect} from "react-redux";
import moment from "moment";
import validate from "validate.js";
import {isEmpty, isNil, omitBy} from "lodash";
import axios from "axios";

// actions
import {getSingleOrganization} from "../../../actions/action_organizations";
import {
    connectToDaxko,
    disconnectToDaxko,
    setOrgEmailPreference,
} from "../../../store/actions/organizationActions";

// styles
import styles from "./organizationInfo.module.css";

// components
import {ErrorAlert, SaveAlert, SecondaryInfoAlert} from "../../alerts/alerts";
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import NumberFormat from "react-number-format";
import {stateOptions} from "../../Client/ClientProfile/StatesData";
import {BasicButton} from "../../buttons/buttons";
import {InfoAlert} from "../../alerts/alerts";
import {organizationApi} from "../../../api/organizationApi";
import {ORG_STATUSES} from "../../../serverVariables/statuses";
import Loading from "../../Loading/Loading";

validate.extend(validate.validators.datetime, {
    parse: function (value, options) {
        return +moment.utc(value);
    },
    // Input is a unix timestamp
    format: function (value, options) {
        const format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
        return moment.utc(value).format(format);
    },
});

// form submission constraints
const orgConstraints = {
    name: {
        presence: {
            message: "^Organization name is required",
        },
        type: "string",
        length: {
            minimum: 1,
            maximum: 255,
            tooShort: "^Organization name is required",
            tooLong: "^Organization name must be less than 255 characters",
        },
    },
    sap: {
        presence: {
            message: "^SAP account # is required",
        },
        type: "string",
        length: {
            minimum: 1,
            maximum: 255,
            tooShort: "^SAP account # is required",
            tooLong: "^SAP account # must be less than 255 characters",
        },
    },
    channel: {
        presence: {message: "^Channel is required"},
        type: "string",
        length: {
            minimum: 1,
            tooShort: "^Sales channel is required",
            maximum: 255,
            tooLong: "^Sales channel must be less than 255 characters",
        },
    },
    employees: {
        presence: false,
        numericality: {
            onlyInteger: true,
        },
    },
    startDate: {
        presence: {message: "^Start Date is required"},
        datetime: {dateOnly: true},
    },
    endDate: {
        presence: {message: "^End Date is required"},
        datetime: {dateOnly: true},
    },
};
const contactConstraints = {
    name: {
        presence: false,
        type: "string",
        length: {maximum: 255},
    },
    phone: {
        presence: false,
        type: "string",
    },
    email: {
        presence: false,
        type: "string",
        email: {message: "^Enter a valid email"},
        length: {
            minimum: 1,
            maximum: 255,
            tooShort: "^Email is required",
            tooLong: "^Email must be less than 255 characters",
        },
    },
    position: {
        presence: false,
        type: "string",
        length: {maximum: 255},
    },
};
const championConstraints = {
    name: {
        presence: false,
        type: "string",
        length: {maximum: 255},
    },
    phone: {
        presence: false,
        type: "string",
    },
};
const salesConstraints = {
    name: {
        presence: {message: "^Sales Representative is required"},
        type: "string",
        length: {
            minimum: 1,
            tooShort: "^Sales Representative is required",
            maximum: 255,
            tooLong: "^Sales Representative must be less than 255 characters",
        },
    },
    phone: {
        presence: false,
        type: "string",
    },
    email: {
        presence: true,
        type: "string",
        email: true,
        length: {
            minimum: 1,
            maximum: 255,
            tooShort: "^Email is required",
            tooLong: "^Email must be less than 255 characters",
        },
    },
};
const addressConstraints = {
    address: {
        type: "string",
        presence: false,
        length: {maximum: 255},
    },
    state: {
        presence: {message: "^State is required"},
        type: "string",
        length: {
            minimum: 1,
            tooShort: "^State is required",
            maximum: 255,
            tooLong: "^State must be less than 255 characters",
        },
    },
    city: {
        presence: {message: "^City is required"},
        type: "string",
        length: {
            minimum: 1,
            tooShort: "^City is required",
            maximum: 255,
            tooLong: "^City must be less than 255 characters",
        },
    },
    zipcode: {
        type: "string",
        presence: false,
        length: {maximum: 255},
    },
};
const emailValidation = {
    email: {
        presence: false,
        type: "string",
        email: {message: "^Enter a valid email"},
        length: {
            minimum: 1,
            maximum: 255,
            tooShort: "^Email is required",
            tooLong: "^Email must be less than 255 characters",
        },
    },
};
const communityDisplayValidation = {
    display: {
        presence: false,
        type: "string",
        length: {
            minimum: 1,
            tooShort: "^Display name is required",
            maximum: 20,
            tooLong: "^Display name must be less than 21 characters",
        },
    },
};

const OrganizationInfo = ({
                              organization,
                              getSingleOrganization,
                              ...props
                          }) => {
    const [formState, updateState] = useState({
        name: organization.name || "",
        channel: organization.sales_channel || "",
        sap:
            organization.sap !== null && organization.sap !== undefined
                ? organization.sap.toString()
                : "",
        employees: organization.employees,
        startDate: moment(organization.start_date).isValid()
            ? moment(organization.start_date).format("YYYY-MM-DD")
            : "",
        endDate: moment(organization.start_date).isValid()
            ? moment(organization.end_date).format("YYYY-MM-DD")
            : "",
        contactName: organization.contact_name || "",
        contactNumber: organization.contact_phone
            ? organization.contact_phone.replaceAll("-", "")
            : "",
        contactEmail: organization.contact_email || "",
        contactPosition: organization.contact_position || "",
        championName: organization.champion_name || "",
        championPhone: organization.champion_phone || "",
        championEmail: organization.champion_email || "",
        address: organization.address || "",
        city: organization.city || "",
        state: organization.state || "",
        zip: organization.zip || "",
        salesName: organization.sales_rep_name || "",
        salesNumber: organization.sales_rep_phone || "",
        salesEmail: organization.sales_rep_email || "",
        emailList: organization.wellness_resources || false,
        communityShowAll: !!organization.community_all,
        communityShowOrg: !!organization.community_org,
        communityDisplayName: organization.community_name || "",
        assessmentOptional: organization.assessment_optional || false,
        wotdAccess: organization.wotd_access,
        signUpCall: organization.wotd_signup,
        multifactor: organization.multifactor || "",
    });
    const [changes, setChanges] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);
    const [validation, updateValidation] = useState({});
    const [daxkoId, setDaxkoId] = useState(organization.daxko_scope || "");
    const [connected, setConnected] = useState(organization.daxko_scope);
    const [saveConnect, setSaveConnect] = useState(false);
    const [editScope, setEditScope] = useState(false);
    const [connectError, setConnectError] = useState(false);
    const [archived, setArchived] = useState({
        status: organization.status_id,
        loading: false,
    });

    function setForm(value) {
        setChanges(true);
        updateState((prevState) => ({
            ...prevState,
            ...value,
        }));
    }

    function setValidation(value) {
        updateValidation((prevState) => ({
            ...prevState,
            ...value,
        }));
    }

    const showPanel = (button, id) => {
        // grab all panels and hide
        const panels = document.querySelectorAll(`.${styles.panel}`);
        for (const panel of panels) {
            panel.classList.remove(styles.show);
        }
        // find panel is question
        const showPanel = document.getElementById(id);
        if (showPanel) showPanel.classList.add(styles.show);

        // grab all menu items
        const menu = document.querySelectorAll(`.${styles.listItem}`);
        for (const item of menu) {
            item.classList.remove(styles.active);
        }
        button.classList.add(styles.active);
    };

    const toggle = (id) => {
        const content = document.getElementById(id);
        if (content.classList.contains(styles.show)) {
            content.classList.remove(styles.show);
        } else {
            content.classList.add(styles.show);
        }
    };

    const submit = async (e) => {
        e.preventDefault();
        const errors = {};

        // validate org
        const {
            name,
            channel,
            sap,
            employees,
            startDate,
            endDate,
            emailList,
            communityShowAll,
            communityShowOrg,
            communityDisplayName,
            wotdAccess,
            signUpCall,
            assessmentOptional,
            multifactor,
        } = formState;

        if (moment(startDate).isValid()) {
            orgConstraints.endDate.datetime.earliest = moment(startDate).utc();
        }

        const orgValues = {name, channel, sap, startDate, endDate};
        if (!isNaN(parseInt(employees))) orgValues.employees = employees;
        const orgValidation = validate(orgValues, orgConstraints);
        errors.orgValidation = orgValidation;

        // validate contact person
        const {
            contactName,
            contactNumber,
            contactEmail,
            contactPosition,
        } = formState;
        const contactInput = {
            name: contactName,
            phone: contactNumber,
            position: contactPosition,
        };
        if (contactEmail.length > 0) contactInput.email = contactEmail;
        errors.contactValidation = validate(contactInput, contactConstraints);

        // validate champion
        const {championName, championPhone, championEmail} = formState;
        const championInput = {
            name: championName,
            phone: championPhone,
            email: championEmail,
        };
        const updatedConstraints = championConstraints;
        if (championInput.email) updatedConstraints.email = emailValidation.email;
        errors.championValidation = validate(championInput, championConstraints);

        // validate address
        const {address, city, state, zip} = formState;
        errors.addressValidation = validate(
            {
                address,
                state,
                city,
                zipcode: zip,
            },
            addressConstraints
        );

        // sales validation
        const {salesName, salesEmail, salesNumber} = formState;
        const salesInput = {name: salesName, phone: salesNumber};
        if (salesEmail.length) salesInput.email = salesEmail;
        errors.salesValidation = validate(salesInput, salesConstraints);

        // validate community
        if (communityShowOrg) {
            // only test display name if org is chosen
            errors.communityDisplayValidation = validate(
                {display: communityDisplayName},
                communityDisplayValidation
            );
        }
        if (!communityShowOrg && !communityShowAll) {
            errors.communityValidation = {options: ["One option must be selected"]};
        } else {
            errors.communityValidation = null;
        }

        setValidation(errors);
        if (!isEmpty(omitBy(errors, isNil))) return;

        const formData = new FormData();
        const data = {
            name,
            sap,
            sales_channel: channel,
            employees,
            start_date: startDate,
            end_date: endDate,
            contact_name: contactName,
            contact_phone: contactNumber,
            contact_position: contactPosition,
            contact_email: contactEmail,
            champion_name: championName,
            champion_phone: championPhone,
            champion_email: championEmail,
            address,
            city,
            state,
            zip,
            sales_rep_name: salesName,
            sales_rep_phone: salesNumber,
            sales_rep_email: salesEmail,
            community_name: communityDisplayName,
            community_all: communityShowAll,
            community_org: communityShowOrg,
            wotd_access: wotdAccess,
            wotd_signup: signUpCall,
            assessment_optional: assessmentOptional,
            multifactor,
        };
        const keys = Object.keys(data);
        keys.forEach((key) => {
            if (isNil(data[key]) || data[key] === "") return;
            formData.append(key, data[key]);
        });

        try {
            await axios.post(`/organizations/update/${organization.id}`, formData);
            await setOrgEmailPreference(organization.id, emailList);
            setChanges(false);
            getSingleOrganization(organization.id);
        } catch (e) {
            setSubmissionError(true);
        }
    };

    async function connectDaxko() {
        try {
            setSaveConnect(true);
            await connectToDaxko(organization.id, daxkoId);
            await getSingleOrganization(organization.id);
            setSaveConnect(false);
            setEditScope(false);
            setConnectError(false);
            setConnected(true);
        } catch (e) {
            setSaveConnect(false);
            setEditScope(true);
            setConnectError(true);
            setConnected(false);
        }
    }

    async function disconnectDaxko() {
        try {
            setSaveConnect(true);
            await disconnectToDaxko(organization.id);
            await getSingleOrganization(organization.id);
            setDaxkoId("");
            setSaveConnect(false);
            setEditScope(false);
            setConnectError(false);
            setConnected(false);
        } catch (e) {
            setSaveConnect(false);
            setEditScope(true);
            setConnectError(true);
            setConnected(true);
        }
    }

    async function changeOrgStatus(val) {
        try {
            if (archived.loading) return;
            let flippedStatus;
            if (val === ORG_STATUSES.ACTIVE) flippedStatus = "inactive";
            else flippedStatus = "active";
            setArchived((prevState) => ({...prevState, loading: true}));
            const {
                data: {result: update},
            } = await organizationApi().changeStatus(organization.id, flippedStatus);
            await getSingleOrganization(organization.id);
            setArchived({status: update.status_id, loading: false});
        } catch (e) {
            console.log(e);
            setArchived((prevState) => ({...prevState, loading: false}));
        }
    }

    function showArchivedText() {
        if (archived.loading)
            return (
                <div className="d:i-b">
                    <Loading type="ThreeDots"/>
                </div>
            );
        if (archived.status === ORG_STATUSES.ACTIVE)
            return (
                <button
                    onClick={() => changeOrgStatus(organization.status_id)}
                    className={styles.archiveButton}
                >
                    Archive
                </button>
            );
        if (archived.status === ORG_STATUSES.INACTIVE)
            return (
                <button
                    onClick={() => changeOrgStatus(organization.status_id)}
                    className={styles.archiveButton}
                >
                    Activate
                </button>
            );
    }

    return (
        <div>
            <div className="container">
                <div className="row margin-top-2x">
                    <div className="col-xs-12 col-sm-8">
                        <h2 className="m:0">Info</h2>
                        {changes ? <InfoAlert>You have unsaved changes</InfoAlert> : null}
                        {submissionError ? (
                            <ErrorAlert>Unable to update organization.</ErrorAlert>
                        ) : null}
                    </div>
                    <div className={`col-xs-12 col-sm-4 ${styles.addButton}`}>
                        <BasicButton
                            onClick={submit}
                            className="btn primary btn btn-default"
                        >
                            + Save Changes
                        </BasicButton>
                    </div>
                </div>
            </div>
            <div className="container margin-top-2x">
                <BoxContainer className={` ${styles.container}`}>
                    <div className={`hidden-xs hidden-sm ${styles.listMenu}`}>
                        <ul className={styles.list}>
                            <li
                                className={`${styles.listItem} ${styles.active}`}
                                onClick={(e) => showPanel(e.target, "orgInfo")}
                            >
                                Organization Info
                                {validation.orgValidation ? (
                                    <span className={styles.errorCircle}/>
                                ) : null}
                            </li>
                            <li
                                className={styles.listItem}
                                onClick={(e) => showPanel(e.target, "contactPerson")}
                            >
                                Contact Person
                                {validation.contactValidation ? (
                                    <span className={styles.errorCircle}/>
                                ) : null}
                            </li>
                            <li
                                className={styles.listItem}
                                onClick={(e) => showPanel(e.target, "champion")}
                            >
                                Treo Champion
                                {validation.championValidation ? (
                                    <span className={styles.errorCircle}/>
                                ) : null}
                            </li>
                            <li
                                className={styles.listItem}
                                onClick={(e) => showPanel(e.target, "address")}
                            >
                                Address
                                {validation.addressValidation ? (
                                    <span className={styles.errorCircle}/>
                                ) : null}
                            </li>
                            <li
                                className={styles.listItem}
                                onClick={(e) => showPanel(e.target, "salesRep")}
                            >
                                Sales Rep
                                {validation.salesValidation ? (
                                    <span className={styles.errorCircle}/>
                                ) : null}
                            </li>
                            <li
                                className={styles.listItem}
                                onClick={(e) => showPanel(e.target, "connect")}
                            >
                                Connect
                            </li>
                            <li
                                className={styles.listItem}
                                onClick={(e) => showPanel(e.target, "community")}
                            >
                                Community
                            </li>
                            <li
                                className={styles.listItem}
                                onClick={(e) => showPanel(e.target, "manage")}
                            >
                                Settings
                            </li>
                        </ul>
                    </div>
                    <form onSubmit={submit} className={styles.panelMain}>
                        <div id="orgInfo" className={`${styles.panel} ${styles.show} `}>
                            <h3 onClick={() => toggle("orgInfo")} className={styles.heading}>
                                Organization Info
                            </h3>
                            <div className={styles.content}>
                                <div className="row flex-wrap">
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="name">
                                            Organization Name{" "}
                                            <span className="text-danger">* required</span>
                                        </label>
                                        <input
                                            value={formState.name}
                                            onChange={(e) => setForm({name: e.target.value})}
                                            id="name"
                                            className="form-control"
                                            type="text"
                                        />
                                        {validation.orgValidation &&
                                        validation.orgValidation.name ? (
                                            <div>
                                                {validation.orgValidation.name.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="sales">Sales Channel</label>
                                        <select
                                            id="sales"
                                            className="form-control"
                                            value={formState.channel}
                                            onChange={(e) => setForm({channel: e.target.value})}
                                        >
                                            <option value="" selected disabled>
                                                Select a channel
                                            </option>
                                            <option value="corporate">Corporate</option>
                                            <option value="club">Club</option>
                                            <option value="clinical">Clinical</option>
                                            <option value="retail">Retail</option>
                                        </select>
                                        {validation.orgValidation &&
                                        validation.orgValidation.channel ? (
                                            <div>
                                                {validation.orgValidation.channel.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="account">
                                            SAP Account #
                                            <span className="text-danger">* required</span>
                                        </label>
                                        <input
                                            value={formState.sap}
                                            onChange={(e) => setForm({sap: e.target.value})}
                                            id="account"
                                            className="form-control"
                                            type="text"
                                        />
                                        {validation.orgValidation &&
                                        validation.orgValidation.sap ? (
                                            <div>
                                                {validation.orgValidation.sap.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="account">Number of employees</label>
                                        <input
                                            value={formState.employees}
                                            onChange={(e) => setForm({employees: e.target.value})}
                                            id="employees"
                                            className="form-control"
                                            type="number"
                                        />
                                        {validation.orgValidation &&
                                        validation.orgValidation.employees ? (
                                            <div>
                                                {validation.orgValidation.employees.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="startDate">
                                            Start Date<span className="text-danger">* required</span>
                                        </label>
                                        <input
                                            value={formState.startDate}
                                            onChange={(e) => setForm({startDate: e.target.value})}
                                            id="startDate"
                                            className="form-control"
                                            type="date"
                                        />
                                        {validation.orgValidation &&
                                        validation.orgValidation.startDate ? (
                                            <div>
                                                {validation.orgValidation.startDate.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="endDate">
                                            End Date<span className="text-danger">* required</span>
                                        </label>
                                        <input
                                            value={formState.endDate}
                                            onChange={(e) => setForm({endDate: e.target.value})}
                                            id="endDate"
                                            className="form-control"
                                            type="date"
                                        />
                                        {validation.orgValidation &&
                                        validation.orgValidation.endDate ? (
                                            <div>
                                                {validation.orgValidation.endDate.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="contactPerson" className={styles.panel}>
                            <h3
                                onClick={() => toggle("contactPerson")}
                                className={styles.heading}
                            >
                                Contact Person
                            </h3>
                            <div className={styles.content}>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="contactName">Name</label>
                                        <input
                                            value={formState.contactName}
                                            onChange={(e) => setForm({contactName: e.target.value})}
                                            className="form-control"
                                            type="text"
                                            id="contactName"
                                        />
                                        {validation.contactValidation &&
                                        validation.contactValidation.name ? (
                                            <div>
                                                {validation.contactValidation.name.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="contactNumber">Phone Number</label>
                                        <NumberFormat
                                            className="form-control"
                                            id="contactNumber"
                                            format="(###) ###-####"
                                            mask=""
                                            onChange={(e) =>
                                                setForm({
                                                    contactNumber: e.target.value.replace(/\D/g, ""),
                                                })
                                            }
                                            value={formState.contactNumber}
                                        />
                                        {validation.contactValidation &&
                                        validation.contactValidation.phone ? (
                                            <div>
                                                {validation.contactValidation.phone.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="contactPosition">Position</label>
                                        <input
                                            value={formState.contactPosition}
                                            onChange={(e) =>
                                                setForm({contactPosition: e.target.value})
                                            }
                                            className="form-control"
                                            type="text"
                                            id="contactPosition"
                                        />
                                        {validation.contactValidation &&
                                        validation.contactValidation.position ? (
                                            <div>
                                                {validation.contactValidation.position.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="contactEmail">Email</label>
                                        <input
                                            value={formState.contactEmail}
                                            onChange={(e) =>
                                                setForm({contactEmail: e.target.value})
                                            }
                                            className="form-control"
                                            type="text"
                                            id="contactEmail"
                                        />
                                        {validation.contactValidation &&
                                        validation.contactValidation.email ? (
                                            <div>
                                                {validation.contactValidation.email.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="champion" className={styles.panel}>
                            <h3 onClick={() => toggle("champion")} className={styles.heading}>
                                Treo Champion
                            </h3>
                            <div className={styles.content}>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <SecondaryInfoAlert>
                                            <h3 className="m-t:0">What is a Treo Champion</h3>
                                            <p>
                                                The Treo champion should spend no more than 1-3 hours
                                                per month on implementing the Treo program. This
                                                individual should be somebody who can commit to
                                                receiving and responding to correspondence from your
                                                company's dedicated Treo Account Manager that includes:
                                            </p>
                                            <ul>
                                                <li>Updates to coaching sign-ups</li>
                                                <li>
                                                    Updates to your company BOX folder (promotional
                                                    material, policies, etc.)
                                                </li>
                                                <li>Coaching Success Metrics</li>
                                                <li>
                                                    Requests for phone or in-person touch bases to review
                                                    progress & feedback
                                                </li>
                                                <li>
                                                    Forwarding questions you receive from employees about
                                                    Treo
                                                </li>
                                            </ul>
                                        </SecondaryInfoAlert>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="championName">Name</label>
                                        <input
                                            value={formState.championName}
                                            onChange={(e) =>
                                                setForm({championName: e.target.value})
                                            }
                                            className="form-control"
                                            type="text"
                                            id="championName"
                                        />
                                        {validation.championValidation &&
                                        validation.championValidation.name ? (
                                            <div>
                                                {validation.championValidation.name.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="championNumber">Phone Number</label>
                                        <NumberFormat
                                            className="form-control"
                                            id="championNumber"
                                            format="(###) ###-####"
                                            mask=""
                                            onChange={(e) =>
                                                setForm({
                                                    championPhone: e.target.value.replace(/\D/g, ""),
                                                })
                                            }
                                            value={formState.championPhone}
                                        />
                                        {validation.championValidation &&
                                        validation.championValidation.phone ? (
                                            <div>
                                                {validation.championValidation.phone.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="championEmail">Email</label>
                                        <input
                                            value={formState.championEmail}
                                            onChange={(e) =>
                                                setForm({championEmail: e.target.value})
                                            }
                                            className="form-control"
                                            type="text"
                                            id="championEmail"
                                        />
                                        {validation.championValidation &&
                                        validation.championValidation.email ? (
                                            <div>
                                                {validation.championValidation.email.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="address" className={styles.panel}>
                            <h3 onClick={() => toggle("address")} className={styles.heading}>
                                Address
                            </h3>
                            <div className={styles.content}>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="addressStreet">Address</label>
                                        <input
                                            value={formState.address}
                                            onChange={(e) => setForm({address: e.target.value})}
                                            type="text"
                                            id="addressStreet"
                                            className="form-control"
                                        />
                                        {validation.addressValidation &&
                                        validation.addressValidation.address ? (
                                            <div>
                                                {validation.addressValidation.address.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="addressCity">City</label>
                                        <input
                                            value={formState.city}
                                            onChange={(e) => setForm({city: e.target.value})}
                                            type="text"
                                            id="addressCity"
                                            className="form-control"
                                        />
                                        {validation.addressValidation &&
                                        validation.addressValidation.city ? (
                                            <div>
                                                {validation.addressValidation.city.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="addressState">State</label>
                                        <select
                                            value={formState.state}
                                            onChange={(e) => setForm({state: e.target.value})}
                                            id="addressState"
                                            className="form-control"
                                        >
                                            {stateOptions.map((state) => (
                                                <option value={state.value}>{state.label}</option>
                                            ))}
                                        </select>
                                        {validation.addressValidation &&
                                        validation.addressValidation.state ? (
                                            <div>
                                                {validation.addressValidation.state.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="addressZip">Zip Code</label>
                                        <input
                                            value={formState.zip}
                                            onChange={(e) => setForm({zip: e.target.value})}
                                            type="text"
                                            id="addressZip"
                                            className="form-control"
                                        />
                                        {validation.addressValidation &&
                                        validation.addressValidation.zipcode ? (
                                            <div>
                                                {validation.addressValidation.zipcode.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="salesRep" className={styles.panel}>
                            <h3 onClick={() => toggle("salesRep")} className={styles.heading}>
                                Sales Rep
                            </h3>
                            <div className={styles.content}>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="salesName">Name</label>
                                        <input
                                            value={formState.salesName}
                                            onChange={(e) => setForm({salesName: e.target.value})}
                                            className="form-control"
                                            type="text"
                                            id="salesName"
                                        />
                                        {validation.salesValidation &&
                                        validation.salesValidation.name ? (
                                            <div>
                                                {validation.salesValidation.name.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="salesNumber">Phone Number</label>
                                        <NumberFormat
                                            className="form-control"
                                            id="salesNumber"
                                            format="(###) ###-####"
                                            mask=""
                                            onChange={(e) =>
                                                setForm({
                                                    salesNumber: e.target.value.replace(/\D/g, ""),
                                                })
                                            }
                                            value={formState.salesNumber}
                                        />
                                        {validation.salesValidation &&
                                        validation.salesValidation.phone ? (
                                            <div>
                                                {validation.salesValidation.phone.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="salesEmail">Email</label>
                                        <input
                                            value={formState.salesEmail}
                                            onChange={(e) => setForm({salesEmail: e.target.value})}
                                            className="form-control"
                                            type="text"
                                            id="salesEmail"
                                        />
                                        {validation.salesValidation &&
                                        validation.salesValidation.email ? (
                                            <div>
                                                {validation.salesValidation.email.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="connect" className={styles.panel}>
                            <h3 onClick={() => toggle("connect")} className={styles.heading}>
                                Connect
                            </h3>
                            <div className={styles.content}>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 form-group">
                                        <label htmlFor="daxkoId">Daxko Id</label>
                                        {!organization.daxko_scope || editScope ? (
                                            <>
                                                <input
                                                    value={daxkoId}
                                                    onChange={(e) => setDaxkoId(e.target.value)}
                                                    className="form-control margin-bottom-15"
                                                    type="text"
                                                    id="daxkoId"
                                                />
                                                {connectError ? (
                                                    <div>
                            <span className="text-danger">
                              Unable to make connection. Please check your Daxko
                              Id and try again.
                            </span>
                                                    </div>
                                                ) : null}
                                                {!saveConnect ? (
                                                    <BasicButton onClick={connectDaxko}>
                                                        Connect
                                                    </BasicButton>
                                                ) : (
                                                    <SaveAlert complete={!saveConnect}/>
                                                )}
                                            </>
                                        ) : (
                                            <div className="d:f">
                                                {organization.daxko_scope}
                                                <button
                                                    onClick={() => setEditScope(true)}
                                                    className={styles.edit}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    onClick={disconnectDaxko}
                                                    className={styles.edit}
                                                >
                                                    Disconnect
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <h4 className="margin-bottom-15">Branches</h4>
                                        {!connected ? (
                                            <SecondaryInfoAlert>
                                                Connect organization to load branches
                                            </SecondaryInfoAlert>
                                        ) : (
                                            <div>
                                                {organization.branches.map((branch) => (
                                                    <div className={styles.branch}>
                                                        <h5>
                                                            {branch.name} ({branch.partner_branch_id})
                                                        </h5>
                                                        <p className="m:0">{branch.address}</p>
                                                        <p className="m:0">
                                                            {branch.city} {branch.state}, {branch.zip}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="community" className={styles.panel}>
                            <h3
                                onClick={() => toggle("community")}
                                className={styles.heading}
                            >
                                Community
                            </h3>
                            <div className={styles.content}>
                                <div className="row">
                                    <div className="col-xs-12 form-group">
                                        <h4>
                                            Select visible communities{" "}
                                            <span className="text-danger">1 required</span>
                                        </h4>
                                        <div className={styles.communityCheckboxes}>
                                            <div className="margin-right-25">
                                                <label htmlFor="allMembers">All Members</label>
                                                <input
                                                    id="allMembers"
                                                    type="checkbox"
                                                    checked={formState.communityShowAll}
                                                    onChange={(e) =>
                                                        setForm({communityShowAll: e.target.checked})
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="orgMembers">Org Members</label>
                                                <input
                                                    id="orgMembers"
                                                    type="checkbox"
                                                    checked={formState.communityShowOrg}
                                                    onChange={(e) =>
                                                        setForm({communityShowOrg: e.target.checked})
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {validation.communityValidation &&
                                        validation.communityValidation.options ? (
                                            <div>
                                                {validation.communityValidation.options.map((err) => (
                                                    <span className="d:b text-danger">{err}</span>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>

                                    {formState.communityShowOrg ? (
                                        <div className="col-xs-12 col-sm-6 col-md-4 form-group">
                                            <label>Display Name</label>
                                            <input
                                                value={formState.communityDisplayName}
                                                onChange={(e) =>
                                                    setForm({communityDisplayName: e.target.value})
                                                }
                                                className="form-control"
                                                type="text"
                                            />
                                            {validation.communityDisplayValidation &&
                                            validation.communityDisplayValidation.display ? (
                                                <div>
                                                    {validation.communityDisplayValidation.display.map(
                                                        (err) => (
                                                            <span className="d:b text-danger">{err}</span>
                                                        )
                                                    )}
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div id="manage" className={styles.panel}>
                            <h3 onClick={() => toggle("manage")} className={styles.heading}>
                                Settings
                            </h3>
                            <h4>Customize the member experience</h4>
                            <div className={styles.content}>
                                <div className="row">
                                    <div className="col-xs-12 form-group">
                                        <h4>Archive this organization</h4>
                                        {showArchivedText()}
                                        {archived.status === ORG_STATUSES.INACTIVE ? (
                                            <p>
                                                Setting an organization to active will allow any member
                                                enrolled in it to log in.
                                            </p>
                                        ) : (
                                            <p>
                                                Archiving an organization will prevent any members
                                                currently enrolled in it from logging in.
                                            </p>
                                        )}

                                        <div className={styles.emailList}>
                                            <input
                                                checked={formState.wotdAccess}
                                                onChange={(e) =>
                                                    setForm({
                                                        ...formState,
                                                        wotdAccess: e.target.checked,
                                                        signUpCall: e.target.checked
                                                            ? formState.signUpCall
                                                            : false, // Uncheck "Sign Up Call" when "Workout of the Day Access" is unchecked
                                                    })
                                                }
                                                id="WotdAccess" // Change the ID to be unique
                                                value={formState.wotdAccess}
                                                className="form-control"
                                                type="checkbox"
                                            />
                                            <label htmlFor="WotdAccess">
                                                Workout of the Day Access
                                            </label>
                                        </div>
                                        <div
                                            style={{marginLeft: "35px"}}
                                            className={styles.emailList}
                                        >
                                            <span className={styles.secondaryCheckmarkLine}></span>
                                            <input
                                                checked={formState.signUpCall}
                                                onChange={(e) => {
                                                    setForm({
                                                        ...formState,
                                                        signUpCall: e.target.checked,
                                                    });
                                                }}
                                                id="SignUpCall" // Change the ID to be unique
                                                value={formState.signUpCall}
                                                className="form-control"
                                                type="checkbox"
                                                disabled={!formState.wotdAccess}
                                            />

                                            <label htmlFor="Workout">Sign Up Call</label>
                                        </div>

                                        <div className={styles.emailList}>
                                            <input
                                                checked={formState.assessmentOptional}
                                                onChange={(e) =>
                                                    setForm({
                                                        ...formState,
                                                        assessmentOptional: e.target.checked,
                                                    })
                                                }
                                                id="Access"
                                                value={formState.assessmentOptional}
                                                className="form-control"
                                                type="checkbox"
                                            />
                                            <label htmlFor="Optional Assessment">Optional Lifestyle assessment</label>
                                        </div>


                                        <div className={styles.emailList}>
                                            <input
                                                checked={formState.emailList}
                                                onChange={(e) =>
                                                    setForm({emailList: e.target.checked})
                                                }
                                                id="subscribe"
                                                className="form-control"
                                                type="checkbox"
                                            />
                                            <label htmlFor="subscribe">
                                                Subscribe members to email lists
                                            </label>
                                        </div>

                                        <div className={styles.emailList}>
                                            <input
                                                checked={formState.multifactor}
                                                onChange={(e) =>
                                                    setForm({multifactor: e.target.checked})
                                                }
                                                id="multifactor"
                                                className="form-control"
                                                type="checkbox"
                                            />
                                            <label htmlFor="multifactor">
                                                Require multi-factor authentication for members
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </BoxContainer>
            </div>
        </div>
    );
};

const actions = {
    getSingleOrganization,
};
export default connect(null, actions)(OrganizationInfo);
