import React, { useEffect, useState, Suspense } from "react";
import { withRouter } from "react-router-dom";
// redux
import { connect } from "react-redux";
import { portalApiMap } from "../../../store/actions/portalAction";

// components
import PortalCard from "../../portalCard/PortalCard";
import Loading from "../../Loading/Loading";
import RankMedal from "../../rankMedal/rankMedal";
import PortalPagination from "../../portalPagination/PortalPagination";
import MonthSelector from "../../monthSelector/MonthSelector";
// routes
import { fetchPortalCustomerBadges } from "../routes/DashboardPortalRoutes";
import styles from "./PortalBadgeTable.module.css";
import Badge from "../../badge/badge";

const PortalBadgeTable = ({ portalApiMap, organizationId }) => {
  // Set initial selected month to the first day of the current month
  const [selectedMonth, setSelectedMonth] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [badgeData, setBadgeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 25; // default number of items per page

  const getPortalRank = async (date, page = currentPage, limitVal = limit) => {
    try {
      setLoading(true);
      setError(null);
      // Pass the month start date and pagination parameters to your API call
      const response = await fetchPortalCustomerBadges(
        date.toISOString(),
        organizationId,
        page,
        limitVal,
        portalApiMap
      );
      if (response && response.user_data) {
        setBadgeData(response.user_data);
        if (response.total) {
          setTotalPages(Math.ceil(response.total / limitVal));
        }
      }
    } catch (err) {
      console.error("Error fetching badge info:", err);
      setError(err);
    } finally {
      setLoading(false);
      if (initialLoad) {
        setInitialLoad(false);
      }
    }
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    getPortalRank(selectedMonth, page, limit);
  };

  const handleMonthChange = (newMonth) => {
    setSelectedMonth(newMonth);
    setCurrentPage(1); // reset page when month changes
    getPortalRank(newMonth, 1, limit);
  };

  useEffect(() => {
    if (portalApiMap && selectedMonth) {
      getPortalRank(selectedMonth, currentPage, limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalApiMap, selectedMonth]);

  return (
    <Suspense
      fallback={
        <div className={styles.centeredLoading}>
          <Loading />
        </div>
      }
    >
      {initialLoad ? (
        <div className={styles.centeredLoading}>
          <Loading />
        </div>
      ) : error ? (
        <div className={styles.centeredLoading}>
          Error: {error.message || "Error loading data"}
        </div>
      ) : (
        <div>
          {/* Month selector to navigate between months */}
          <div className={styles.monthlySelectorContainer}>
            <MonthSelector
              currentMonth={selectedMonth}
              onMonthChange={handleMonthChange}
            />
          </div>
          <PortalCard className={styles.portalCardContainer}>
            {loading && (
              <div className={styles.tableLoadingOverlay}>
                <Loading />
              </div>
            )}
            <h2>Achievements</h2>
            <div
              className={`${styles.content} ${!loading ? styles.loaded : ""}`}
            >
              <table className={styles.fullWidthTable}>
                <thead>
                  <tr className={styles.tableRowHeader}>
                    <th className={styles.tableHeaderCell}>First Name</th>
                    <th className={styles.tableHeaderCell}>Last Name</th>
                    <th className={styles.tableHeaderCell}>Rank</th>
                    <th className={styles.tableHeaderCell}>Level</th>
                    <th className={styles.tableHeaderCell}>
                      Achievement Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {badgeData.length === 0 ? (
                    <tr>
                      <td colSpan="5" className={styles.noUsersCell}>
                        No users found.
                      </td>
                    </tr>
                  ) : (
                    badgeData.map((badge, index) => (
                      <tr
                        key={index}
                        className={
                          index % 2 === 0 ? styles.evenRow : styles.oddRow
                        }
                      >
                        <td className={styles.tableCell}>
                          {badge.first_name}
                        </td>
                        <td className={styles.tableCell}>
                          {badge.last_name}
                        </td>
                        <td className={styles.tableCell}>
                          <span className={styles.rankMedalContainer}>
                            <RankMedal
                              rank={badge.rank}
                              style={{ width: "50px", height: "50px" }}
                            />
                          </span>
                          <span>{badge.rank}</span>
                        </td>
                        <td className={styles.tableCell}>
                          <span className={styles.badgeContainer}>
                            <Badge badge={badge.level} size={50} shadow={false} />
                          </span>
                          <span>{badge.level}</span>
                        </td>
                        <td className={styles.tableCell}>
                          {new Date(
                            badge.achievement_date
                          ).toLocaleDateString()}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <PortalPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              )}
            </div>
          </PortalCard>
        </div>
      )}
    </Suspense>
  );
};

const actions = { portalApiMap };

export default withRouter(connect(null, actions)(PortalBadgeTable));
