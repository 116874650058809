import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';
import styles from './MonthSelector.module.css';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const MonthSelector = ({ currentMonth, onMonthChange }) => {
  // Create a debounced version of the onMonthChange callback (300ms delay)
  const debouncedOnMonthChange = useCallback(
    debounce((newMonth) => {
      onMonthChange(newMonth);
    }, 300),
    [onMonthChange]
  );

  const handlePrev = () => {
    const newMonth = new Date(currentMonth);
    newMonth.setMonth(newMonth.getMonth() - 1);
    newMonth.setDate(1);
    debouncedOnMonthChange(newMonth);
  };

  const handleNext = () => {
    const newMonth = new Date(currentMonth);
    newMonth.setMonth(newMonth.getMonth() + 1);
    newMonth.setDate(1);
    debouncedOnMonthChange(newMonth);
  };

  return (
    <div className={styles.container}>
      <button className={styles.arrowButton} onClick={handlePrev}>
        <MdKeyboardArrowLeft className={styles.icon} />
      </button>
      <div className={styles.monthDisplay}>
        {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
      </div>
      <button className={styles.arrowButton} onClick={handleNext}>
        <MdKeyboardArrowRight className={styles.icon} />
      </button>
    </div>
  );
};

export default MonthSelector;
