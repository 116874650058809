import React, { useState, useEffect, useRef } from 'react';
import { Progress, Spin } from 'antd';
import styles from '../UserProgress.module.css';

// Daily Score Tile Component for Progress Page
export const DailyScore = ({
  averageDailyScore,
  dailyGoal
}) => {
  // Add state for animated progress value
  const [animatedScore, setAnimatedScore] = useState(0);
  const [animatedPercent, setAnimatedPercent] = useState(0);
  const lastScoreRef = useRef(null);
  
  // Calculate the correct percentage based on averageDailyScore and dailyGoal
  // Use a reasonable default if dailyGoal is not provided
  const effectiveDailyGoal = dailyGoal || 100;
  
  // Calculate the percentage of the goal achieved
  const targetPercent = averageDailyScore !== null && averageDailyScore !== undefined
    ? Math.min((averageDailyScore / effectiveDailyGoal) * 100, 100)
    : 0;
  
  // Animate the score when averageDailyScore changes
  useEffect(() => {
    if (averageDailyScore === null || averageDailyScore === undefined) {
      return;
    }
    
    // Check if the score has changed or if it's the first time
    const scoreChanged = lastScoreRef.current !== averageDailyScore;
    lastScoreRef.current = averageDailyScore;
    
    if (scoreChanged) {
      // Reset animation values
      setAnimatedScore(0);
      setAnimatedPercent(0);
      
      // Animation duration
      const animationDuration = 1000; // 1 second
      
      // Start the animation
      let startTime;
      
      const animate = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const progress = timestamp - startTime;
        const percentage = Math.min(progress / animationDuration, 1);
        
        // Use easeOutQuad for smoother animation
        const eased = 1 - (1 - percentage) * (1 - percentage);
        
        // Update animated values
        const newScore = Math.min(averageDailyScore * eased, averageDailyScore);
        const newPercent = Math.min(targetPercent * eased, targetPercent);
        
        setAnimatedScore(newScore);
        setAnimatedPercent(newPercent);
        
        if (percentage < 1) {
          requestAnimationFrame(animate);
        }
      };
      
      requestAnimationFrame(animate);
    }
    
  }, [averageDailyScore, effectiveDailyGoal, targetPercent]);

  return (
    <div className={styles.dailyScoreTile}>
      <div className={styles.dailyScoreContent}>
        <div className={`${styles.dailyScoreProgressGroup} ${styles.progressCircle}`}>
          {averageDailyScore === null || averageDailyScore === undefined ? (
            <Spin size="large" />
          ) : (
            <Progress
              type="circle"
              percent={animatedPercent}
              width={80}
              strokeWidth={12}
              strokeColor="#6BA543"
              trailColor="#eaecf1"
              format={() => ''}
            />
          )}
        </div>
        <div className={styles.dailyScoreTextContainer}>
          <span className={styles.dailyScoreTitle}>Average Daily</span>
          <span className={styles.dailyScoreSubtitle}>Wellness Score</span>
          <span className={styles.dailyScoreNumber}>{Number(animatedScore).toFixed(1)}</span>
        </div>
      </div>
    </div>
  );
};
