import React from 'react';
import RankMedal from '../../../components/rankMedal/rankMedal';
import Badge from '../../../components/badge/badge';
import { LifetimeScoreIcon } from '../../../serverVariables/progressIcons';
import { getHealthGoalIcon } from '../ProgressUtils';
import styles from '../UserProgress.module.css';

// Current Standings Tile Component for Progress Page
export const CurrentStandings = ({
  lifetimeScore,
  healthGoal,
  user,
  currentBadge
}) => {
  return (
    <>
      <div className={styles.statsContainer}>
        <div className={styles.statsRow}>
          <div className={styles.statItem}>
            <div className={styles.statIconContainer}>
              <div className={styles.lifetimeScoreContainer}>
                <img src={LifetimeScoreIcon} alt="Lifetime Score" width={62} height={62} />
              </div>
            </div>
            <span className={styles.statLabel}>Lifetime Score</span>
            <span className={styles.statValue}>{lifetimeScore}</span>
          </div>
          
          <div className={styles.statItem}>
            <div className={styles.statIconContainer}>
              {healthGoal && (
                <div className={styles.lifetimeScoreContainer}>
                  <img src={getHealthGoalIcon(healthGoal)} alt="Health Goal" width={75} height={75} />
                </div>
              )}
            </div>
            <span className={styles.statLabel}>Health Goal</span>
            <span className={styles.statValue}>{healthGoal}</span>
          </div>
          
          <div className={styles.statItem}>
            <div className={styles.statIconContainer}>
              <RankMedal 
                rank={user.game.rank_id}
                width={74}
                height={74}
              />
            </div>
            <span className={styles.statLabel}>Rank</span>
            <span className={styles.statValue}>{user.game.rank_name}</span>
          </div>
          
          <div className={styles.statItem}>
            <div className={styles.statIconContainer}>
              <Badge 
                name={currentBadge}
                size={200}
                shadow={true}
              />
            </div>
            <span className={styles.statLabel}>Current Badge</span>
            <span className={styles.statValue}>{currentBadge}</span>
          </div>
        </div>
      </div>
    </>
  );
};
