import React, { Component } from 'react';
//import AmCharts from "@amcharts/amcharts3-react";

// list values should have the format
// [ { date: x, value: y }, ...]

class Graph extends Component{
    render(){
        let chart_config= {
            "type": "serial",
            "theme": "light",
            "marginRight": 40,
            "marginTop": 50,
            "marginLeft":40,
            "autoMarginOffset": 20,
            "mouseWheelZoomEnabled":false,
            "dataDateFormat": "YYYY-MM-DD",
            "valueAxes": [{
                "axisAlpha": 0,
                "gridThickness": 2,
                "gridColor": "black",
                "position": "left",
                "dashLength":1,
                "ignoreAxisWidth":false,
                "color":"black",
            }],
            "balloon": {
                "borderThickness": 1,
                "shadowAlpha": 0
            },

            "graphs": [{
                "id": "g1",
                "balloon":{
                    "drop":true
                },
                "bullet": "round",
                "bulletBorderAlpha": 1,
                "bulletColor": "#00b200",
                "bulletSize": 7,
                "connect": false,
                "hideBulletsCount": 365,
                "lineThickness": 3,
                "lineColor":"#00b200",
                "title": "Taken",
                "type": "smoothedLine",
                "useLineColorForBulletBorder": true,
                "valueField": "value",
                "balloonText": "<span style='font-size:16px;'>[[value]]</span>"
            },{
                "id": "g2",
                "balloon":{
                    "drop":true
                },
                "bullet": "round",
                "bulletBorderAlpha": 1,
                "bulletColor": "#FB7B3A",
                "bulletSize": 7,
                "connect": false,
                "hideBulletsCount": 365,
                "lineThickness": 2,
                "lineColor":"#FB7B3A",
                "title": "Total",
                "type": "smoothedLine",
                "useLineColorForBulletBorder": true,
                "valueField": "value2",
                "balloonText": "<span style='font-size:16px;'>[[value]]</span>"
            },{
                "id": "g3",
                "balloon":{
                    "drop":true
                },
                "bullet": "round",
                "bulletBorderAlpha": 1,
                "bulletColor": "#F9E250",
                "bulletSize": 7,
                "connect": false,
                "hideBulletsCount": 365,
                "lineThickness": 2,
                "lineColor":"#F9E250",
                "title": "Available",
                "type": "smoothedLine",
                "useLineColorForBulletBorder": true,
                "valueField": "value3",
                "balloonText": "<span style='font-size:16px;'>[[value]]</span>"
            }],
            "chartCursor": {
                "pan": true,
                "valueLineEnabled": true,
                "valueLineBalloonEnabled": true,
                "cursorAlpha":1,
                "cursorColor":"#354052",
                "limitToGraph":"g1",
                "valueLineAlpha":0.5,
                "valueZoomable":true
            },
            "categoryField": "date",
            "categoryAxis": {
                "axisColor": "black",
                "color": "black",
                "gridColor": "black",
                "parseDates": true,
                "dashLength": 1,
                "minPeriod": "DD",
                "minorGridEnabled": true,
            },
            "responsive": {
                "enabled": true
            },
            "dataProvider": this.props.data.length ? this.props.data : [],
        }
        if (this.props.legend) {
            chart_config.legend = {
                "useGraphSettings": this.props.legend
            };
        }

        return(
            <div className="tile--graph-container" style={{height: this.props.height || 250}}>
                <div>AmCharts Offline</div>
                {/*<AmCharts.React*/}
                {/*    options={chart_config}*/}
                {/*    style={{ width: "100%", height: this.props.height || "250px" }}*/}
                {/*/>*/}
            </div>
        )
    }
}

export default Graph;