import React, {useEffect, useState, useRef} from 'react';
import {Link, withRouter, Redirect} from 'react-router-dom';

import _ from 'lodash';
import Select from "react-select";
import { parse as parseQuery } from 'query-string';

// styles
import styles from './contentSearch.module.css';
import styles2 from './contentRow/contentRow.module.css';

// actions
import {
    fetchContentTypes,
    fetchContentStatuses,
    fetchHierarchicalCategories,
    searchAllContent,
    fetchTags, fetchInstructors, fetchClassTypes,
} from "../../store/actions/contentActions";

// components
import {BoxContainer} from "../containers/boxContainer/boxContainer";
import SearchableDropdown from "../dropdowns/searchableDropdown/searchableDropdown";
import {PrimaryButton} from "../buttons/buttons";
import {ReactComponent as PlusIcon} from '../../images/plus-solid-circle.svg';
import {CONTENT_FORMATS} from "../../serverVariables/contentFormats";
import Filter from './filter/filter';
import {Modal} from "react-bootstrap";
import {ReactComponent as TimesIcon} from "../../images/times-solid.svg";
import Loading from "../Loading/Loading";
import ContentRow from './contentRow/contentRow';
import {ReactComponent as DownArrow} from "../../images/caret-down-solid.svg";
import {GeneralPagination} from "../pagination/generalPagination/generalPagination";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import ButtonRouteDropDown from '../dropdowns/buttonRouteDropDown/ButtonRouteDropDown';


const ContentSearch = props => {
    // props
    const {match, history, location} = props;
    // local
    const [loading, setLoading] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [contentType, setContentType] = useState(null);
    const [advancedOpen, setAdvancedOpen] = useState(false); // determines if the advanced filter options show
    const advancedRef = useRef(); // the container of the format and type filters
    const tagsRef = useRef(); // the container of the tags
    const [statuses, setStatuses] = useState([]);
    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagModal, setTagModal] = useState(false);
    const [instructorsList, setInstructorsList] = useState([]);
    const [classTypesList, setClassTypesList] = useState([]);
    const [content, setContent] = useState({content: [], pages: 0, count: 0});
    // the filter state
    const [form, setForm] = useState({
        title: '',
        status: null,
        category: null,
        format: null,
        type: null,
        tags: [],
        sort: 'updated-desc',
        page: 1,
    })


    // grab content statuses on 1st render
    useEffect(() => {
        fetchContentStatuses()
            .then(statuses => {
                const reshaped = statuses.map(status => ({
                    name: status.name,
                    value: status.name,
                }));
                setStatuses([{name: 'All Statuses', value: ''}, ...reshaped]);
            })
            .catch(e => console.log(e));
    }, []);

    // change the query string and search whenever the form is altered
    useDidMountEffect(() => {
        let {url} = match;
        url += '?';
        url += `page=${form.page || 1}&`;
        url += `sort=${form.sort}&`;
        if (form.title) url += `title=${form.title}&`;
        if (form.format) url += `format=${form.format}&`;
        if (form.type) url += `type=${form.type}&`;
        if (form.category) url += `category=${form.category.value}&`;
        if (form.status) url += `status=${form.status}&`;
        if (form.classType) url += `classType=${form.classType}&`;
        if (form.instructor) url += `instructor=${form.instructor}&`;
        history.push(url);
    }, [form]);

    // grab the query string on 1st render and search
    useEffect(() => {
        firstRender();
    }, []);

    function calcHeight() {
        if (advancedRef.current && tagsRef.current) return advancedRef.current.clientHeight + tagsRef.current.clientHeight;
        return 0;
    }

    async function submit(page, search) {
        if (loading) return;
        setLoading(true);
        try {
            const content = await searchAllContent(page, search);
            setContent(content);
            setLoading(false);
            window.scrollTo(0, 0);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    }

    async function updateCategoryList(val) {
        let tempCategories;
        try{
            if (val) {
                const found = types.find(type => type.value === val);
                tempCategories = await fetchHierarchicalCategories(found.value);
            } else {
                tempCategories = (await fetchHierarchicalCategories()).filter(item => item);
            }
            setCategories([{id: '', name: 'All Categories', subcategories: []}, ...tempCategories]);
            setForm({...form, type: val,  category: ''});
        } catch(e) {
            console.log(e);
        }
    }

    async function firstRender() {
        try {
            // get content types, instructors, and class types
            const [tempTypes, instructors, classTypes] = await Promise.all([fetchContentTypes(), fetchInstructors('', ''), fetchClassTypes('', '')]);
            setContentType(tempTypes.shift().id);
            const reshapedContentTypes = tempTypes.map(type => ({
                name: type.type,
                value: type.id,
            }));
            setTypes([{name: 'All Content Types', value: ''}, ...reshapedContentTypes]);

            const reshapedClassTypes = classTypes.map(type => ({value: type.content_meta_option_id, name: type.label}));
            const reshapedInstructors = instructors.map(trainer => ({value: trainer.content_meta_option_id, name: `${trainer.label} ${trainer.text ? `(${trainer.text})` : ''}`}));
            setClassTypesList(reshapedClassTypes);
            setInstructorsList(reshapedInstructors);

            // grab tags
            let tempTags = await Promise.all(tempTypes.map(type => fetchTags(type.id)));
            tempTags = _.flatten(tempTags).sort((a,b) => a.name < b.name ? -1 : 1);
            setTags(tempTags);

            // parse query string of url
            const query = parseQuery(location.search);

            // parse any query tags
            let queryTags = [];
            if (query.tag) {
                // find the tags in the list of all tags
                queryTags = query.tag.split(',').map(tag => {
                    return tempTags.find(item => item.id === tag);
                }).filter(item => item);
            }

            // get categories based on query string
            let tempCategories;
            if (query && query.category) {
                if (query.type) {
                    const found = tempTypes.find(type => type.id === query.type);
                    tempCategories = await fetchHierarchicalCategories(found.id);
                } else {
                    tempCategories = await fetchHierarchicalCategories();
                }
            } else if (query && query.type) {
                const found = tempTypes.find(type => type.id === query.type);
                tempCategories = await fetchHierarchicalCategories(found.id);
            } else {
                tempCategories = await fetchHierarchicalCategories();
            }
            setCategories([{id: '', name: 'All Categories', subcategories: []},...tempCategories]);

            // update the form if a query string was set
            if (query && !_.isEmpty(query)) {
                setForm({
                    ...form,
                    title: query.title,
                    format: query.format,
                    type: query.type,
                    status: query.status,
                    category: query.category ? {value: parseInt(query.category), label: _.flatten(_.flatten(tempCategories).map(cat => [cat, ...cat.subcategories])).find(cat => cat.id === query.category).name} : '',
                    tags: queryTags,
                    classType: query.classType,
                    instructor: query.instructor,
                    page: query.page || 1,
                    sort: query.sort || form.sort,
                })
            }
            setMounted(true);
            await submit(query.page || 1, gatherData({
                title: query.title,
                type: query.type ? tempTypes.find(type => type.id === query.type).name : null,
                format: query.format,
                status: query.status,
                category: query.category,
                classType: query.classType,
                instructor: query.instructor,
                tags: query.tag,
                sort: query.sort
            }));
        } catch (e) {
            console.log(e);
        }
    }

    function gatherData(overrides={}) {
        return {
            title: overrides.title || form.title,
            type: overrides.type || form.type ? types.find(type => type.value === form.type).name : null,
            format: overrides.format || form.format,
            status: overrides.status || form.status,
            category: overrides.category || (form.category ? form.category.value : null),
            tags: overrides.tags || form.tags.length ? form.tags.map(item => item.id).join(',') : null,
            classType: overrides.classType || form.classType,
            instructor: overrides.instructor || form.instructor,
            sort: overrides.sort || form.sort,
        }
    }

    function removeTag(val) {
        const updated = form.tags.filter(tag => tag.id !== val.id);
        setForm({...form, tags: updated});
    }

    function toggleTitleOrder(){
        if (loading) return;
        const copy = {...form, page: 1};
        if (form.sort === 'title-desc') {
            copy.sort = 'title-asc';
        } else {
            copy.sort = 'title-desc';
        }
        setForm(copy);
        submit(1, gatherData({sort: copy.sort}));
    }

    function toggleDateOrder() {
        if (loading) return;
        const copy = {...form, page: 1};
        if (form.sort === 'updated-desc') {
            copy.sort = 'updated-asc';
        } else {
            copy.sort = 'updated-desc';
        }
        setForm(copy);
        submit(1, gatherData({sort: copy.sort}));
    }

    function changePage({selected}) {
        const page = selected+1;
        setForm({...form, page});
        submit(page, gatherData());
    }

    // routes fro schedule calander dropdown button
    const scheduleBtnRouteOptions = [
        { value: '/schedule', label: 'Class of the Week', },
        { value: '/scheduleClassofDay', label: 'Class of the Day',  },
      ];

  
    return (
        <div>
            {/*Header*/}
            <div className='page-header-container searchForm-container'>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav>
                                <ol className="breadcrumb ">
                                    <li className="breadcrumb-item underline ">
                                        <Link to="/dashboard" className="text-light">Dashboard</Link>
                                    </li>
                                    <span className="arrow"/>
                                    <li className="breadcrumb-item text-light active">Content</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <h1>Content</h1>
                        </div>
                        <div className={`col-sm-12 col-md-6 text-right ${styles.primariesContainer}`}>
                            <div  className={styles.btnDropdownScheduleContainer}>
                            <PrimaryButton
                                onClick={() => {
                                    props.history.push(props.match.url + '/new')
                                }}
                                className={styles.primaries}
                                data-testID="newContentBtn"
                            >+ New Content</PrimaryButton>
                        <ButtonRouteDropDown options={scheduleBtnRouteOptions} match={match} history={history}/>
                         </div>
                        </div>
                        <div className="col-sm-12 tag_links" data-testID="contentType" >
                            {contentType ? <Link to={`/dashboard/Content/class-types/`}>Class Types</Link> : null}
                            {contentType ? <Link to={`/dashboard/Content/equipment/`}>Equipment</Link> : null}
                            {contentType ? <Link to={`/dashboard/Content/instructors`}>Instructors</Link> : null}
                            {contentType ? <Link to={`/dashboard/Content/tags/${contentType}`}>Tags</Link> : null}
                            {contentType ? <Link to={`/dashboard/Content/type/${contentType}`}>Categories</Link> : null}
                        </div>
                    </div>
                </div>
            </div>

            {/*Filter*/}
            <div className="container" data-testID="contentSearch">
                <BoxContainer className={styles.filter}>
                    <form onSubmit={e => {
                        e.preventDefault();
                        setForm({...form, page: 1});
                        submit(1, gatherData());
                    }}>
                        <div className={styles.filtersMain}>
                            <div className={`form-group ${styles.filtersTitle}`}>
                                <label htmlFor="search">What are you looking for?</label>
                                <input
                                    value={form.title}
                                    onChange={e => setForm({...form, title: e.target.value})}
                                    className="form-control"
                                    type="text"
                                    id="search"
                                    placeholder='Search by title'/>
                            </div>
                            <div className={`form-group ${styles.filtersCategory}`}>
                                <ReactSelectCategories
                                    placeholder='All Categories'
                                    className='capitalize'
                                    name='categories'
                                    label='Category'
                                    list={categories}
                                    value={form.category}
                                    onChange={val => {
                                        if(!val.value){
                                            setForm({...form, category: null});
                                        } else {
                                            setForm({...form, category: val})
                                        }
                                    }}
                                />
                            </div>
                            <div className={`form-group ${styles.filtersStatus}`}>
                                <label>Status</label>
                                <SearchableDropdown
                                    options={statuses}
                                    onChange={val => setForm({...form, status: val})}
                                    value={form.status}
                                    placeholder='All Statuses'
                                />
                            </div>
                            <div className={styles.submit}>
                                <PrimaryButton onClick={(e) => {
                                    e.preventDefault();
                                    setForm({...form, page: 1});
                                    submit(1, gatherData());
                                }}>Search</PrimaryButton>
                            </div>
                        </div>
                        <div>
                            <p className={`${styles.advancedHeading} ${advancedOpen ? styles.open : ''}`} onClick={() => setAdvancedOpen(!advancedOpen)}>Advanced Filters</p>
                            <div className={styles.panel} style={advancedOpen ? {maxHeight: `${calcHeight()}px`, overflow: 'visible'} : {}}>
                                <div ref={advancedRef} className={styles.filtersAdvanced}>
                                    <div className={`form-group`}>
                                        <label>Content Type</label>
                                        <SearchableDropdown
                                            options={types}
                                            onChange={val => updateCategoryList(val)}
                                            value={form.type}
                                            placeholder='All Content Types'
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label>Format</label>
                                        <SearchableDropdown
                                            options={[
                                                {name: 'All Formats', value: ''},
                                                ...Object.keys(CONTENT_FORMATS).map(key => ({name: key, value: key.toLocaleLowerCase()}))
                                            ]}
                                            onChange={val => setForm({...form, format: val})}
                                            value={form.format}
                                            placeholder='Select a format'
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label>Class Type</label>
                                        <SearchableDropdown
                                            options={[
                                                {name: 'All Class Types', value: ''},
                                                ...classTypesList,
                                            ]}
                                            onChange={val => setForm({...form, classType: val})}
                                            value={form.classType}
                                            placeholder='Select a Class Type'
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label>Instructor</label>
                                        <SearchableDropdown
                                            options={[
                                                {name: 'All Instructors', value: ''},
                                                ...instructorsList,
                                            ]}
                                            onChange={val => setForm({...form, instructor: val})}
                                            value={form.instructor}
                                            placeholder='Select an Instructor'
                                        />
                                    </div>
                                </div>
                                <div ref={tagsRef}>
                                    <div className={styles.tagsHeading}>
                                        <label>Tags</label>
                                        <PlusIcon className={styles.plus} onClick={() => setTagModal(true)}/>
                                    </div>
                                    <div className={styles.tagContainer}  data-testID="contentList">
                                        {form.tags.map(tag => (
                                            <button key={tag.id} className={styles.tag} onClick={() => removeTag(tag)}>
                                                {tag.name}
                                                <TimesIcon className={styles.times}/>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </BoxContainer>
            </div>

            {/* Content List */}
            <div className="container margin-top-lrg">
                { loading || !mounted ?
                    <div><Loading/></div>
                    :
                    <div>
                        <h4>{content.count} items found</h4>
                        <div>
                            <div className={`${styles2.row} ${styles2.header}`}>
                                <div className={`${styles2.title} cur:p`} onClick={toggleTitleOrder}>
                                    Title
                                    {
                                        form.sort === 'title-asc' || form.sort === 'title-desc' ?
                                        <DownArrow className={`${styles2.arrow} ${form.sort === 'title-asc' ? styles2.up : ''}`}/>
                                        : null
                                    }
                                </div>
                                <div className={styles2.status}>Status</div>
                                <div className={styles2.type}>Type</div>
                                <div className={styles2.format}>Format</div>
                                <div className={styles2.category}>Category</div>
                                <div className={`${styles2.date} cur:p`} onClick={toggleDateOrder}>
                                    Last Updated
                                    {
                                        form.sort === 'updated-asc' || form.sort === 'updated-desc' ?
                                            <DownArrow className={`${styles2.arrow} ${form.sort === 'updated-asc' ? styles2.up : ''}`}/>
                                            : null
                                    }
                                </div>
                                <div className={styles2.view}/>
                            </div>
                            {content.content.map(item => <ContentRow key={item.id} content={item}/>)}
                        </div>
                    </div>
                }
            </div>

            {/* Pagination */}
            <div className="container">
                <div className="pagination-container">
                    <GeneralPagination forcePage={form.page-1} pages={content.pages} onPageChange={changePage}/>
                </div>
            </div>
            <Modal show={tagModal} onHide={() => setTagModal(false)}>
                <Filter
                    allTags={tags}
                    setTags={val => setForm({...form, tags: val})}
                    tags={form.tags}
                    close={() => setTagModal(false)}
                    />
            </Modal>
        </div>
    )
}
export default withRouter(ContentSearch);

function ReactSelectCategories({ list, label, value, name, onChange, ...props}) {
    const groups = list.map(category => {
        if (!category.subcategories || category.subcategories.length === 0) return { value: category.id, label: category.name };

        const options = [];
        options.push({ value: category.id, label: category.name });
        category.subcategories.forEach(sub => {
            options.push({value: sub.id, label: sub.name});
        });
        return { label: category.name, options };
    });
    return(
        <div className={props.className}>
            <label>{label}</label>
            <Select
                placeholder={props.placeholder}
                className='select-form-control'
                onBlur={props.onBlur}
                value={value}
                name={name}
                options={groups}
                onChange = {e => {
                    onChange(e)
                }}
                formatGroupLabel={formatGroupLabel}
                styles={categoryStyles}
            />
        </div>
    )
}

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const categoryStyles = {
    control: styles => ({ ...styles, backgroundColor: '#fff' }),
    option: (styles, data) => {
        return {
            ...styles,
            marginLeft: data.options.filter(item => item.label === data.label).length ? 0 : 20,
        };
    },
    input: styles => ({ ...styles }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
};