import React, {useState, useCallback, useEffect} from "react";
import {withRouter} from "react-router-dom";
import validate from "validate.js";
import {isEmpty} from "lodash";
import axios from "axios";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";

// styles
import styles from "./signUp.module.css";

// actions
import {acceptPolicyVersion} from "../../../store/actions/policyActions";

// components
import SignUpLoading from "./signupLoading";
import {PrimaryButton} from "../../buttons/buttons";
import {ErrorAlert} from "../../alerts/alerts";
import TreoLogo from "../../../images/logo-white.png";
import MatrixLogo from "../../../images/matrix-logo.png";
import JFWLogo from "../../../images/jfw-logo.png";

import NumberFormat from "react-number-format";
import TermsOfService from "../termsOfService/termsOfService";
import PuzzleChallenge from "../../puzzleChallenge/puzzleChallenge";

const constraints = {
    firstName: {
        type: "string",
        presence: "^First name is required",
        length: {
            minimum: 1,
            maximum: 255,
            tooShort: "^First name is required",
            tooLong: "^First name must be less than 255 character",
        },
        format: {
            pattern: /^[A-Za-z ]+$/, // This pattern allows only alphabetic characters
            message: '^First name must contain only alphabetic characters',
        },
    },
    lastName: {
        type: "string",
        presence: "^Last name is required",
        length: {
            minimum: 1,
            maximum: 255,
            tooShort: "^Last name is required",
            tooLong: "^Last name must be less than 255 character",
        },
        format: {
            pattern: /^[A-Za-z ]+$/, // This pattern allows only alphabetic characters
            message: '^Last name must contain only alphabetic characters',
        },
    },
    phone: {
        numericality: true,
        length: {is: 10, message: "Phone number is invalid"},
    },
    email: {
        type: "string",
        presence: "^Email is required",
        email: "^Enter a valid email",
    },
    password: {
        type: "string",
        presence: "^Password is required",
        length: {
            minimum: 8,
            maximum: 15,
            tooShort: "^Password must be at least 8 characters long",
            tooLong: "^Password must be less than 15 characters",
        },
    },
    confirm: {
        type: "string",
        presence: "^Password is required",
        length: {
            minimum: 8,
            maximum: 15,
            tooShort: "^Password must be at least 8 characters long",
            tooLong: "^Password must be less than 15 characters",
        },
    },
};

const SignUp = (props) => {
    // props
    const {orgId, code, setPage, logo, daxkoFields, history} = props;

    // state
    const [validation, setValidation] = useState({});
    const [formState, setForm] = useState({
        firstName: daxkoFields ? daxkoFields.firstName : "",
        lastName: daxkoFields ? daxkoFields.lastName : "",
        email: "",
        password: "",
        confirm: "",
        phone: "",
        emailConfirmation: "",
    });
    const [creating, setCreating] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState("");
    const [policies, setPolicies] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [puzzle, setPuzzle] = useState(false);
    const [arrow, setArrow] = useState(1);
    const [currentBackground, setCurrentBackground] = useState("");
    
    // Handle responsive background
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 575) {
                // Mobile background
                if (parseInt(orgId) === 1074) {
                    setCurrentBackground("https://treo.s3.us-east-2.amazonaws.com/assets/bg/signup-background-green-mobile.png");
                } else {
                    setCurrentBackground("https://treo.s3.us-east-2.amazonaws.com/assets/bg/signup-background-jht-mobile.png");
                }
            } else {
                // Desktop background
                if (parseInt(orgId) === 1074) {
                    setCurrentBackground("https://treo.s3.us-east-2.amazonaws.com/assets/bg/signup-background-green.png");
                } else {
                    setCurrentBackground("https://treo.s3.us-east-2.amazonaws.com/assets/bg/signup-background-jht.png");
                }
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [orgId]);

    function verify() {
        if (parseInt(orgId) !== 15 && arrow !== 0){
            return setPuzzle(true);
        }
        try {
            submit();
        } catch (error) { console.error(error)}
    }

    async function submit(e) {
        if (e && e.preventDefault) e.preventDefault();
        if (parseInt(orgId) !== 15 && arrow !== 0) return
        if(disableSubmit) return;
        setDisableSubmit(true);
        const errors = {};
        const {firstName, lastName, phone, email, password, confirm, emailConfirmation} = formState;
        const validation = validate(
            {
                firstName,
                lastName,
                phone,
                email,
                password,
                confirm,
            },
            constraints
        );
        if (validation) {
            errors.firstName = validation.firstName;
            errors.lastName = validation.lastName;
            errors.phone = validation.phone;
            errors.email = validation.email;
            errors.password = validation.password;
            errors.confirm = validation.confirm;
        }
        if (password && confirm && password !== confirm) {
            errors.confirm = ["Passwords do not match"];
        }
        Object.keys(errors).map((key) => {
            if (!errors[key]) delete errors[key];
        });
        setValidation(errors);
        if (!isEmpty(errors)) {
            return setDisableSubmit(false);
        }

        try {
            // b/e validation
            const res = await axios.post(`/organizations/validate-new-client/${orgId}/${code}`, {
                first_name: firstName,
                last_name: lastName,
                phone,
                email,
                password,
            });
            // create user
            const response = await axios.post(`/organizations/new-client/${orgId}/${code}`, {
                first_name: firstName,
                last_name: lastName,
                phone,
                email,
                password,
                daxko_member_id: daxkoFields ? daxkoFields.memberId : null,
                emailConfirmation: emailConfirmation,
            });
            setCreating(true);
            if (response.status === 400) {
                setError("Request has timed out please try again" + response.data.message);
                setDisableSubmit(false);
            }

            if (response.status != 200) {
                setError(response.data.message);
                setDisableSubmit(false);
            }
            // accept policies
            await Promise.all(
                policies.map((policy) =>
                    acceptPolicyVersion(policy.id, policy.active_version_id, email)
                )
            );
            setFinished(true);
        } catch (error) {
            setDisableSubmit(false);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setError(`Error: ${error.response.data.error}`);
            } else if (error.request) {
                // The request was made but no response was received
                setError("No response received from the server.");
            } else {
                // Something happened in setting up the request that triggered an Error
                setError("Error setting up the request.");
            }
        }
    }

    if (creating) {
        return (
            <SignUpLoading finished={finished} url={`/member/onboard-confirm`} error={error} setError={setError}/>
        );

    }
    // new rendering style for orgId 1034, 1038, and 1074
    if (orgId == 1034 || orgId == 1038 || orgId == 1074) {
        return (
            <div className={styles.signupPage} style={{ backgroundImage: `url(${currentBackground})` }}>
                <div className={styles.signupLayout}>
                    <div className={styles.logoSection}>
                        <div className={styles.logoContainer}>
                            {orgId == 1074 ? (
                                // Only show Treo logo for Org 1074
                                <img src={TreoLogo} alt="Treo Wellness" className={styles.treoLogo} />
                            ) : (
                                // Show brand logo + PRESENTS + Treo logo for 1034 and 1038
                                <>
                                    {orgId == 1034 ? (
                                        <img 
                                            src={JFWLogo} 
                                            alt="Johnson Fitness & Wellness" 
                                            className={styles.johnsonLogo}
                                        />
                                    ) : (
                                        <img 
                                            src={MatrixLogo} 
                                            alt="Matrix Fitness" 
                                            className={styles.johnsonLogo}
                                        />
                                    )}
                                    <div className={styles.presents}>PRESENTS</div>
                                    <img src={TreoLogo} alt="Treo Wellness" className={styles.treoLogo} />
                                </>
                            )}
                        </div>
                    </div>
                    <div className={styles.formSection}>
                        <div className={styles.card}>
                            <div>{error ? <ErrorAlert>{error}</ErrorAlert> : null}</div>
                            <form onSubmit={submit}>
                                <h2 className={styles.title}>
                                    {orgId == 1074 
                                        ? "Welcome! Let's start your 30 day free trial by setting up your account."
                                        : "Welcome! Let's get started by creating your account."}
                                </h2>
                                <div className={styles.formGrid}>
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        value={formState.firstName}
                                        onChange={(e) =>
                                            setForm({...formState, firstName: e.target.value})
                                        }
                                        onBlur={(e) => {
                                            const problems = validate(
                                                {firstName: e.target.value},
                                                {firstName: constraints.firstName}
                                            );
                                            if (problems) {
                                                setValidation({...validation, firstName: problems.firstName});
                                            } else {
                                                const copy = Object.assign({}, validation);
                                                delete copy.firstName;
                                                setValidation(copy);
                                            }
                                        }}
                                        type="text"
                                        className="form-control"
                                        id="firstName"
                                    />
                                    {validation.firstName ? (
                                        <div>
                                            {validation.firstName.map((err) => (
                                                <span key={err} className="d:b text-danger">
                                                    {err}
                                                </span>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                        value={formState.lastName}
                                        onChange={(e) =>
                                            setForm({...formState, lastName: e.target.value})
                                        }
                                        onBlur={(e) => {
                                            const problems = validate(
                                                {lastName: e.target.value},
                                                {lastName: constraints.lastName}
                                            );
                                            if (problems) {
                                                setValidation({...validation, ...problems});
                                            } else {
                                                const copy = Object.assign({}, validation);
                                                delete copy.lastName;
                                                setValidation(copy);
                                            }
                                        }}
                                        type="text"
                                        className="form-control"
                                        id="lastName"
                                    />
                                    {validation.lastName ? (
                                        <div>
                                            {validation.lastName.map((err) => (
                                                <span key={err} className="d:b text-danger">
                                                    {err}
                                                </span>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Mobile Phone</label>
                                    <NumberFormat
                                        format="(###) ###-####"
                                        mask=""
                                        type="tel"
                                        value={formState.phone}
                                        onChange={(e) =>
                                            setForm({...formState, phone: e.target.value.replace(/\D/g, "")})
                                        }
                                        onBlur={(e) => {
                                            const problems = validate(
                                                {phone: e.target.value.replace(/\D/g, "")},
                                                {phone: constraints.phone}
                                            );
                                            if (problems) {
                                                setValidation({...validation, ...problems});
                                            } else {
                                                const copy = Object.assign({}, validation);
                                                delete copy.phone;
                                                setValidation(copy);
                                            }
                                        }}
                                        className="form-control"
                                        id="phone"
                                    />
                                    {validation.phone ? (
                                        <div>
                                            {validation.phone.map((err) => (
                                                <span key={err} className="d:b text-danger">
                                                    {err}
                                                </span>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        value={formState.email}
                                        onChange={(e) =>
                                            setForm({...formState, email: e.target.value})
                                        }
                                        onBlur={(e) => {
                                            const problems = validate(
                                                {email: e.target.value},
                                                {email: constraints.email}
                                            );
                                            if (problems) {
                                                setValidation({...validation, ...problems});
                                            } else {
                                                const copy = Object.assign({}, validation);
                                                delete copy.email;
                                                setValidation(copy);
                                            }
                                        }}
                                        type="email"
                                        className="form-control"
                                        id="email"
                                    />
                                    {validation.email ? (
                                        <div>
                                            {validation.email.map((err) => (
                                                <span key={err} className="d:b text-danger">
                                                    {err}
                                                </span>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        value={formState.password}
                                        onChange={(e) =>
                                            setForm({...formState, password: e.target.value})
                                        }
                                        onBlur={(e) => {
                                            const problems = validate(
                                                {password: e.target.value},
                                                {password: constraints.password}
                                            );
                                            if (problems) {
                                                setValidation({...validation, ...problems});
                                            } else {
                                                const copy = Object.assign({}, validation);
                                                delete copy.password;
                                                setValidation(copy);
                                            }
                                        }}
                                        type="password"
                                        className="form-control"
                                        id="password"
                                    />
                                    {validation.password ? (
                                        <div>
                                            {validation.password.map((err) => (
                                                <span key={err} className="d:b text-danger">
                                                    {err}
                                                </span>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="confirm">Confirm Password</label>
                                    <input
                                        value={formState.confirm}
                                        onChange={(e) =>
                                            setForm({...formState, confirm: e.target.value})
                                        }
                                        onBlur={(e) => {
                                            const problems = validate(
                                                {confirm: e.target.value},
                                                {confirm: constraints.confirm}
                                            );
                                            if (problems) {
                                                setValidation({...validation, ...problems});
                                            } else {
                                                const copy = Object.assign({}, validation);
                                                delete copy.confirm;
                                                setValidation(copy);
                                            }
                                        }}
                                        type="password"
                                        className="form-control"
                                        id="confirm"
                                    />
                                    {validation.confirm ? (
                                        <div>
                                            {validation.confirm.map((err) => (
                                                <span key={err} className="d:b text-danger">
                                                    {err}
                                                </span>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                                </div>
                                <div className={styles.disclaimer}>
                                    <h4>TERMS AND CONDITIONS</h4>
                                    <p>{orgId == 1074 ? 
                                        "By registering, you acknowledge and agree to the following terms and conditions listed below. This registration includes a 30-day free trial. Upon trial completion, continued membership requires a monthly subscription of $7.99." :
                                        "By completing this registration form, you agree to be added to the Johnson Fitness & Wellness and Treo Wellness marketing and promotional email lists, as well as the terms and conditions below. You may receive updates on new products, special offers, and wellness tips. You can unsubscribe at any time using the link provided in our emails."}</p>
                                    <div className={styles.termsLinks}>
                                        <TermsOfService horizontal={true} loadPolicies={(policies) => setPolicies(policies)} darkOverlay={true} />
                                    </div>
                                </div>
                                <div className={styles.submitContainer}>
                                    <button type="button" className={styles.back} onClick={() => history.push('/')}>
                                        Back
                                    </button>
                                    <PrimaryButton 
                                        onClick={verify} 
                                        disabled={disableSubmit}
                                    >
                                        Sign Up
                                    </PrimaryButton>
                                </div>
                                <div className={styles.footer}>
                                <p>Need help? <a href="mailto:support@treowellness.com">support@treowellness.com</a></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal show={puzzle} onHide={() => setPuzzle(false)}>
                    <PuzzleChallenge close={() => setPuzzle(false)} update={setArrow} callback={verify}/>
                </Modal>
            </div>
        );
    }
    
    // default rendering for other orgIds
    return (
        <div>
            {orgId == 1040 ?
                null
                :
                <div
                    className="margin-bottom-2x"
                    style={{maxWidth: logo ? "50px" : "150px"}}
                >
                    <img
                        className={styles.logo}
                        src={logo || TreoLogo}
                        alt="Treo Wellness"
                    />
                </div>
            }
            <div>{error ? <ErrorAlert>{error}</ErrorAlert> : null}</div>
            <form onSubmit={submit}>
                <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                        value={formState.firstName}
                        onChange={(e) =>
                            setForm({...formState, firstName: e.target.value})
                        }
                        onBlur={(e) => {
                            const problems = validate(
                                {firstName: e.target.value},
                                {firstName: constraints.firstName}
                            );
                            if (problems) {
                                setValidation({...validation, firstName: problems.firstName});
                            } else {
                                const copy = Object.assign({}, validation);
                                delete copy.firstName;
                                setValidation(copy);
                            }
                        }}
                        type="text"
                        className="form-control"
                        id="firstName"
                    />
                    {validation.firstName ? (
                        <div>
                            {validation.firstName.map((err) => (
                                <span key={err} className="d:b text-danger">
                  {err}
                </span>
                            ))}
                        </div>
                    ) : null}
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        value={formState.lastName}
                        onChange={(e) =>
                            setForm({...formState, lastName: e.target.value})
                        }
                        onBlur={(e) => {
                            const problems = validate(
                                {lastName: e.target.value},
                                {lastName: constraints.lastName}
                            );
                            if (problems) {
                                setValidation({...validation, ...problems});
                            } else {
                                const copy = Object.assign({}, validation);
                                delete copy.lastName;
                                setValidation(copy);
                            }
                        }}
                        type="text"
                        className="form-control"
                        id="lastName"
                    />
                    {validation.lastName ? (
                        <div>
                            {validation.lastName.map((err) => (
                                <span key={err} className="d:b text-danger">
                  {err}
                </span>
                            ))}
                        </div>
                    ) : null}
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Mobile Phone</label>
                    <NumberFormat
                        id="phone"
                        className="form-control"
                        format="(###) ###-####"
                        mask=""
                        type="tel"
                        onChange={(e) =>
                            setForm({
                                ...formState,
                                phone: e.target.value.replace(/\D/g, ""),
                            })
                        }
                        onBlur={(e) => {
                            const problems = validate(
                                {phone: e.target.value.replace(/\D/g, "")},
                                {phone: constraints.phone}
                            );
                            if (problems) {
                                setValidation({...validation, ...problems});
                            } else {
                                const copy = Object.assign({}, validation);
                                delete copy.phone;
                                setValidation(copy);
                            }
                        }}
                        value={formState.phone}
                        required
                    />
                    {validation.phone ? (
                        <div>
                            {validation.phone.map((err) => (
                                <span key={err} className="d:b text-danger">
                  {err}
                </span>
                            ))}
                        </div>
                    ) : null}
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        value={formState.email}
                        onChange={(e) => setForm({...formState, email: e.target.value})}
                        onBlur={(e) => {
                            const problems = validate(
                                {email: e.target.value},
                                {email: constraints.email}
                            );
                            if (problems) {
                                setValidation({...validation, ...problems});
                            } else {
                                const copy = Object.assign({}, validation);
                                delete copy.email;
                                setValidation(copy);
                            }
                        }}
                        type="email"
                        className="form-control"
                        id="email"
                    />
                    {validation.email ? (
                        <div>
                            {validation.email.map((err) => (
                                <span key={err} className="d:b text-danger">
                  {err}
                </span>
                            ))}
                        </div>
                    ) : null}
                </div>
                <div className={styles.confirmation}>
                    <input
                        value={formState.emailConfirmation}
                        onChange={(e) => setForm({...formState, emailConfirmation: e.target.value})}
                        type="text"
                        hidden
                        autoComplete={"off"}
                        className={`${styles.confirmation}`}
                        id="emailConfirmation"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password (minimum 8 characters)</label>
                    <input
                        value={formState.password}
                        onChange={(e) =>
                            setForm({...formState, password: e.target.value})
                        }
                        onBlur={(e) => {
                            const problems = validate(
                                {password: e.target.value},
                                {password: constraints.password}
                            );
                            if (problems) {
                                setValidation({...validation, ...problems});
                            } else {
                                const copy = Object.assign({}, validation);
                                delete copy.password;
                                setValidation(copy);
                            }
                        }}
                        type="password"
                        className="form-control"
                        id="password"
                    />
                    {validation.password ? (
                        <div>
                            {validation.password.map((err) => (
                                <span key={err} className="d:b text-danger">
                  {err}
                </span>
                            ))}
                        </div>
                    ) : null}
                </div>
                <div className="form-group">
                    <label htmlFor="confirm">Confirm Password</label>
                    <input
                        value={formState.confirm}
                        onChange={(e) => setForm({...formState, confirm: e.target.value})}
                        onBlur={(e) => {
                            const problems = validate(
                                {confirm: e.target.value},
                                {confirm: constraints.confirm}
                            );
                            if (problems) {
                                return setValidation({...validation, ...problems});
                            } else {
                                const copy = Object.assign({}, validation);
                                delete copy.confirm;
                                setValidation(copy);
                            }
                            if (
                                formState.password &&
                                formState.confirm &&
                                formState.password !== formState.confirm
                            ) {
                                setValidation({
                                    ...validation,
                                    confirm: ["Passwords do not match"],
                                });
                            } else if (
                                formState.password &&
                                formState.password === formState.confirm &&
                                validation.confirm
                            ) {
                                const copy = Object.assign({}, validation);
                                delete copy.confirm;
                                setValidation(copy);
                            }
                        }}
                        type="password"
                        className="form-control"
                        id="confirm"
                    />
                    {validation.confirm ? (
                        <div>
                            {validation.confirm.map((err) => (
                                <span key={err} className="d:b text-danger">
                  {err}
                </span>
                            ))}
                        </div>
                    ) : null}
                </div>
                <div className="form-group">
                    <h4 className="t-t:u">Terms and Conditions</h4>
                    <p>
                        By continuing you agree to Treo's terms and conditions as outlined
                        below.
                    </p>
                    <TermsOfService loadPolicies={setPolicies}/>
                </div>
                <div className="t-a:c">
                    <button
                        type="button"
                        tabIndex={0}
                        className={styles.back}
                        onClick={() => history.push('/')}
                    >
                        Back
                    </button>
                    <PrimaryButton disabled={disableSubmit} type="button" onClick={verify}>Sign Up</PrimaryButton>
                </div>
            </form>

            <Modal show={puzzle} onHide={() => setPuzzle(false)}>
                <PuzzleChallenge close={() => setPuzzle(false)} update={setArrow} callback={verify}/>
            </Modal>
            {orgId == 1074 ?
                <div>
                <img height="1" width="1" style={{display: 'none'}} src="https://www.facebook.com/tr?id=1380462626626098&ev=PageView&noscript=1"/>
                </div>
                :
                null
            }
        </div>
    );
};

const actions = {};

export default withRouter(connect(null, actions)(SignUp));
