import { authApi } from "../../api/authApi";
import { userApi } from "../../api/userApi";
import { wellnessScoreApi } from "../../api/wellnessScoreApi";
import {gameApi} from "../../api/gameApi";
import {createCookie, eraseCookie, getCookie} from "../../helpers/cookies";
import {
    USER_AUTH,
    APP_READY,
    DAILY_SCORE,
    SESSION,
    GET_BADGES,
    SET_ALERT_OPTIONS,
    MULTIFACTOR_UPDATE
} from "../storeConstants";
import { FETCH_SELF } from "../../actions/action_user";

export const login = params => {
    const {email, password, code, backup, save} = params;
    return async dispatch => {
        // check if device has a cookie to bypass mfa
        const challengeToken = getCookie(`${email}_challengeToken`);

        const {data} = await authApi().login({email, password, code, backup, save}, challengeToken);

        // return contained a new mfa bypass token to be saved
        if (data.challengeToken) {
            createCookie(`${email}_challengeToken`, data.challengeToken);
        }

        // see if login route is prompting you for a mfa code
        if (data.code) {
            // user is being asked for mfa, delete any previous mfa bypass token
            eraseCookie(`${email}_challengeToken`);
            // save credentials to state and send to appropriate page
            dispatch({type: MULTIFACTOR_UPDATE, payload: {email, password, method: data.method, phone: data.phone || ''}});
            return {codeRequired: true}
        }



        if (data) {
            const userRes = await userApi().getUser();
            if (userRes.data.user.permission_id === 1) {
                const badges = await gameApi().getBadges()
                    .catch(e => {});
                const alertRes = await userApi().getAlertOptions()
                    .catch(e => {});
                const scoreRes = await wellnessScoreApi().getDailyScore();
                dispatch({ type: DAILY_SCORE, payload: scoreRes.data.results.score})
                if (badges) dispatch({type: GET_BADGES, payload: badges.data.result});
                if (alertRes) dispatch({type: SET_ALERT_OPTIONS, payload: alertRes.data});
            }

            dispatch({ type: USER_AUTH, payload: true });
            dispatch({ type: FETCH_SELF, payload: userRes });
            dispatch({ type: APP_READY, payload: true });
        }
    }
}

export const checkLogin = () => {
    return async dispatch => {
        try {
            const authRes = await authApi().checkLogin();

            if (authRes.data) {
                const userRes = await userApi().getUser();
                if (userRes.data.user.permission_id === 1) {
                    const scoreRes = await wellnessScoreApi().getDailyScore();
                    const badges = await gameApi().getBadges()
                        .catch(e => {});
                    const alertRes = await userApi().getAlertOptions()
                        .catch(e => {});
                    dispatch({ type: DAILY_SCORE, payload: scoreRes.data.results.score})
                    if (badges) dispatch({type: GET_BADGES, payload: badges.data.result});
                    if (alertRes) dispatch({type: SET_ALERT_OPTIONS, payload: alertRes.data});
                }

                dispatch({ type: USER_AUTH, payload: true });
                dispatch({ type: FETCH_SELF, payload: userRes });
                dispatch({ type: APP_READY, payload: true });
                return authRes;
            } else {
                dispatch({ type: APP_READY, payload: true });
            }
        } catch (e) {
            dispatch({ type: APP_READY, payload: true });
        }
    }
}

export const logout = () => {
    return async dispatch => {
        const res = await authApi().logout();
        dispatch({ type: USER_AUTH, payload: false });
        dispatch({ type: APP_READY, payload: false });
        dispatch({ type: FETCH_SELF, payload: null });
        dispatch({ type: DAILY_SCORE, payload: 0});
        return res;
    }
}

export const registerSession = (seconds) => {
    return async (dispatch, getState) => {
        const {user, sessionKey} = getState();
        // don't report session time of demo members
        if (user.demo_member) return;
        // create new session key if not present
        if (!sessionKey) {
            const key = `${user.username}-${new Date().getTime()}`;
            dispatch({type: SESSION, payload: key});
            return authApi().recordSession(seconds, key);
        } else if (seconds !== 0) {
            // only log session start once
            return authApi().recordSession(seconds, sessionKey);
        } else {
            return null;
        }
    }
}

//**************** Actions that don't involve the store *****************//

export async function verifyPassword(password) {
    const {data: {result}} = await authApi().verifyPassword(password);
    return result;
}

export async function setupMFA(method, password) {
    const {data: {result}} = await authApi().setupMFA(method, password);
    return result;
}

export async function confirmMFA(code, method) {
    const {data: {result}} = await authApi().confirmMFA(code, method);
    return result;
}

export async function disableMFA(password) {
    const {data: {result}} = await authApi().disableMFA(password);
    return result;
}

export async function fetchConfirmationCode() {
    const {data: {result}} = await authApi().getConfirmationCode()
    return result;
}
