import React from "react";
import styles from "./DocumentModal.module.css";

const DocumentModal = ({
  modalOpen,
  setModalOpen,
  title,
  fields,
  handleSubmit,
  submitLabel,
}) => {
  if (!modalOpen) return null;

  return (
    <div className={styles.modalOverlay} data-testID="modal-habit-tile">
      <div className={styles.modal}>
        <h3>{title}</h3>
        {/* Dynamic Form Fields */}
        {fields.map((field, index) => (
          <div key={index} className={styles.inputGroup}>
            <label>{field.label}</label>
            {field.type === "text" && (
              <input
                type="text"
                placeholder={field.placeholder}
                value={field.value}
                onChange={field.onChange}
              />
            )}
            {field.type === "file" && (
              <input
                type="file"
                accept={field.accept}
                onChange={field.onChange}
              />
            )}
            {field.error && (
              <div className={styles.error}>{field.error}</div>
            )}
          </div>
        ))}

        {/* Modal Actions */}
        <div className={styles.modalActions}>
          <button className={styles.createButton} onClick={handleSubmit}>
            {submitLabel}
          </button>
          <button
            className={styles.closeButton}
            onClick={() => setModalOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentModal;
