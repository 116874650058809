import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {Alert} from "react-bootstrap";
import NumberFormat from "react-number-format";

// styles
import "./Account.css";
import styles from './Account.module.css';

// components
import { ImageUpload } from "./imageUpload/imageUploadContainer";
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {NICKNAME_STATUSES} from "../../../serverVariables/leaderboardNicknameStatuses";
import {PrimaryButton} from "../../buttons/buttons";

let userData;
let platformId;
let commPrefId;
let contactFreq;
let phone;

class Account extends React.Component {
    constructor(){
        super();
        this.state={
          firstName: '',
          lastName: '',
          email: '',
          show: false,
          alertStyle: true,
          alert: '',
          video_call_method:'',
          video_call_methodId:'',
          video_call_address: '',
          contactFreqId: '',
          contactFreqArr: [],
          phone: null,
          alt_phone: null,
          alt_email: '',
          birthday:'',
          profile_image:'',
          platformId:[],
          commPrefArr:[],
          commPref: '',
          commPrefId: '',
          contactFreq: '',
          imgUploaded:true,
          error:'',
          weekly_updates: false,
          carrier:false,
          carrier_error:false,
          notification_setting: false,
          timezone: '',
          // notification settings
          textReminderFrequency: null,
          textReminderTime: null,
          startDate: null,
        };
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.setProfileImage = this.setProfileImage.bind(this);
  }

  getInfo() {
    axios
      .get(`/user/client/single/${this.props.username}`)
      .then(res => {
        userData = res.data.user;
        let alteredTime = null;
        if (userData.text_notification_time) {
            const tnt = moment(userData.text_notification_time).utc();
            let hour = moment(tnt).utc().hour();
            if (hour < 10) hour = `0${hour}`;
            alteredTime = `${hour}:00:00`;
        }
        this.setState({
          firstName: userData.first_name,
          lastName: userData.last_name,
          email: userData.email,
          video_call_method: userData.video_call_method,
          video_call_address: userData.video_call_address,
          commPref: userData.contact_method,
          contactFreq: userData.contact_frequency,
          phone: userData.phone,
          profile_image: userData.profile_image,
          weekly_updates: userData.updates,
          carrier: !!userData.carrier,
          notification_setting: userData.notification_setting,
          timezone: userData.timezone,
          alt_phone: userData.alt_phone,
          alt_email: userData.alt_email,
          textReminderFrequency: userData.text_notification_type,
          textReminderTime: alteredTime,
          profileImageStatus: userData.profile_image_status,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getInfo();
  }

  goBack() {
    window.history.back();
  }

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  accountUpdate(e) {
    e.preventDefault();
    this.setState({ show: false });
    let query_string = "";
    phone = parseInt(this.state.phone);

    if (this.state.firstName) {
      query_string = query_string + `first_name=${this.state.firstName}&`;
    }
    if (this.state.lastName) {
      query_string = query_string + `last_name=${this.state.lastName}&`;
    }
    if (this.state.email) {
      query_string = query_string + `email=${this.state.email}&`;
    }
    if (this.state.phone) {
      query_string = query_string + `phone=${this.state.phone}&`;
    }
    if (this.state.timezone) query_string +=`&timezone=${this.state.timezone}&`;
    if (this.state.alt_phone) query_string += `&alt_phone=${this.state.alt_phone}&`;
    if (this.state.alt_email) query_string += `&alt_email=${this.state.alt_email}&`;

    if (phone.toString().length < 10) {
      this.setState({ phone: userData.phone }, () => {
        alert("please follow pattern for phone number");
      });
      return;
    }

    axios
      .post(`/user/${this.props.username}/client-detail/add?${query_string}`)
      .then(res => {
        if (res.data.message === "SUCCESS") {
          this.getInfo();
          this.setState({ show: true });
          this.setState({ alert: "success" });
          this.setState({ alertStyle: true });
          this.setState({ formChanged: false });
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({ show: true });
        this.setState({ alert: "error" });
        this.setState({ alertStyle: false });
      });
  }

  setProfileImage(file) {
    let data = new FormData();
    data.append("image_upload", file);
    data.append("user", this.props.username);
    axios
      .post(`/user/add-photo`, data)
      .then(res => {
        this.setState({
          profile_image: res.data.result.profile_image,
          formChanged: true,
          imgUploaded: true
        });
      })
      .catch(e => {
        this.errorHandler(e.message);
      });
  }

  errorHandler = error => {
    this.setState({ error: error });
    this.setState({ show: true });
    this.setState({ alert: error });
    this.setState({ alertStyle: false });
  };

  clickImg = confirmation => {
    if (!confirmation) {
      this.setState({ imgUploaded: false });
    }
    if (confirmation) {
      this.setState({ imgUploaded: true });
    }
  };

  saveChanges() {
    if (!this.state.imgUploaded) {
      return (
        <input
          disabled
          className="btn primary"
          type="submit"
          value="IMAGE UPLOADING"
        />
      );
    } else if (this.state.error) {
      return (
        <PrimaryButton className="btn primary" type="submit">SAVE</PrimaryButton>
      );
    } else {
      return (
        <PrimaryButton className="btn primary tour-profile-save" type="submit">SAVE</PrimaryButton>
      );
    }
  }

    statusFlag = (status) => {
    if (status === NICKNAME_STATUSES.PENDING) {
      return <div className={styles.pendingFlag}>Pending Approval</div>
    } else if (status === NICKNAME_STATUSES.REJECTED || status === NICKNAME_STATUSES.ASSIGNED) {
      return <div className={styles.rejectionFlag}>The profile image you selected was not approved. Please choose another.</div>
    }
  }

  render() {
    let changeAlertStyle = this.state.alertStyle ? "success" : "danger";

    return (
      <BoxContainer className='padding-standard'>
        <form onSubmit={e => this.accountUpdate(e)}>
          <div>
            <div className="row">
              <div className="col-xs-12 flex-vertically t-a:c margin-bottom">
                <div className="profile-img-container">
                  <div/>
                  <ImageUpload
                    image={this.state.profile_image}
                    setProfileImage={this.setProfileImage}
                    errorHandler={this.errorHandler}
                    clickImg={this.clickImg}
                  />
                </div>
                <div>
                  <p
                    onClick={event =>
                      this.setState({ profile_image: null, formChanged: true })
                    }
                  >
                    x Remove Image
                  </p>
                  {this.statusFlag(this.state.profileImageStatus)}
                </div>
              </div>
            </div>
              <hr/>
            <div className="row">
              <div className="col-xs-6">
                <div className="form-group">
                  <label>First name</label>
                  <input
                    name="firstname"
                    type="text"
                    minlength="1"
                    value={this.state.firstName}
                    onChange={event =>
                      this.setState({
                        firstName: event.target.value,
                        formChanged: true
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="col-xs-6">
                <div className="form-group">
                  <label>Last name</label>
                  <input
                    minlength="1"
                    type="text"
                    value={this.state.lastName}
                    onChange={event =>
                      this.setState({
                        lastName: event.target.value,
                        formChanged: true
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <div className='form-group'>
                        <label htmlFor="email">Email</label>
                        <input id='email' name="email" type="email"
                        value={this.state.email}
                        onChange={(event)=>this.setState({email:event.target.value, formChanged:true})}
                        required
                         />
                    </div>
                  </div>
                <div className="col-xs-12 col-sm-6">
                    <div className='form-group'>
                        <label htmlFor="alt_email">Alternative Email</label>
                        <input id='alt_email' name="alt_email" type="email"
                        value={this.state.alt_email}
                        onChange={(event)=>this.setState({alt_email:event.target.value, formChanged:true})}
                         />
                    </div>
                  </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-4">
                    <div className='form-group'>
                        <label>Mobile Phone (xxx) xxx-xxxx
                        </label>
                        <NumberFormat
                            format="(###) ###-####" mask="_"
                            className="phone-input"
                            type="tel"
                            value={this.state.phone}
                            onChange={(event)=>this.setState({phone:event.target.value.replace(/\D/g,''), formChanged:true})}
                            required
                        />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4">
                    <div className='form-group'>
                        <label>Alt Phone
                        </label>
                        <NumberFormat
                            format="(###) ###-####" mask="_"
                            className="phone-input"
                            type="tel"
                            value={this.state.alt_phone}
                            onChange={(event)=>this.setState({alt_phone:event.target.value.replace(/\D/g,''), formChanged:true})}
                        />
                    </div>
                </div>
            </div>

            <div>
              {this.state.show ? (
                  <Alert
                      className="save-profile-alert"
                      bsStyle={changeAlertStyle}
                      onDismiss={this.handleDismiss}
                  >
                    <p>{this.state.alert}</p>
                  </Alert>
              ) : null}
              <div className='d:f j-c:f-e'>{this.saveChanges()}</div>
            </div>

          </div>
        </form>
      </BoxContainer>
    );
  }
}

export default withRouter(Account);


