export const colors = {
    black: '#212427',
    blue: '#4E88A1',
    blueLight: '#eaf0f3',
    bronze: '#d6764c',
    darkBlue: '#354052',
    grey: '#ccc',
    greyDark: '#777777',
    greyLight: '#dbd8d8',
    greyLight2: '#eaecf1',
    greyLight3: '#F5F5F5',
    green: '#4bbc4e',
    greenFlat: '#6BA543',
    greenLight: '#afce99',
    gold: '#f7b637',
    platinum: '#a2cad8',
    red: '#b61111',
    redLight: '#f93b3f',
    silver: '#9d9d9d',
    turquoise: '#42cdea',
    white: '#ffffff',
    yellow: '#EEBF38',
    yellowLight: '#f8e5af',
}