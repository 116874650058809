import React from 'react';
import { format } from 'date-fns';
import { ReactComponent as MonthlyLoginsIcon } from '../../../images/icons/Monthly_Logins_Icon.svg';
import styles from '../UserProgress.module.css';

// Monthly Logins Tile Component for Progress Page
export const MonthlyLogins = ({
  selectedDate,
  monthlyLogins
}) => {
  return (
    <div className={styles.monthlyLoginTile}>
      <div className={styles.iconHabitBackground} style={{ backgroundColor: 'rgba(255, 115, 3, 0.15)' }} />
      <div className={styles.iconHabit}>
        <MonthlyLoginsIcon width={21} height={26} />
      </div>
      <div className={styles.achievementTitleContainer}>
        <span className={styles.achievementTitle}>{format(selectedDate, 'MMMM')}</span>
        <span className={styles.achievementTitle} style={{ fontWeight: 600 }}>Logins</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
        <span className={styles.monthlyLoginValue} style={{ fontWeight: 600 }}>{monthlyLogins}</span>
        <span className={styles.monthlyLoginDays}>Days</span>
      </div>
      <div className={styles.calendarContainer}>
        {[...Array(4)].map((_, rowIndex) => (
          <div key={`row-${rowIndex}`} className={styles.calendarRow}>
            {[...Array(7)].map((_, colIndex) => (
              <div key={`dot-${rowIndex}-${colIndex}`} className={styles.calendarDot} />
            ))}
          </div>
        ))}
        <div className={styles.calendarRow}>
          {[...Array(3)].map((_, i) => (
            <div key={`last-${i}`} className={styles.calendarDot} />
          ))}
        </div>
      </div>
    </div>
  );
};
