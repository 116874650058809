import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import styles from "./linkList.module.css";

const LinkList = (props) => {
  const {
    links = [],
    exact = true,
    forceDropdown,
    location,
    history,
    className,
    callback,
  } = props;

  // extract url for wotd to check which version
  const urlParts = window.location.pathname.split("/");
  const wotdVersion = urlParts[urlParts.length - 1];

  if (forceDropdown) {
    return (
      <div
        className={`${styles.container} ${styles.onlyDropdown} ${className}`}
      >
        <div className="container">
          <select
            className={styles.dropdown}
            onChange={(e) => history.push(e.target.value)}
            value={location.pathname}
          >
            {links.map((link) => (
              <option key={`${link.label}_dropdown`} value={link.href || ""}>
                {link.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.container} ${className}`} data-testID="linklist">
      <div className={`container ${styles.mobile}`}>
        <select
          className={styles.dropdown}
          onChange={(e) => history.push(e.target.value)}
          value={location.pathname}
          data-testID="linklist-select-dropdown"
        >
          {links.map((link) => (
            <option key={`${link.label}_mobile`} value={link.href || ""}>
              {link.label}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.desktop} data-testID="linklist-desktop">
        {links.map((link) =>
          link.href ? (
            <NavLink
              onClick={() => {
                if (callback) callback();
              }}
              key={`${link.label}_desktop`}
              exact={exact}
              activeClassName={styles.active}
              className={styles.link}
              to={link.href}
              data-testID="linklist-desktop-link"
          >
              {link.label}
            </NavLink>
          ) : (
            <span key={`${link.label}_desktop`} className={styles.link}>
              {link.label}
            </span>
          )
        )}
        {wotdVersion === "v1" || wotdVersion === "v2" ? (
          <div className={styles.dropdownBackground}>
            <NavLink
              exact={exact}
              activeClassName={styles.activeDropdown}
              className={`${styles.link} ${styles.linkDropdown}`}
              to="/dashboard/analytics/wotd/v1"
            >
              - WOTD v1
            </NavLink>
            <NavLink
              exact={exact}
              activeClassName={styles.activeDropdown}
              className={`${styles.link} ${styles.linkDropdown}`}
              to="/dashboard/analytics/wotd/v2"
            >
              - WOTD v2
            </NavLink>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(LinkList);
