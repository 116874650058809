import React, { useState } from "react";
import WYSIWYG from "../../../WYSIWYG/WYSIWYG";
import InputField from "../../../InputField/InputField";
import { connect } from "react-redux";
import { portalApiMap } from "../../../../store/actions/portalAction.js";
import styles from "./CustomerPortalUsers.module.css"; // Import styles
import { BoxContainer } from "../../../containers/boxContainer/boxContainer";
const PortalDashboardMessage = (props) => {
  const { portalApiMap, organizationId } = props;

  // State to store headline and WYSIWYG content
  const [headline, setHeadline] = useState("");
  const [content, setContent] = useState("");

  // Handler to update state when text input changes
  const handleHeadlineChange = (event) => {
    setHeadline(event.target.value);
  };

  // Handler to send the dashboard message
  const handlePostDashboardMessage = async () => {
    try {
      const { postPortalDashboardMessage } = await portalApiMap();
    const response =  await postPortalDashboardMessage(organizationId, headline, content);
      console.log(response)
      alert("Dashboard message updated successfully!");
    } catch (error) {
      alert("Error creating/updating message: " + error.message);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Headline Input Field */}
      <InputField
        title={"Headline"}
        label={"Headline"}
        labelStyle={{ color: "black", fontWeight: 400}}
        inputStyle={{backgroundColor: "white"}}
        value={headline}
        onChange={handleHeadlineChange}
      />

      {/* WYSIWYG Editor for Content */}
      <WYSIWYG value={content} onChangeHandler={setContent} />


      <BoxContainer
        className={styles.customerBtnContainer}
        onClick={handlePostDashboardMessage}
      >
        <span>Save</span>
      </BoxContainer>
      {/* Submit Button */}

    </div>
  );
};

const actions = {
  portalApiMap,
};

export default connect(null, actions)(PortalDashboardMessage);
