import React, {useRef, useState} from 'react';
import {connect} from "react-redux";

// images & icons
import {ReactComponent as TreoLock} from '../../images/treo_lock.svg';

// actions
import {fetchConfirmationCode} from "../../store/actions/authActions";
import {confirmUserOTP} from "../../store/actions/userActions";
import {fetchSelf} from "../../actions/action_user";
import {updateStatus} from "../../store/actions/userActions";

// styles
import styles from './userConfirm.module.css';
import helpers from "../helperStyles.module.css";

// components
import {STATUSES} from "../../serverVariables/statuses";
import ClientHeader from "../clientHeader/clientHeader";
import {PrimaryButton} from "../buttons/buttons";

const ConfirmUser = props => {
    // props
    const {navigation} = props;
    // actions
    const {fetchSelf, updateStatus} = props;
    // state
    const {user} = props;
    // local
    const [code, setCode] = useState("");
    const [codeError, setCodeError] = useState("");
    const disableSendAgain = useRef(0)
    const [disableSendAgainState, setDisableSendAgainState] = useState(0);
    const [disableSubmit, setDisableSubmit] = useState(false);

    // allow user to send another message every 30s
    async function sendAgain() {
        if (disableSendAgain.current > 0) return;
        disableSendAgain.current = 60;
        const interval = setInterval(() => {
            disableSendAgain.current -= 1;
            setDisableSendAgainState(disableSendAgain.current);
            if (disableSendAgain.current < 1) clearInterval(interval);
        }, 1000);
        try {
            // code to send again goes here
            await fetchConfirmationCode();
        } catch (e) {
            console.error('Unable to retrieve confirmation code', e);
        }
    }

    function showSendAgain() {
        if (disableSendAgainState > 0) {
            return (
                <p className={helpers.textCenter}>Message sent again. Please wait {disableSendAgainState} seconds to try
                    sending again.</p>
            )
        } else {
            return (
                <div onClick={sendAgain}>
                    <p className={`text-primary ${styles.receiveCode}`}>Didn't receive code? Send again.</p>
                </div>
            )
        }
    }

    async function submit() {
        setCodeError("")
        // ensure number is entered
        if (!code) return setCodeError("Code cannot be blank");
        // ensure number is a positive integer
        const num = Number(code);
        if (!Number.isInteger(num) && num < 0) return setCodeError('Invalid code');
        try {
            if (disableSubmit) return;
            setDisableSubmit(true);
            const data = await confirmUserOTP(code);
            if (data.confirmed) {
                setCodeError("")
                await updateStatus(user.email, STATUSES.onboarding_assessment);
                await fetchSelf();
                setDisableSubmit(false);
                props.history.push('/member/onboard-assessment');
            } else if (data.error){
                setCodeError('Invalid code');
                setDisableSubmit(false);
            }
        } catch (e) {
            setCodeError('Invalid code');
            setDisableSubmit(false);
        }
    }

    return (
        <div className='client-container'>
            <ClientHeader/>
            <div className={styles.outerContainer}>
                <div className={styles.container}>
                    <TreoLock width={60} height={60}/>
                    <p className={styles.title}>
                        Confirm Your Identity
                    </p>

                    <p className={helpers.textCenter}>
                        Before we continue we need to confirm your identity. Please enter the 6 digit code that was sent
                        to the number ending in {String(user.phone).toString().substring(6)}
                    </p>

                    <div>
                        <input
                            className={styles.input}
                            value={code}
                            onChange={e => setCode(e.target.value)}/>
                        {codeError ? <p className={`text-danger ${helpers.textCenter}`}>{codeError}</p> : null}

                    </div>

                    {showSendAgain()}

                    <PrimaryButton disabled={disableSubmit} onClick={submit}>Confirm</PrimaryButton>
                </div>
            </div>
        </div>
    );
}

const actions = {
    fetchSelf,
    updateStatus
}

function mapStateToProps(state) {
    return {user: state.user};
}

export default connect(mapStateToProps, actions)(ConfirmUser);