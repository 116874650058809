// npm modules
import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Modal, Alert } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

// actions
import { loadPolicyList, setCompliance } from "../../actions/action_policy";
import {fetchSelf} from "../../actions/action_user";

// styles
import styles from './policyComplianceView.module.css';

// components
import {BasicButton, PrimaryButton} from "../buttons/buttons";
import Loading from "../Loading/Loading";
import { STATUSES } from "../../serverVariables/statuses";
import {fetchActivePolicies} from "../../store/actions/policyActions";

const PolicyComplianceView = ({ user, clientPolicyList, loadPolicyList, setCompliance, fetchSelf, ...props }) => {
    const [ showModal, setShowModal ] = useState(false); // controls if modal is present
    const [ currentPolicy, setCurrentPolicy ] = useState({}); // holds current policy being viewed
    const [ currentVersion, setCurrentVersion ] = useState({}); // holds current version being viewed
    const [ activeVersionList, setActiveVersionList ] = useState([]); // holds a list of active versions belonging to the clientPolicyList
    const [ message, setMessage ] = useState(''); // holds message for user if they refuse to accept policies

    const acceptPolicy = (policy) => {
        // change policy if one wos provided
        if (policy) {
            const changed = clientPolicyList.map(item => {
                if (policy.id !== item.id) return item; // this is not the one we're looking for in the change
                return policy;
            });
            loadPolicyList(changed);
        }
        setCurrentVersion({});
        setCurrentPolicy({});
        setShowModal(false);
    };

    // set the active version of the policy being currently viewed
    const loadVersion = (policy) => {
        setCurrentPolicy(policy); // load policy into local state

        // search through the active version state array for the policy that was passed
        const found = activeVersionList.filter(item => item.id === policy.active_version_id);
        setCurrentVersion(found.length > 0 ? found.shift() : {});
        setShowModal(true); // open the modal
    };

    // save acceptance/denial to server and recheck compliance
    const saveAcceptedPolicies = () => {
        const acceptancePromises = clientPolicyList.map(policy => {
            if (policy.accepted && typeof policy.accepted === 'boolean') {
                return axios.put(`/policy/${policy.id}/version/${policy.active_version_id}/user/${user.username}/optin`);
            } else if(!policy.accepted) {
                return axios.put(`/policy/${policy.id}/version/${policy.active_version_id}/user/${user.username}/optout`);
            }
        });

        Promise.all(acceptancePromises)
            .then(() => {
                // recheck to see if user has completed compliance
                axios.get(`/policy/fullcompliance/user/${user.username}`)
                    .then(compRes => {
                        const compliant = compRes.data.result;
                        setCompliance(compliant);
                        if (compliant) {
                            // set user status to next status
                            if (user.user_status_id === STATUSES.unconfirmed_user) {
                                // forward to appropriate route
                                props.history.push(`/member/onboard-confirm`)
                            } else {
                                // forward to appropriate route
                                if (user.user_status_id < STATUSES.active_coached ){
                                    props.history.push(`/member/onboard-assessment`)
                                } else {
                                    props.history.push(`/member/clientDash/${user.username}`);
                                }
                            }
                        } else {
                            setMessage('You must accept all policies to access Treo Habit Builder. Thank you');
                        }
                    });
            }).catch(e => console.log(e));

    };

    // check which policies have been accepted and update clientPolicyList
    const checkPolicies = async () => {
        if (!clientPolicyList || clientPolicyList.length === 0) return;
        const promises = clientPolicyList.map(policy => {
            return axios.get(`/policy/compliance/policy/${policy.id}/version/${policy.active_version_id}/user/${user.username}/`)
                .then(res => res.data.result)
                .catch(e => console.log(e));
        });
        const finalResults = await Promise.all(promises);
        const changed = clientPolicyList.map(policy => {
            // check if policy is in finalResults
            const found = finalResults.filter(item => {
                if (!item) return false;
                return item.policy_item_id === policy.id;
            });
            // set new policy item status on found policy
            if (found.length > 0) return { ...policy, accepted: found[0].opt_in || found[0].opt_out };
            // otherwise return unchanged policy
            return policy;
        });
        loadPolicyList(changed);
    };

    // on mount scheduling
    useEffect(() => {
        fetchActivePolicies()
            .then(res => {
                let polices = res.map(item => {
                    item.accepted = true;
                    return item;
                });
                loadPolicyList(polices);
            }).catch(e => console.log(e));
    }, []);

    const [ checked, setChecked ] = useState(false);
    useEffect(() => {
        if (checked) return;
        checkPolicies();
        if ( Array.isArray(clientPolicyList) && !checked){
            setChecked(true);
        }
    }, [ clientPolicyList ]);


    // load active policy versions when clientPolicyList changes
    // and put into state array
    useEffect(() => {
        if (!clientPolicyList || clientPolicyList.length === 0) return;
        const versions = [];
        const policyPromises = clientPolicyList.map(policy => (
            axios.get(`/policy/version/${policy.active_version_id}`)
                .then(res => {
                    if (Array.isArray(res.data.result) && res.data.result.length > 0) versions.push(res.data.result[0])
                })
            )
        );
        Promise.all(policyPromises)
            .then(() => {
                setActiveVersionList(versions);
            })
            .catch(e => console.log(e));
    }, [ clientPolicyList ]);

    // dismount scheduling
    useEffect(() => {
        return () => {
            loadPolicyList(null);
        }
    },[]);

    if (!clientPolicyList) return <div/>;

    return (
        <div className={styles.container}>
			<div className="client-container policy-container">    
				<h1>Treo Terms & Conditions </h1>
				<p>I agree to these Terms & Conditions</p>
				<form>
					{ activeVersionList.length ? clientPolicyList.map(policy => {
						return (
							<div key={policy.id} className="form-group">

                                <div className="label">
                                    <input
                                        id={`_policy-${policy.id}`}
                                        type="checkbox"
                                        checked={policy.accepted}
                                        onChange={() => {
                                            const found = clientPolicyList.find(item => item.id === policy.id);
                                            acceptPolicy({
                                                ...policy,
                                                accepted: !found.accepted
                                        })}}
                                    />
                                    <label>
									<span onClick={() => policy.id ? loadVersion(policy) : null}>
                                        {policy.title} <span className='details'>&#40;view details&#41;</span>
									</span>
                                    </label>
                                </div>
							</div>
						)
					}) : <Loading/> }
					{ message ? 
						<div>
							<Alert className="alert-danger">{message}</Alert>
						</div> : null 
					}
					<PrimaryButton className="btn" onClick={saveAcceptedPolicies}>Agree & Proceed</PrimaryButton>
				</form>
			</div>
            <Modal show={showModal} onHide={()=>acceptPolicy()}>
                <Modal.Header closeButton>
					<Modal.Title>{currentPolicy.title} <span>(version {currentVersion.version_number})</span></Modal.Title>
				</Modal.Header>
                <Modal.Body>
                    { currentVersion.id ?
                        <div>                                                        
                            <div className="margin-bottom-2x" dangerouslySetInnerHTML={{__html:currentVersion.text}} />
							<BasicButton className="btn red margin-right"  onClick={()=>acceptPolicy({...currentPolicy, accepted: false})}>Decline</BasicButton>
                            <BasicButton className="btn primary" onClick={()=>acceptPolicy({...currentPolicy, accepted: true})}>Accept Policy</BasicButton>                            
                        </div>
                        : <Loading/>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};

const mapStateToProps = ({ user, clientPolicyList, }) => {
    return { user, clientPolicyList, }
};

const actions = {
    loadPolicyList,
    setCompliance,
    fetchSelf,
};

PolicyComplianceView.propTypes = {
    user: PropTypes.shape({ username: PropTypes.string }),
    clientPolicyList: PropTypes.array,
    loadPolicyList: PropTypes.func,
    setCompliance: PropTypes.func,
    goBack: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, actions)(PolicyComplianceView));