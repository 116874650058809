import React from "react";
import { useState } from "react";

// components
import OrganizationCategoriesMenu from "../components/OrganzationCategoriesMenu";
import OrganizationDocumentList from "../components/OrganizationDocumentList";

// styles
import styles from "./CustomerPortalUsers.module.css"; 

const Documentation = (props) => {
  const {  organizationId } = props;
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <div className={styles.documentationContainer}>
      <OrganizationCategoriesMenu
        organizationId={organizationId}
        categories={categories}
        setCategories={setCategories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <OrganizationDocumentList
        organizationId={organizationId}
        selectedCategory={selectedCategory}
      />
    </div>
  );
};

export default Documentation;
