import React, { useEffect, useState } from "react";
import styles from "./CustomerPortalUsers.module.css"; // Import your customer portal styles
import { portalApiMap } from "../../../../store/actions/portalAction";

import { BoxContainer } from "../../../containers/boxContainer/boxContainer";
import CustomerTile from "../../../customerTile/CustomerTile";
import { connect } from "react-redux";
import DocumentModal from "../../../documentModal/DocumentModal";
import Loading from "../../../Loading/Loading";

const CustomerPortalUsers = (props) => {
  const { portalApiMap, organizationId } = props;

  const [customers, setCustomers] = useState([]);
  const [customerModal, setCustomerModal] = useState(false);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [openRemoveUserModal, setOpenRemoveUserModal] = useState(false);

  const [newCustomer, setNewCustomer] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    organization_id: organizationId || "",
  });

  useEffect(() => {
    const fetchCustomers = async () => {
      setLoading(true);
      setError(null);

      try {
        const { getPortalCustomerUsers } = await portalApiMap();
        const response = await getPortalCustomerUsers(organizationId);
        setCustomers(response.data.results || []);
      } catch (err) {
        console.error("Failed to fetch customers:", err);
        setError("Failed to fetch customer users.");
      } finally {
        setLoading(false);
      }
    };

    if (organizationId) {
      fetchCustomers();
    }
  }, [organizationId, portalApiMap]);

  // Handle input changes and clear any existing field errors
  const handleInputChange = (field, value) => {
    setNewCustomer((prev) => ({ ...prev, [field]: value }));
    setFieldErrors((prev) => ({ ...prev, [field]: "" }));
  };

  // Validate each field and set field-specific errors
  const validateCustomer = () => {
    let errors = {};
    if (!newCustomer.first_name.trim()) {
      errors.first_name = "First name is required";
    }
    if (!newCustomer.last_name.trim()) {
      errors.last_name = "Last name is required";
    }
    if (!newCustomer.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(newCustomer.email)) {
      errors.email = "Email is invalid";
    }
    if (!newCustomer.password) {
      errors.password = "Password is required";
    } else if (newCustomer.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleRemoveCustomer = async (email) => {
    setLoading(true);
    try {
      const { removeCustomerUser } = await portalApiMap();
      await removeCustomerUser(email);
      setCustomers((prev) =>
        prev.filter((customer) => customer.email !== email)
      );
      setOpenRemoveUserModal(false);
    } catch (err) {
      console.error("Failed to remove user:", err);
      alert(err.message || "Failed to remove user.");
    } finally {
      setLoading(false);
    }
  };

  // Handle new customer submission with improved error handling
  const handleAddCustomer = async (e) => {
    e.preventDefault();
    setError(null);

    if (!validateCustomer()) {
      return;
    }

    setLoading(true);

    try {
      const { postCreateCustomerPortalUser } = await portalApiMap();
      const response = await postCreateCustomerPortalUser(newCustomer);
      console.log(response.error);
      // Add the new customer to the existing list
      setCustomers((prev) => [
        ...prev,
        {
          email: newCustomer.email,
          name: `${newCustomer.first_name} ${newCustomer.last_name}`,
          role: "User",
        },
      ]);

      // Reset form and close modal
      setCustomerModal(false);
      setNewCustomer({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        organization_id: organizationId,
      });
      setFieldErrors({});
    } catch (err) {
      console.error("Failed to create user:", err);
      // Attempt to extract error data
      const errorData = err.response && err.response.data && err.response.data.error;
      if (errorData) {
        // If errorData is an object, convert it to a string
        if (typeof errorData === "object") {
          setError(Object.values(errorData).join(", "));
        } else {
          setError(errorData);
        }
      } else {
        setError("Failed to create user.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.customerPortal}>
      <div className={styles.boxes}>
        {loading ? (
          <Loading />
        ) : error ? (
          <p className={styles.error}>{error}</p>
        ) : customers.length === 0 ? (
          <p>No customers found. Click below to add one.</p>
        ) : (
          customers.map((customer) => (
            <CustomerTile
              key={customer.email}
              customer={customer}
              openRemoveUserModal={openRemoveUserModal}
              setOpenRemoveUserModal={setOpenRemoveUserModal}
              remove={() => handleRemoveCustomer(customer.email)}
            />
          ))
        )}
      </div>

      {/* Add Customer Button */}
      <BoxContainer
        className={styles.customerBtnContainer}
        onClick={() => setCustomerModal(true)}
      >
        <span>+ Add Customer</span>
      </BoxContainer>

      {/* Add New Customer Modal */}
      <DocumentModal
        modalOpen={customerModal}
        setModalOpen={setCustomerModal}
        title="Add New Customer"
        fields={[
          {
            label: "First Name",
            type: "text",
            placeholder: "Enter first name",
            value: newCustomer.first_name,
            onChange: (e) => handleInputChange("first_name", e.target.value),
            error: fieldErrors.first_name,
          },
          {
            label: "Last Name",
            type: "text",
            placeholder: "Enter last name",
            value: newCustomer.last_name,
            onChange: (e) => handleInputChange("last_name", e.target.value),
            error: fieldErrors.last_name,
          },
          {
            label: "Email",
            type: "text",
            placeholder: "Enter email",
            value: newCustomer.email,
            onChange: (e) => handleInputChange("email", e.target.value),
            error: fieldErrors.email,
          },
          {
            label: "Password",
            type: "text",
            placeholder: "Enter password",
            value: newCustomer.password,
            onChange: (e) => handleInputChange("password", e.target.value),
            error: fieldErrors.password,
          },
        ]}
        handleSubmit={handleAddCustomer}
        submitLabel={loading ? "Adding..." : "Add Customer"}
      />

      {/* Global Error Display */}
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

const actions = {
  portalApiMap,
};

export default connect(null, actions)(CustomerPortalUsers);
