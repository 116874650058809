import React, {useEffect, useRef, useState} from 'react';
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import _ from "lodash";
import {connect} from 'react-redux';

// styles
import styles from './myVictoryFeed.module.css';

// actions
import {fetchMonthlyScore} from "../../../../store/actions/wellnessScoreActions";
import {fetchMemberVictories} from "../../../../store/actions/communityActions";

// components
import {REACTION_TYPES} from "../../../../serverVariables/reactionTypes";
import LikeReaction from "../../likeReaction/likeReaction";
import WowReaction from "../../wowReaction/wowReaction";
import EnvyReaction from "../../envyReaction/envyReaction";
import LoveReaction from "../../loveReaction/loveReaction";
import {ReactComponent as StreakIcon} from "../../../../images/icons/Fire_Streak.svg";
import {VICTORY_TYPES} from "../../../../serverVariables/victoryTypes";
import {
    CheckIn15_200,
    CheckIn8_200,
    Watched_8_shadow,
    Watched_15_shadow
} from "../../../../serverVariables/badges";
import Loading from "../../../Loading/Loading";
import SelectVictoryImage from "../../../selectVictoryImage/selectVictoryImage";

const MyVictoryFeed = props => {
    // props
    const {user, monthlyScore} = props;
    // actions
    const {fetchMonthlyScore} = props;
    // local
    const [victories, setVictories] = useState(null);
    const [loading, setLoading] = useState(false);
    const endReached = useRef(false);
    const [endReachedState, setEndReachedState] = useState(endReached.current);
    const last = useRef();
    const feed = useRef();
    const victoryContainer = useRef();

    useEffect(() => {
        feed.current = victories;
    }, [victories]);

    useEffect(() => {
        fetchMonthlyScore()
            .catch(e => console.log(e))
        loadVictories();
    }, []);

    useEffect(() => {
        victoryContainer.current.addEventListener('scroll', _.throttle(onScroll, 500));
        return () => {
            victoryContainer.current.removeEventListener('scroll', onScroll);
        }
    }, []);

    async function onScroll() {
        if (last.current && !endReached.current) {
            const inView = isElementInViewport(last.current);
        if (inView && !loading && feed.current) {
                setLoading(true);
                await loadVictories(feed.current);
            }
            setLoading(false);
        }
    }

    function isElementInViewport (el) {
        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    async function loadVictories(oldVictories) {
        try {
            let res;
            if (oldVictories) {
                res = await fetchMemberVictories(oldVictories[oldVictories.length-1].victory.id)
                setVictories([...oldVictories, ...res.victories]);
            } else {
                res = await fetchMemberVictories();
                setVictories(res.victories);
            }
            if (!res.more) {
                setEndReachedState(true);
                endReached.current = true;
            }
        } catch(e) {
            console.log(e);
        }
    }

    function selectVictoryTitle(victory) {
        if (victory.victory.victory_type.id === VICTORY_TYPES.CHECK_IN_8 ||
            victory.victory.victory_type.id === VICTORY_TYPES.CHECK_IN_15
        ) {
            return <strong>Earned {victory.victory.victory_type.name} Badge</strong>
        }
        if (victory.victory.victory_type.id === VICTORY_TYPES.BADGES) {
            return <strong>Earned {victory.victory.level_achievement.level.name} Badge</strong>
        }
        if (victory.victory.victory_type.id === VICTORY_TYPES.STREAK) {
            return <strong>7 Day Streak</strong>
        }
        if (
            victory.victory.victory_type.id === VICTORY_TYPES.MEALTIME_SPACING ||
            victory.victory.victory_type.id === VICTORY_TYPES.EATING_OCCASIONS ||
            victory.victory.victory_type.id === VICTORY_TYPES.VEGETABLES_AND_FRUIT ||
            victory.victory.victory_type.id === VICTORY_TYPES.PROTEIN_SERVINGS ||
            victory.victory.victory_type.id === VICTORY_TYPES.SWEETENED_FOODS ||
            victory.victory.victory_type.id === VICTORY_TYPES.SWEETENED_DRINKS ||
            victory.victory.victory_type.id === VICTORY_TYPES.STRESS_RELEASING ||
            victory.victory.victory_type.id === VICTORY_TYPES.SLEEP ||
            victory.victory.victory_type.id === VICTORY_TYPES.PHYSICAL_ACTIVITY ||
            victory.victory.victory_type.id === VICTORY_TYPES.SPRINT_INTENSITY ||
            victory.victory.victory_type.id === VICTORY_TYPES.STRENGTH_BUILDING ||
            victory.victory.victory_type.id === VICTORY_TYPES.RELATED_SKILLS ||
            victory.victory.victory_type.id === VICTORY_TYPES.FOUNDATIONS ||
            victory.victory.victory_type.id === VICTORY_TYPES.COACH ||
            victory.victory.victory_type.id === VICTORY_TYPES.JUST_FOR_YOU ||
            victory.victory.victory_type.id === VICTORY_TYPES.STEPS_100K ||
            victory.victory.victory_type.id === VICTORY_TYPES.STEPS_150K ||
            victory.victory.victory_type.id === VICTORY_TYPES.STEPS_200K ||
            victory.victory.victory_type.id === VICTORY_TYPES.STEPS_300K
        ) {
            return <strong>Earned {victory.victory.victory_type.name} Badge</strong>
        }

        if (victory.victory.victory_type.id === VICTORY_TYPES.RANK) {
            return <strong>{victory.victory.victory_type.name}</strong>
        }

        if (victory.victory.victory_type.id === VICTORY_TYPES.VIDEOS_WATCHED_8 ||
            victory.victory.victory_type.id === VICTORY_TYPES.VIDEOS_WATCHED_15
        ) {
            return <strong>{victory.victory.victory_type.name}</strong>
        }
    }

    function abbrNum(number, decPlaces) {
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10,decPlaces);

        // Enumerate number abbreviations
        var abbrev = [ "k", "m", "b", "t" ];

        // Go through the array backwards, so we do the largest first
        for (var i=abbrev.length-1; i>=0; i--) {

            // Convert array index to "1000", "1000000", etc
            var size = Math.pow(10,(i+1)*3);

            // If the number is bigger or equal do the abbreviation
            if(size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round(number*decPlaces/size)/decPlaces;

                // Handle special case where we round up to the next abbreviation
                if((number === 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }

                // Add the letter for the abbreviation
                number += abbrev[i];

                // We are done... stop
                break;
            }
        }

        return number;
    }

    function render() {
        if (!victories) {
            return (<div className="padding-standard"><Loading/></div>)
        }

        return (
            <>
                <div className={styles.label}>Recent</div>
                <div>
                    {victories.map((victory, i) => {
                        let likes, loves, wows, envies;
                        const reactions = victory.reactions;
                        if (reactions) {
                            likes = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.LIKE);
                            if (likes) likes = likes.count;
                            else likes = 0;

                            wows = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.WOW);
                            if (wows) wows = wows.count;
                            else wows = 0;

                            envies = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.ENVY);
                            if (envies) envies = envies.count;
                            else envies = 0;

                            loves = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.LOVE);
                            if (loves) loves = loves.count;
                            else loves = 0;

                        }

                        function render() {
                            return (
                                <>
                                    <SelectVictoryImage typeId={victory.victory.victory_type.id} size={200} victory={victory} className={styles.badge}/>
                                    <div>
                                        <div>{selectVictoryTitle(victory)}&nbsp;(earned {format(parseISO(victory.victory.achievement_date), 'MM/dd/yy')})</div>
                                        <div className={styles.reactions} data-testID="victoryReactionBtn">
                                            <div>
                                                <span>{abbrNum(likes, 1)}</span>
                                                <LikeReaction
                                                    disabled
                                                    className={`${styles.icon} ${styles.like}`}
                                                    color='transparent'
                                                    stroke='#354052'
                                                    animatedColor='#6bd0f3'
                                                    animatedStroke='#e8ecf0'/>
                                            </div>
                                            <div>
                                                <span>{abbrNum(wows, 1)}</span>
                                                <WowReaction
                                                    disabled
                                                    className={styles.icon}
                                                    color='transparent'
                                                    stroke='#354052'
                                                    animatedColor='#f7cb5d'
                                                    animatedStroke='#c78430'/>
                                            </div>
                                            <div>
                                                <span>{abbrNum(envies, 1)}</span>
                                                <EnvyReaction
                                                    disabled
                                                    className={styles.icon}
                                                    color='transparent'
                                                    stroke='#354052'
                                                    animatedColor='#adcb54'
                                                    animatedStroke='#3f4e3d'/>
                                            </div>
                                            <div>
                                                <span>{abbrNum(loves, 1)}</span>
                                                <LoveReaction
                                                    disabled
                                                    className={styles.icon}
                                                    color='transparent'
                                                    stroke='#354052'
                                                    animatedColor='#e5466c'
                                                    animatedStroke='transparent'/>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        if (i === victories.length-1){
                            return (
                                <div ref={last} className={styles.victory} data-testID="victoryFeedTile">
                                    {render()}
                                </div>
                            )
                        }
                        else return (
                            <div className={styles.victory} >
                                {render()}
                            </div>
                        )
                    })}
                    {endReachedState ? <p>You're all caught up!</p> : null}
                </div>
                <div data-testID="myVicUpdates">
                    <p className={styles.infographicLabel}>Keep going to hit additional victories</p>
                    <div className={styles.infographic}>
                        <img src="https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Locked200x200.png" alt=""/>
                        <strong>Monthly Goal</strong><span>&nbsp;({monthlyScore}/1500)</span>
                    </div>
                    <div className={styles.infographic}>
                        <StreakIcon/>
                        <strong>7 Day Streak</strong><span>&nbsp;({7-parseInt(user.current_streak)} days to go)</span>
                    </div>
                    <div className={styles.infographic}>
                        <img src={Watched_8_shadow} alt=""/>
                        <strong>Watch 8 Fitness Videos</strong>
                    </div>
                    <div className={styles.infographic}>
                        <img src={Watched_15_shadow} alt=""/>
                        <strong>Watch 15 Fitness Videos</strong>
                    </div>
                    {user.daxko_barcode ?
                        <>
                            <div className={styles.infographic}>
                                <img src={CheckIn8_200} alt=""/>
                                <strong>8 Check Ins / Month</strong>
                            </div>
                            <div className={styles.infographic}>
                                <img src={CheckIn15_200} alt=""/>
                                <strong>15 Check Ins / Month</strong>
                            </div>
                        </>
                        : null
                    }
                </div>
            </>
        )
    }

    return (
        <div ref={victoryContainer} className={styles.myVictories}>
            {render()}
        </div>
    )
}

function mapStateToProps({user, monthlyScore}) {
    return {user, monthlyScore}
}

const actions = {
    fetchMonthlyScore,
}

export default connect(mapStateToProps, actions)(MyVictoryFeed);