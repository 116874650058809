import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Route } from "react-router-dom";

// styles
import styles from "./analyticsContainer.module.css";

// actions
import { fetchOrganizations } from "../../../store/actions/organizationActions";
import { fetchFullCoachList } from "../../../store/actions/userActions";

// components
import UserAnalytics from "../userAnalytics/userAnalytics";
import ContentAnalytics from "../contentAnalytics/contentAnalytics";
import HabitAnalytics from "../habitAnalytics/habitAnalytics";
import CoachAnalytics from "../coachAnalytics/coachAnalytics";
import LinkList from "../../links/linkList/linkList";
import { PERMISSIONS } from "../../../serverVariables/permissions";
import { STATUSES } from "../../../serverVariables/statuses";
import SearchableDropdown from "../../dropdowns/searchableDropdown/searchableDropdown";
import CommunityAnalytics from "../communityAnalytics/communityAnalytics";
import WotdAnalytics from "../wotdAnalytics/WotdAnalytics";
import DataSyncAnalytics from "../dataSyncAnalytics/dataSyncAnalytics";

const GENDERS = [
  { name: "Select Gender", value: "" },
  { name: "Female", value: "female" },
  { name: "Male", value: "male" },
  { name: "Other", value: "other" },
];
const AGE_GROUPS = [
  { name: "Select Age Group", value: "" },
  { name: "18-29", value: "18-29" },
  { name: "30-39", value: "30-39" },
  { name: "40-49", value: "40-49" },
  { name: "50-59", value: "50-59" },
  { name: "60+", value: "60-110" },
];
const MEMBER_STATUS = [
  { name: "Select Status", value: "" },
  { name: "Onboarding - Assessment", value: STATUSES.onboarding_assessment },
  { name: "Active", value: STATUSES.active_not_coached },
  { name: "Active - Coached", value: STATUSES.active_coached },
];

const AnalyticsContainer = (props) => {
  // props
  const { user, match } = props;
  // local
  const [organizations, setOrganizations] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [filters, setFilters] = useState({
    organization: "",
    status: "",
    coach: "",
    gender: "",
    ageGroup: "",
    rank: "",
  });
  const [ready, setReady] = useState(false);

  // fetch organizations
  useEffect(() => {
    let userId = null;
    if (user && user.permission_id === PERMISSIONS.COACH) {
      userId = user.id;
    }
    try {
      prepare(userId).then(() => {
        setReady(true);
      });
    } catch (e) {
      console.log(e);
    }
    async function prepare(userId) {
      return Promise.all([
        fetchOrganizations(userId)
          .then((orgs) => {
            const newOrgs = [
              { name: "Search Organizations", value: "" },
              ...orgs.map((org) => ({ name: org.name, value: org.id })),
            ];
            setOrganizations(newOrgs);
          })
          .catch((e) => console.log(e)),

        fetchFullCoachList()
          .then((list) => {
            if (user.permission_id === PERMISSIONS.COACH) {
              const coach = {
                name: `${user.first_name} ${user.last_name}`,
                value: user.id,
              };
              setCoaches([coach]);
              setFilters({ ...filters, coach: user.id });
            } else {
              const newList = [
                { name: "Select a Coach", value: "" },
                ...list.map((item) => ({
                  name: `${item.first_name} ${item.last_name}`,
                  value: item.id,
                })),
              ];
              setCoaches(newList);
            }
          })
          .catch((e) => console.log(e)),
      ]);
    }
  }, []);

  let links = [];
  if (user.permission_id === PERMISSIONS.ADMIN) {
    links = [
      { href: `${match.url}/user`, label: "User" },
      { href: `${match.url}/content`, label: "Content" },
      { href: `${match.url}/habit`, label: "Habit" },
      { href: `${match.url}/coach`, label: "Coach" },
      { href: `${match.url}/community`, label: "Community" },
      { href: `${match.url}/datasyncing`, label: "Data Syncing" },
      { href: `${match.url}/wotd/v1`, label: "Workout of The Day" }
    ];
  } else {
    links = [
      { href: `${match.url}/user`, label: "User" },
      { href: `${match.url}/habit`, label: "Habit" },
      { href: `${match.url}/coach`, label: "Coach" },
      { href: `${match.url}/community`, label: "Community" },
    ];
  }

  if (!ready) {
    return <div />;
  }
  return (
    <div className="container">
      <div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-light active">Dashboard</li>
          </ol>
        </nav>
      </div>
      <h3 className={styles.heading}>Analytics </h3>
      <div className={styles.container}>
        <div className={styles.sidebar} data-testID="sideNavAdmin">
          <LinkList links={links} />
          {window.location.pathname ===
          "/dashboard/analytics/wotd/v2" ? null : (
            <h4 className="margin-top-25 margin-bottom-15">Filters:</h4>
          )}

          {window.location.pathname !== "/dashboard/analytics/wotd/v2" &&
            (window.location.pathname === "/dashboard/analytics/wotd/v1" ? (
              <form>
                <div className="form-group">
                  <label>Organization</label>
                  <SearchableDropdown
                    options={organizations}
                    onChange={(val) => {
                      setFilters({ ...filters, organization: val });
                    }}
                    value={filters.organization}
                    placeholder="Search Organizations"
                    data-testID="searchDropDown"
                  />
                </div>
              </form>
            ) : (
              <form>
                <div className="form-group" data-testID="org-search">
                  <label>Organization</label>
                  <SearchableDropdown
                    options={organizations}
                    onChange={(val) => {
                      setFilters({ ...filters, organization: val });
                    }}
                    value={filters.organization}
                    placeholder="Search Organizations"
                  />
                </div>
                <div className="form-group" data-testID="member-search">
                  <label>Member Status</label>
                  <SearchableDropdown
                    options={MEMBER_STATUS}
                    onChange={(val) => setFilters({ ...filters, status: val })}
                    value={filters.status}
                    placeholder="Select Status"
                  />
                </div>
                <div className="form-group" data-testID="coach-search">
                  <label>Coach</label>
                  <SearchableDropdown
                    options={coaches}
                    onChange={(val) => setFilters({ ...filters, coach: val })}
                    value={filters.coach}
                    placeholder="Select Coach"
                  />
                </div>
                <div className="form-group" data-testID="select-gender">
                  <label>Gender</label>
                  <SearchableDropdown
                    options={GENDERS}
                    onChange={(val) => setFilters({ ...filters, gender: val })}
                    value={filters.gender}
                    placeholder="Select Gender"
                  />
                </div>
                <div className="form-group" data-testID="select-age">
                  <label>Age Group</label>
                  <SearchableDropdown
                    options={AGE_GROUPS}
                    onChange={(val) =>
                      setFilters({ ...filters, ageGroup: val })
                    }
                    value={filters.ageGroup}
                    placeholder="Select Age Group"
                  />
                </div>
              </form>
            ))}
        </div>
        <div className={styles.content} data-testID="analytics-container">
          <Route
            path={`${match.url}/user`}
            render={() => <UserAnalytics filters={filters} coaches={coaches} />}
          />
          <Route
            path={`${match.url}/content`}
            render={() => <ContentAnalytics filters={filters} />}
          />
          <Route
            path={`${match.url}/habit`}
            render={() => <HabitAnalytics filters={filters} />}
          />
          <Route
            path={`${match.url}/coach`}
            render={() => <CoachAnalytics filters={filters} />}
          />
          <Route
            path={`${match.url}/community`}
            render={() => <CommunityAnalytics filters={filters} />}
          />
          <Route
            path={`${match.url}/datasyncing`}
            render={() => <DataSyncAnalytics filters={filters} />}
          />
          <Route
            path={`${match.url}/wotd/:version`}
            render={() => <WotdAnalytics filters={filters} />}
          />
        </div>
      </div>
    </div>
  );
};
function mapStateToProps({ user }) {
  return { user };
}
export default withRouter(connect(mapStateToProps)(AnalyticsContainer));
