import React, {useState, useEffect} from 'react';
import startOfToday from 'date-fns/startOfToday';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

// styles
import styles from './userAnalytics.module.css';

// actions
import {
    fetchAbandonmentFactors, fetchActiveMembers, fetchAgeDemographics,
    fetchConversionFactors, fetchEngagementData, fetchGenderDemographics, fetchHeightDemographics,
    fetchNewMemberGraph,
    fetchNewMembers, fetchSessionData, fetchTopClients, fetchWeightDemographics,
} from "../../../store/actions/analyticsActions";

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import Demographics from "./demographics/demographics";
import NewMembersGraph from "./newMembersGraph/newMembersGraph";
import TopClients from "./topClients/topClients";
import Conversion from "./conversion/conversion";
import Abandoned from "./abandoned/abandoned";
import EngagementGraph from "./engagementGraph/engagementGraph";
import RetentionGraph from "./retentionGraph/retentionGraph";
import {GraphPlaceholder} from "../../graphPlaceholder/graphPlaceholder";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
// import { filter } from 'lodash';

const UserAnalytics = props => {
    // props
    const {filters, coaches} = props;
    // local
    const [date, setDate] = useState({
        start: subDays(startOfToday(), 30),
        end: new Date()
    });

    const [newMembers, setNewMembers] = useState(null);
    const [activeMembers, setActiveMembers] = useState(0);
    const [returningMembers, setReturningMembers] = useState(0);
    const [ages, setAges] = useState(null);
    const [weights, setWeights] = useState(null);
    const [heights, setHeights] = useState(null);
    const [genders, setGenders] = useState(null);
    const [newMemberData, setNewMemberData] = useState(null);
    const [topClients, setTopClients] = useState(null);
    const [conversionData, setConversionData] = useState(null);
    const [engagementSelection, setEngagementSelection] = useState('engagement');
    const [activeMemberData, setActiveMemberData] = useState(null);
    const [avgDurationData, setAvgDurationData] = useState(null);
    const [retentionData,] = useState(null);
    const [query, setQuery] = useState({
        start: date.start,
        end: date.end,
        organizationId: filters.organization,
        statusId: filters.status,
        gender: filters.gender,
        ageGroup: filters.ageGroup,
        coach: filters.coach,
    });

    // on first render
    useEffect(() => {
        runQuery();
    }, [query]);

    useDidMountEffect(() => {
        const newQuery = {
            start: date.start,
            end: date.end,
            organizationId: filters.organization,
            statusId: filters.status,
            gender: filters.gender,
            ageGroup: filters.ageGroup,
            coach: filters.coach,
            rank: filters.rank,
        };
        setQuery(newQuery);
    }, [date, filters]);

    // functions
    function runQuery() {
        getNewMembers();
        getActive();
        getAges();
        getWeights();
        getHeights();
        getGenders();
        getTopClients();
        getNewMemberData();
        getConversionData();
        getActiveMemberData();
        getAvgDurationData();
        //getRetentionData();
    }

    async function getNewMembers() {
        try {
            const members = await fetchNewMembers(query);
            setNewMembers(members);
        } catch(e) {
            console.log(e);
        }
    }

    async function getActive() {
        try {
            const members = await fetchActiveMembers(query);
            setReturningMembers(members.returning || 0)
            setActiveMembers(members.active || 0);
        } catch (e) {
            console.log(e);
        }
    }

    async function getAges() {
        try {
            const ages = await fetchAgeDemographics(query);
            setAges(ages.map(point => ({age: point.age, members: point.count})));
        } catch (e) {
            console.log(e);
        }
    }

    async function getWeights() {
        try {
            const weights = await fetchWeightDemographics(query);
            setWeights(weights.map(point => ({weight: point.weight, members: point.count})));
        } catch (e) {
            console.log(e);
        }
    }

    async function getHeights() {
        try {
            const heights = await fetchHeightDemographics(query);
            setHeights(heights.map(point => ({height: point.height, members: point.count})).filter(point => point.height > 48));
        } catch (e) {
            console.log(e);
        }
    }

    async function getGenders() {
        try {
            const genders = await fetchGenderDemographics(query);
            setGenders([
                {
                    name: 'male',
                    gender: genders.male,
                },
                {
                    name: 'female',
                    gender: genders.female,
                },
                {
                    name: 'other',
                    gender: genders.other,
                },
            ])
        } catch (e) {
            console.log(e);
        }
    }

    async function getNewMemberData() {
        try {
            const data = await fetchNewMemberGraph(query);
            setNewMemberData(data);
        } catch (e) {
            console.log(e);
        }
    }

    async function getTopClients() {
        try {
            let clients = await fetchTopClients(query);
            if (!Array.isArray(clients)) clients = [];
            setTopClients(clients);
        } catch (e) {
            console.log(e);
        }
    }

    async function getConversionData() {
        try {
            const conversions = await fetchConversionFactors(query);
            const abandonment = await fetchAbandonmentFactors(query);
            setConversionData({
                onboarding: conversions.onboarding ? conversions.onboarding : 0,
                active_not_coached: conversions.active_non_coached ? conversions.active_non_coached : 0,
                coached: conversions.active_coached ? conversions.active_coached : 0,
                abandoned: abandonment.abandoned ? abandonment.abandoned : 0,
                abandonedTotal: abandonment.total ? abandonment.total : 0,
                total: conversions.total ? conversions.total : 0,
            });
        } catch (e) {
            console.log(e);
        }
    }

    function toggleEngagement(val){
        setEngagementSelection(val);
    }

    async function getActiveMemberData() {
        try {
            const engagement = await fetchEngagementData(query);
            setActiveMemberData(engagement);
        } catch (e) {
            console.log(e);
        }
    }

    async function getAvgDurationData() {
        try {
            const sessions = await fetchSessionData(query);
            setAvgDurationData(sessions.map(session => ({date: session.date, minutes: Math.floor(session.average_session_time/60)})))
        } catch (e) {
            console.log(e);
        }
    }

    function handleDate(e, range) {
        const val = e.target.value;
        let parsedDate = parse(val, 'yyyy-MM-dd', new Date());
        if (range === 'start') {
            parsedDate = startOfDay(parsedDate);
            return setDate({...date, start: parsedDate});
        } else {
            parsedDate = endOfDay(parsedDate);
            return setDate({...date, end: parsedDate});
        }
    }

    return (
        <div>
            <h3 className={styles.heading}> User Analytics</h3>
            <form className={styles.filter}>
                <div>
                    <label>Choose Date Range</label>
                    <div className={`form-group ${styles.dateRange}`} data-testID="date-range-user">
                        <input
                            max={format(subDays(date.end, 1), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.start, 'yyyy-MM-dd', {})}
                            onChange={e => handleDate(e, 'start')}
                            onKeyDown={e => e.preventDefault()}
                        />
                        <span className='p-r:1 p-l:1'>to</span>
                        <input
                            min={format(addDays(date.start, 1), 'yyyy-MM-dd')}
                            max={format(new Date(), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.end, 'yyyy-MM-dd')}
                            onChange={e => handleDate(e, 'end')}
                            onKeyDown={e => e.preventDefault()}
                        />
                    </div>

                </div>
            </form>
            <div className={styles.newMembersRow}>
                <BoxContainer className={`${styles.boxesOuter} ${styles.newMembers}`} data-testID="new-member-tile-user">
                    { newMembers === null ?
                        <GraphPlaceholder/>
                        :
                        <>
                            <div className={styles.mainLabel}>{newMembers}</div>
                            <div className={styles.subLabel}>New Members</div>
                        </>
                    }
                </BoxContainer>
                <BoxContainer className={`${styles.boxesOuter} ${styles.activeRow}`} data-testID="engage-member-tile-user">
                    <div className={styles.heading2}>Engaged Members</div>
                    <div className={styles.activeMembers}>
                        <div className={`${styles.stats} ${styles.boxes}`}>
                            <div className={styles.mainLabel}>{activeMembers}</div>
                            <div className={styles.subLabel}>Active</div>
                        </div>
                        <div className={`${styles.stats} ${styles.boxes}`}>
                            <div className={styles.mainLabel}>{returningMembers}</div>
                            <div className={styles.subLabel}>Returning</div>
                        </div>
                        <div className={`${styles.stats} ${styles.boxes}`}>
                            <div className={styles.mainLabel}>{returningMembers ? `${Math.floor((returningMembers/activeMembers)*100)}%` : 'N/A'}</div>
                            <div className={styles.subLabel}>Stickiness</div>
                        </div>
                    </div>
                </BoxContainer>
            </div>
            <BoxContainer className={styles.demographics} data-testID="member-demographics-tile-user">
                <div className={`${styles.heading2} t-a:c`}>Engaged Member Demographics</div>
                <Demographics ageData={ages} weightData={weights} heightData={heights} genderData={genders}/>
            </BoxContainer>
            <div className={styles.newMemberGraphRow}>
                <BoxContainer className={styles.newMemberGraph} data-testID="new-member-graph-user">
                    <div className={styles.heading2}>New Members</div>
                    <div className={styles.inner}>
                        <NewMembersGraph data={newMemberData}/>
                    </div>
                </BoxContainer>
                <BoxContainer className={styles.topClients} data-testID="performing-client-user">
                    <div className={styles.heading2}>Top Preforming Clients</div>
                    <TopClients data={topClients}/>
                </BoxContainer>
            </div>
            <div className={styles.conversionRow}>
                <BoxContainer className={styles.conversion} data-testID="conversion-tile-user">
                    <div className={styles.heading2}>Conversion</div>
                    <div className={styles.inner}>
                        <Conversion data={conversionData} date={date} filters={filters} query={query} setQuery={setQuery} coaches={coaches}/>
                    </div>
                </BoxContainer>

                <BoxContainer className={styles.abandoned} data-testID="abandoment-tile-user">
                    <div className={styles.heading2}>
                        Abandonment
                        <p style={{fontSize: '14px'}} className={styles.subLabel}>(inactive for 10+ days)</p>
                    </div>
                    <div className={styles.inner}>
                        <Abandoned data={conversionData}/>
                    </div>
                </BoxContainer>
            </div>
            <div className={styles.engagementRow}>
                <BoxContainer className={styles.engagement} data-testID="engagement-tile-user">
                    <div className='margin-bottom-15'>
                        <span
                            onClick={() => toggleEngagement('engagement')}
                            className={`${styles.tab} ${engagementSelection === 'engagement' ? styles.active : ''}`}>
                            Engagement
                        </span>
                        {/*<span*/}
                        {/*    onClick={() => toggleEngagement('retention')}*/}
                        {/*    className={`${styles.tab} ${engagementSelection === 'retention' ? styles.active : ''}`}>*/}
                        {/*    Retention*/}
                        {/*</span>*/}
                    </div>
                    <div>
                        {engagementSelection === 'engagement' ? <EngagementGraph activeData={activeMemberData} durationData={avgDurationData}/> : null}
                        {engagementSelection === 'retention' ? <RetentionGraph data={retentionData}/> : null}
                    </div>
                </BoxContainer>
            </div>
        </div>
    )
}

export default UserAnalytics;