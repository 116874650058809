import React from 'react';
import { getHabitColor } from '../ProgressUtils';
import { habitProgressIcons, habitBackgroundColors } from '../ProgressConstants';
import { ReactComponent as CustomHabitIcon } from '../../../images/icons/Custom_Habit_Icon.svg';
import { DotProgress } from '../DotProgress';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from '../UserProgress.module.css';



// Daily Habit Averages Tile Component for Progress Page
export const DailyHabitAverages = ({
  habitAverages
}) => {
  const formatUnit = (unit) => {
    return unit || '';
  };

  const formatTitle = (title) => {
    const titleMap = {
      'strength building activity': 'Strength building',
      'stress releasing activity': 'Stress releasing',
    };
    return titleMap[title.toLowerCase()] || title;
  };

  // List of habits that use dots instead of progress circle
  const dotProgressHabits = ['sweetened drinks', 'sweetened foods', 'eating occasions'];
  
  // For testing: Mark some habits as custom
  const testCustomHabits = ['mealtime spacing', 'protein', 'vegetables'];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 12, marginBottom: 12 }}>
        {habitAverages.map((habit, index) => {
          if (!habit || !habit.title) return null;
          const habitTitle = habit.title.toLowerCase();
          const isSpecialHabit = ['strength building activity', 'sprint intensity', 'stress releasing activity'].includes(habitTitle);
          
          // For testing: Mark specific habits as custom
          const isCustomForTesting = testCustomHabits.includes(habitTitle);
          const isCustomHabit = habit.isCustom || isCustomForTesting;
          
          const progressIcon = (isCustomHabit && !isSpecialHabit) ? CustomHabitIcon : habitProgressIcons[habitTitle];
          const backgroundColor = habitBackgroundColors[habitTitle] || '#eaecf1'; // greyLight2
          const useDotProgress = dotProgressHabits.includes(habitTitle);
          
          return (
            <div key={index} style={{ 
              backgroundColor: '#ffffff', 
              borderRadius: 12, 
              padding: 10, 
              position: 'relative', 
              width: 'calc(33.33% - 8px)', 
              height: 100, 
              overflow: 'hidden' 
            }}
            className={isCustomHabit ? styles.customHabitTile : ''}
            >
              <div style={{ 
                position: 'absolute',
                right: -5,
                top: -5,
                width: 37,
                height: 37,
                borderRadius: 18,
                backgroundColor
              }} />
              <div style={{
                position: 'absolute',
                right: habitTitle === 'sweetened drinks' ? '-5px' : '-5px',
                top: habitTitle === 'sweetened drinks' ? '-3px' : '-3px',
                width: 36,
                height: 36,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 2
              }}>
                {React.createElement(progressIcon || CustomHabitIcon)}
              </div>
              <div className={styles.habitTitleContainer}>
                {formatTitle(habit.title)}
              </div>

              {useDotProgress ? (
                <DotProgress 
                  value={habit.average_value} 
                  unit={formatUnit(habit.unit, habit.title)}
                  habitTitle={habit.title}
                />
              ) : (!isCustomHabit || isSpecialHabit) ? (
                <div style={{
                  position: 'absolute',
                  bottom: 12,
                  left: 12,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 4
                }}>
                  <div className={styles.habitProgressWrapper}>
                    <CircularProgressbar
                      value={habit.progress || 0}
                      strokeWidth={12}
                      styles={{
                        root: {
                          width: '100%',
                          height: '100%'
                        },
                        path: {
                          stroke: getHabitColor(habit.title),
                          strokeLinecap: 'round',
                          transition: 'stroke-dashoffset 1s ease-in-out',
                        },
                        trail: {
                          stroke: '#777777',
                          opacity: 0.2,
                          strokeWidth: 10,
                        },
                      }}
                    />
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <span style={{ 
                        color: getHabitColor(habit.title),
                        fontWeight: 600,
                        fontSize: 12
                      }}>
                        {(habit.average_value || 0).toFixed(1)}
                      </span>
                    </div>
                  </div>
                  {(!isCustomHabit || isSpecialHabit || isCustomHabit) && !useDotProgress && (
                    <div style={{ marginLeft: 4 }}>
                      <span className={styles.habitUnit} style={{ 
                        color: getHabitColor(habit.title),
                        fontWeight: 500,
                        lineHeight: '1.2',
                        display: 'inline-block'
                      }}>
                        {formatUnit(habit.unit, habit.title)}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.customHabitValueContainer}>
                  <div className={styles.customHabitValueWrapper}>
                    <span className={styles.customHabitValue}>
                      {(habit.average_value || 0).toFixed(1)}
                    </span>
                    <span className={styles.customHabitUnit}>
                      {formatUnit(habit.unit, habit.title)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
