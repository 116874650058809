import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";

// styles
import styles from './privacyAndSecurity.module.css';

// actions
import {fetchSelf} from "../../actions/action_user";
import {disableMFA} from "../../store/actions/authActions";
import {updatePassword} from '../../store/actions/userActions';

// images and icons
import {ReactComponent as LockIcon} from '../../images/lock_icon.svg';
import {ReactComponent as PasswordIcon} from '../../images/password_lock.svg';
import {ReactComponent as QRIcon} from '../../images/qr_code.svg';
import {ReactComponent as TextBubbleIcon} from '../../images/text_bubble.svg';

// components
import ClientHeader from "../clientHeader/clientHeader";
import {BasicButton, PrimaryButton} from "../buttons/buttons";
import {Modal} from "react-bootstrap";
import PasswordEyeInput from "../passwordEyeInput/passwordEyeInput";
import {eraseCookie} from "../../helpers/cookies";

const PrivacyAndSecurity = props => {
    // state
    const {user} = props;
    // actions
    const {fetchSelf} = props;
    // local
    const [mfaClicked, setMfaClicked] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordChangeError, setPasswordChangeError] = useState('');
    const [passwordModal, setPasswordModal] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordViewOpen, setPasswordViewOpen] = useState(false);
    const [mfaRequiredModal, setMfaRequiredModal] = useState(!user.mfa_enabled && user.organization.mfa_required);

    // get user details on load
    useEffect(() => {
        fetchSelf();
    }, [])

    function methodPress(method) {
        setMfaClicked(false);
        props.history.push('/member/mfa-setup?method=' + method);
    }

    async function submitDisable() {
        try {
            const result = await disableMFA(password);
            if (result.error) {
                setPasswordError(true);
            } else {
                eraseCookie(`${user.email}_challengeToken`);
                fetchSelf();
                setPasswordModal(false);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function changePassword(e) {
        e.preventDefault();
        // confirm password is at least 8 characters
        if (newPassword.length < 8) {
            return setPasswordChangeError('Password must be at least 8 characters');
        }
        // verify passwords are the same
        if (newPassword !== confirmPassword) {
            return setPasswordChangeError('Passwords do not match');
        }

        setPasswordChangeError('');
        try {
            await updatePassword(password, newPassword, confirmPassword);
            setPasswordViewOpen(false);
            setPasswordChangeError('');
            setPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch(err) {
            if (err.response && err.response.data) {
                if (typeof err.response.data.error === 'string') {
                    setPasswordChangeError(err.response.data.error);
                } else {
                    setPasswordChangeError('Unable to update password');
                }
            }
        }
    }

    function resolveMFAView() {
        if (user.mfa_enabled) {
            return (
                <PrimaryButton onClick={() => setPasswordModal(true)}>Disable MFA</PrimaryButton>
            )
        }
        if (mfaClicked) {
            return <MfaMethodList close={() => setMfaClicked(false)}/>
        }
        return (
            <PrimaryButton onClick={() => setMfaClicked(true)}>Setup Now</PrimaryButton>
        )
    }

    function MfaMethodList({close}) {
        return (
            <div className={styles.mfaMethodContainer}>
                <div onClick={() => methodPress('qr_code')}>
                    <div className={styles.mfaMethod}>
                        <QRIcon fill={'#ccc'} width={30} height={30}/>
                        <div className={styles.mfaMethodText}>
                            <p className={styles.mfaHeading}>Authenticator App</p>
                            <p>Use an authenticator app to get 2FA code</p>
                        </div>
                    </div>
                </div>
                <div onClick={() => methodPress('text_message')}>
                    <div className={styles.mfaMethod}>
                        <TextBubbleIcon fill={'#ccc'} width={30} height={20}/>
                        <div className={styles.mfaMethodText}>
                            <p className={styles.mfaHeading}>Text Messaging</p>
                            <p>Receive a one time text message code at login</p>
                        </div>
                    </div>
                </div>
                <div onClick={() => methodPress('email')}>
                    <div className={styles.mfaMethod}>
                        <i
                            style={{color: '#ccc', fontSize: '30px'}}
                            className="fa fa-envelope fa-lg"
                        />
                        <div className={styles.mfaMethodText}>
                            <p className={styles.mfaHeading}>Email</p>
                            <p>Treo will send you an email when you login</p>
                        </div>
                    </div>
                </div>
                <PrimaryButton onClick={close}>Close</PrimaryButton>
            </div>
        )
    }

    function closePasswordView() {
        setPasswordViewOpen(false);
        setPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setPasswordChangeError('');
    }

    function resolvePasswordView() {
        if (passwordViewOpen) {
            return (
                <form onSubmit={changePassword} className={styles.passwordContainer}>
                    <div>
                        <label htmlFor="currentPassword">Enter current password:</label>
                        <PasswordEyeInput id='currentPassword' value={password} onChange={val => setPassword(val)}/>
                    </div>
                    <div>
                        <label htmlFor="newPassword">Enter New password:</label>
                        <PasswordEyeInput id='newPassword' value={newPassword} onChange={val => setNewPassword(val)}/>
                    </div>
                    <div>
                        <label htmlFor="confirmPassword">Confirm new password:</label>
                        <PasswordEyeInput id='confirmPassword' value={confirmPassword}
                                          onChange={val => setConfirmPassword(val)}/>
                    </div>
                    <div className={styles.passwordContainerButtons}>
                        <BasicButton type='button' onClick={closePasswordView}>Cancel</BasicButton>
                        <PrimaryButton type='submit' onClick={changePassword}>Submit</PrimaryButton>
                    </div>
                </form>
            )

        } else {
            return (<PrimaryButton onClick={() => setPasswordViewOpen(true)}>Update</PrimaryButton>)
        }
    }

    return (
        <div className="client-container">
            <div>
                <ClientHeader bypassRedirect={true} backUrl={`/member/clientDash/${user.username}`}/>
            </div>
            <div className={`container ${styles.cardContainer}`}>
                <div className={styles.card}>
                    <div className={styles.top}>
                        <LockIcon className={styles.icon} width={28} height={28}/>
                        <div>
                            <p className={styles.title}>Multi-Factor Authentication</p>
                            <p className={styles.subtitle}>Secure your login by adding Multi-Factor Authentication</p>
                        </div>
                    </div>
                    <div className={styles.separator}/>
                    <div>
                        {resolveMFAView()}
                    </div>
                </div>

                <div className={styles.card}>
                    <div className={styles.top}>
                        <PasswordIcon className={styles.icon} width={38} height={38}/>
                        <div>
                            <p className={styles.title}>Password</p>
                            <p className={styles.subtitle}>Update your password frequently to stay secure</p>
                        </div>
                    </div>
                    <div className={styles.separator}/>
                    <div>
                        {passwordChangeError ? <p className='text-danger'>{passwordChangeError}</p> : null}
                        {resolvePasswordView()}
                    </div>
                </div>
            </div>

            <Modal show={passwordModal} onHide={() => setPasswordModal(false)}>
                <div className={styles.passwordModal}>
                    <h4 className='no-margin'>Confirm Password</h4>
                    <p className='no-margin'>We need to confirm your identity before disabling multi-factor authentication.</p>
                    <div>
                        <PasswordEyeInput type="password" value={password} onChange={val => setPassword(val)}/>
                        {passwordError ? <p class="no-margin text-danger">Invalid Password</p> : null}
                    </div>
                    <div className={styles.passwordModalButtons}>
                    <PrimaryButton onClick={submitDisable}>Confirm</PrimaryButton>
                        <BasicButton onClick={() => {setPassword(''); setPasswordModal(false)}}>Cancel</BasicButton>
                    </div>
                </div>
            </Modal>

            <Modal show={mfaRequiredModal} onHide={() => setMfaRequiredModal(false)}>
                <div className={styles.passwordModal}>
                    <h4 className='no-margin'>Multi-Factor Authentication Required</h4>
                    <p className='no-margin'>Multi-Factor authentication has been required by your Treo Provider and must be setup before you can continue.</p>
                    <div className={styles.passwordModalButtons}>
                        <MfaMethodList close={() => setMfaRequiredModal(false)} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user,
        scoreHistory: state.scoreHistory,
        dailyScore: state.dailyScore
    }
}

const actions = {
    fetchSelf,
}

export default withRouter(connect(mapStateToProps, actions)(PrivacyAndSecurity));