// OrganizationPortalSideMenu.js
import React, { useState } from "react";
import styles from "./OrganizationPortalSideMenu.module.css";

/**
 * Props:
 * - items: an array of objects with properties:
 *     - id: unique identifier for the panel (e.g., "orgInfo")
 *     - label: the text to display (e.g., "Organization Info")
 *     - error: (optional) boolean flag to indicate an error (shows a red dot)
 * - initialActive: (optional) id of the panel that should be active initially
 * - onSelect: a callback function that receives the selected id when a menu item is clicked
 */
const OrganizationPortalSideMenu = ({ items, initialActive, onSelect }) => {
  const [activeItem, setActiveItem] = useState(
    initialActive || (items.length > 0 ? items[0].id : null)
  );

  const handleClick = (id) => {
    setActiveItem(id);
    if (onSelect) {
      onSelect(id);
    }
  };

  return (
    <div className={styles.listMenu}>
      <ul className={styles.list}>
        {items.map((item) => (
          <li
            key={item.id}
            className={`${styles.listItem} ${
              activeItem === item.id ? styles.active : ""
            }`}
            onClick={() => handleClick(item.id)}
          >
            {item.label}
            {item.error && <span className={styles.errorCircle}></span>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrganizationPortalSideMenu;
