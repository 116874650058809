/**
 * Fetches the portal customer organization id
 */
export const fetchPortalCustomerOrganizationId = async (portalApiMap) => {
    try {
      const { getPortalCustomerOrganizationId } = await portalApiMap();
      const response = await getPortalCustomerOrganizationId();
      return response.data.results // Return the response for further use
    } catch (err) {
      console.error("Error fetching account data:", err);
      throw err; // Rethrow the error for handling in the caller
    }
  };



  export const fetchPortalHeaderInfo = async (portalApiMap) => {
    try {
      const { getPortalCustomerUserInfo } = await portalApiMap();
      const response = await getPortalCustomerUserInfo();
      return response.data.results// Return the response for further use
    } catch (err) {
      console.error("Error fetching account data:", err);
      throw err; // Rethrow the error for handling in the caller
    }
  };