
// Habit Icons
export const StepsIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Habit_Icon%3DSteps.png';

// Health Goals
export const BetterBloodPressureIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Health%20Goal%3DBetter%20Blood%20Pressure.png';
export const BetterBloodSugarIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Health%20Goal%3DBetter%20Blood%20Sugar.png';
export const HealthierFoodChoicesIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Health%20Goal%3DHealthier%20Food%20Choices.png';
export const HeartBrainHealthIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Health%20Goal%3DHeart%20&%20Brain%20Health.png';
export const LastingWeightLossIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Health%20Goal%3DLasting%20Weight%20Loss.png';
export const MakeActivityHabitIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Health%20Goal%3DMake%20Activity%20a%20Habit.png';
export const MoreEnergyLessStressIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Health%20Goal%3DMore%20Energy%20Less%20Stress.png';
export const StrongerLeanerBodyIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Health%20Goal%3DStronger%20Leaner%20Body.png';

// Personal Progress
export const LifetimeScoreIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Lifestime%20Score.png';
export const BonusPointsIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/PersonalProgress_Bonus%20Points.png';
export const CalendarIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/PersonalProgress_Calendar.png';
export const MonthlyActivityIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/PersonalProgressTile%3DMonthlyActivity.png';
export const MonthlyStreakIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/PersonalProgressTile%3DMonthlyStreak.png';
export const MonthlyVictoryIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/PersonalProgressTile%3DMonthlyVictory.png';
export const AllTimeRankIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/PersonalProgresstTile%3DAllTimeRank.png';
export const MonthlyBadgeIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/PersonalProgresstTile%3DMonthlyBadge.png';
