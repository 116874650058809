import React, {useState, useEffect} from 'react';

// styles
import styles from './puzzleChallenge.module.css';

// images & icons
import {ReactComponent as ArrowIcon} from '../../images/blue_arrow.svg';
import {ReactComponent as RotateBackIcon} from '../../images/rotate_back.svg';
import {ReactComponent as RotateForwardIcon} from '../../images/rotate_forward.svg';
// components
import {PrimaryButton} from "../buttons/buttons";

const PuzzleChallenge = ({close, update, callback}) => {
    // local
    const [rotation, setRotation] = useState(Math.floor(Math.random() * ((270 - 90) / 10 + 1)) * 10 + 90);
    const [warning, setWarning] = useState('');

    useEffect(() => {
        update(Math.abs(rotation%360));
    }, [rotation]);

    function rotate(val) {
        setRotation(prev => prev + val);
    }

    function submit() {
        if (Math.abs(rotation%360)) return setWarning("You don't appear to be human. Please try again.");
        callback();
        close();
    }

    return (
        <div className={styles.container}>
            <h3 className={styles.heading}>Let's make sure you're human</h3>
            <p className={styles.subheading}>Rotate the images until they align</p>
            {warning ? <p className='text-danger'>{warning}</p> : null}
            <div className={styles.puzzle}>
                <ArrowIcon className={styles.image1}/>
                <ArrowIcon style={{transform: `rotate(${rotation}deg)`}} className={styles.image2}/>
            </div>
            <div className={styles.buttons}>
                <button onClick={() => rotate(-10)}><RotateBackIcon/><span>Rotate</span></button>
                <button onClick={() => rotate(10)}><span>Rotate</span><RotateForwardIcon/></button>
            </div>
            <PrimaryButton onClick={submit}>Submit</PrimaryButton>
        </div>
    )
}

export default PuzzleChallenge;