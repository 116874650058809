import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

// styles
import styles from './backupCodes.module.css';

// actions
import {login} from "../../../store/actions/authActions";

// images & icons

// components
import {BasicButton, PrimaryButton} from "../../buttons/buttons";
import {STATUSES} from "../../../serverVariables/statuses";

const BackupCodes = props => {
    //props
    const {history, location} = props;
    // actions
    const {login} = props;
    // state
    const {multifactor, appState, user} = props;
    // local
    const [code, setCode] = useState("");
    const [disableLogin, setDisableLogin] = useState(false);
    const [loginError, setLoginError] = useState('');

    // forward a user to the appropriate place
    useEffect(() => {
        if (appState.appReady && !appState.isLoggedIn && location && location.state && location.state.from && location.state.from.search) {
            history.push({search: location.state.from.search});
        }
        if (location.state && window.location.href.lastIndexOf('localhost') > 0) return;
        if (user && appState.isLoggedIn && appState.appReady) {
            if(user.permission_id === 1){
                if(user.user_status_id < STATUSES.onboarding_coach_consult_pending){
                    //client is in onboarding
                    history.push({
                        pathname : `/member/onboard-assessment`,
                    });
                }else{
                    // Forward to dashboard
                    let url = { pathname: `/member/clientDash/${user.username}` };

                    if (location.state && location.state.from && location.state.from.search){
                        url.search = location.state.from.search;
                    }

                    if (location.state && location.state.from) {
                        const fromPathname = location.state.from.pathname;

                        // Check if the URL matches /account/notifications
                        const pattern = /.*\/account\/notifications/gm;
                        const isNotifications = pattern.test(fromPathname);

                        // Check if the URL matches /member/clientDash/{user.email}/assessments
                        const patternAssessment = /\/member\/clientDash\/[^/]+\/assessments/;
                        const isAssessments = patternAssessment.test(fromPathname);

                        if (isNotifications) {
                            url = location.state.from;
                        } else if (isAssessments) {
                            url = location.state.from;
                        }
                    }
                    history.push(url);
                }
            }else if(user.permission_id === 2){
                history.push({
                    pathname: '/dashboard/analytics/user'
                });
            }else{
                history.push({
                    pathname:'/dashboard/analytics/user'
                });
            }
        }
    }, [ appState ]);

    useEffect(() => {
        document.body.style.background = '#fff';
        return () => document.body.style.background = null;
    },[]);

    function submit(e) {
        e.preventDefault();
        try {
            setDisableLogin(true);
            setLoginError('');
            login({email: multifactor.email, password: multifactor.password, backup: code})
                .catch(e => {
                    if (e.response.data.error) {
                        setLoginError(e.response.data.error);
                        setDisableLogin(false);
                    }
                });
        } catch (e) {
            console.error('invalid');
        }
    }

    return (
        <div className={styles.main}>
            <div className={styles.green}>
                <img className={styles.logo} src="https://treo.s3.us-east-2.amazonaws.com/assets/Logo_White.png"
                     alt="Logo"/>
                <div className={styles.headingContainer}>
                    <h1 className={styles.heading1}>Change is Possible with Treo Wellness</h1>
                    <h2 className={styles.heading2}>We are your whole-person wellbeing app designed to help you discover
                        your healthy lifestyle. Get started today!</h2>
                </div>
            </div>
            <div className={styles.white}>
                <div className={styles.formSelector}>
                    <form onSubmit={submit} className={styles.formContainer}>
                        <div className={styles.form}>
                            <div>
                                <p className={styles.formTitle}>Two-Step Verification</p>
                                <p>If you have lost your MFA device please enter one of the one time codes that was given to you when you setup you device authentication.</p>
                            </div>
                            <div>
                                <label htmlFor="email">One Time Password</label>
                                <input type="text" id='code' name='code' value={code}
                                       onChange={e => setCode(e.target.value)}/>
                                {loginError ? <span className={'text-danger'}>{loginError}</span> : null}
                            </div>
                            <div>
                                <div className={styles.formSubmit}>
                                    <BasicButton type='button' onClick={() => history.push('/mfa-code-verification')}>Back</BasicButton>
                                    <PrimaryButton disabled={disableLogin} type='submit' onClick={submit}>Submit</PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps({multifactor, appState, user}) {
    return {multifactor, appState, user}
}

const actions = {
    login,
};

export default withRouter(connect(mapStateToProps, actions)(BackupCodes));