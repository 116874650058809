// PortalLayout.js
import React, { Suspense, lazy, useEffect, useState } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

// components
import Sidebar from "../sidebar.js/Sidebar";
import PortalHeader from "../portalHeader/PortalHeader";
import Loading from "../Loading/Loading";

// routes
import {
  fetchPortalCustomerOrganizationId,
  fetchPortalHeaderInfo,
} from "./routes/PortalLayoutRoutes";

// redux
import { portalApiMap } from "../../store/actions/portalAction";
import { connect } from "react-redux";

// styles
import styles from "./PortalLayout.module.css";

import avatar from "../../images/avatar-1577909_960_720.png";
import PortalBadgeTable from "../portalDashboard/screens/PortalBadgeTable";
import PortalDashboardAnalytics from "../portalDashboard/screens/PortalDashboardAnalytics";
import PortalDashboardOverview from "../portalDashboard/screens/PortalDashboardOverview";
import PortalContent from "../portalDashboard/screens/PortalContent";


const SupportRequest = lazy(() => import("../supportRequest/SupportRequest"));
const ResourcesLayout = lazy(() =>
  import("../resourcesLayout/ResourcesLayout")
);
const AccountLayout = lazy(() => import("../accountLayout/AccountLayout"));

const PortalLayout = ({ match, location, portalApiMap }) => {
  const [organizationId, setOrganizationId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [headerInfo, setHeaderInfo] = useState(null);

  const getPageTitle = () => {
    const path = location.pathname.replace(match.path, ""); // Remove the base path
    if (path.startsWith("/dashboard")) return "Your Dashboard";
    if (path.startsWith("/support-request")) return "Support Request";
    if (path.startsWith("/resources")) return "Resources";
    if (path.startsWith("/account")) return "Account Details";
    return "Dashboard"; // Default title
  };

  const fetchOrganizationId = async () => {
    try {
      const response = await fetchPortalCustomerOrganizationId(portalApiMap);
      setOrganizationId(response.organization_id);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching organization data:", err);
      setError(err);
      setLoading(false);
    }
  };

  const fetchPortalHeaderInformation = async () => {
    try {
      const response = await fetchPortalHeaderInfo(portalApiMap);
      setHeaderInfo(response);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching header info:", err);
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPortalHeaderInformation();
    fetchOrganizationId();
  }, [portalApiMap]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error fetching organization data: {error.message}</div>;
  }

  const companyName =
    headerInfo && headerInfo.organization
      ? headerInfo.organization
      : "Default Organization";
  const firstName =
    headerInfo && headerInfo.first_name ? headerInfo.first_name : "";
  const lastName =
    headerInfo && headerInfo.last_name ? headerInfo.last_name : "";
  const userName = (firstName + " " + lastName).trim() || "User";

  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.sidebarRightContainer}>
        <PortalHeader
          companyName={companyName}
          pageTitle={getPageTitle()}
          userName={userName}
          userRole={""}
          userImage={avatar}
        />
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route
              exact
              path={`${match.path}/dashboard/activity`}
              render={(props) => <PortalDashboardAnalytics {...props}  organizationId={organizationId} />}
            />

            <Route
              exact
              path={`${match.path}/dashboard/overview`}
              render={(props) => <PortalDashboardOverview {...props} />}
            />


          <Route
              exact
              path={`${match.path}/dashboard/content`}
              render={(props) => <PortalContent {...props}  organizationId={organizationId} />}
            />
            <Route
              exact
              path={`${match.path}/dashboard/reports`}
              render={(props) => <PortalBadgeTable {...props} organizationId={organizationId} />}
            />

            <Route
              path={`${match.path}/support-request`}
              render={(props) => <SupportRequest {...props} />}
            />
            <Route
              path={`${match.path}/resources`}
              render={(props) => (
                <ResourcesLayout {...props} organizationId={organizationId} />
              )}
            />
            <Route
              path={`${match.path}/account`}
              render={(props) => <AccountLayout {...props} />}
            />
            <Redirect to={`${match.path}/dashboard/overview`} />
          </Switch>
        </Suspense>
      </div>
    </div>
  );
};

const actions = { portalApiMap };

export default withRouter(connect(null, actions)(PortalLayout));
