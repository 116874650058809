import React, { useState, useEffect, Suspense, lazy } from "react";

// routes
import {
  fetchPortalCustomerOnboardingStats,
  fetchPortalCustomerGenderStats,
  fetchPortalCustomerAgeStats,
  fetchPortalCustomerAdoptionStats,
} from "./../routes/DashboardPortalRoutes";
// redux
import { portalApiMap } from "../../../store/actions/portalAction";
import { connect } from "react-redux";

// styles
import styles from "./../PortalDashboard.module.css";

// Components
import Loading from "../../Loading/Loading";

const PortalProgressCard = lazy(() => import("./../components/PortalProgressCard"));
const DashboardOnboardingAssessmentCard = lazy(() =>
  import("./../components/DashboardOnboardingAssessmentCard")
);
const GenderStatsChart = lazy(() => import("./../components/GenderStatsChart"));
const AverageAge = lazy(() => import("./../components/AverageAge"));

const PortalDashboard = ({ portalApiMap }) => {
  const [onboardingStatsData, setOnboardingStatsData] = useState(null);
  const [genderData, setGenderData] = useState([]);
  const [ageStatsData, setAgeStatsData] = useState([]);
  const [adoptionStatsData, setAdoptionStatsData] = useState(null);

  // Global loading and error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOtherData = async () => {
      setLoading(true);
      setError(null);
      try {
        const [
          onboardingStats,
          genderStats,
          ageStats,
          adoptionStats,
        ] = await Promise.all([
          fetchPortalCustomerOnboardingStats(portalApiMap),
          fetchPortalCustomerGenderStats(portalApiMap),
          fetchPortalCustomerAgeStats(portalApiMap),
          fetchPortalCustomerAdoptionStats(portalApiMap),
        ]);
        setOnboardingStatsData(onboardingStats);
        setGenderData(genderStats);
        setAgeStatsData(ageStats);
        setAdoptionStatsData(adoptionStats);
      } catch (err) {
        console.error("Error fetching other dashboard data:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchOtherData();
  }, [portalApiMap]);

  return (
    <Suspense fallback={<div><Loading /></div>}>
    {loading ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <Loading />
        </div>
      ) : error ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          Error: {error.message || "Error loading data"}
        </div>
      ) : (
        <>
          <div className={styles.container}>
            <DashboardOnboardingAssessmentCard
              onboardingStats={onboardingStatsData}
              loading={loading}
              error={error}
            />
            <GenderStatsChart data={genderData} loading={loading} error={error} />
            <AverageAge data={ageStatsData} loading={loading} error={error} />
            <PortalProgressCard
              title="Adoption"
              data={adoptionStatsData}
              loading={loading}
              error={error}
            />
          </div>
        </>
      )}
    </Suspense>
  );
};

const actions = { portalApiMap };

export default connect(null, actions)(PortalDashboard);
