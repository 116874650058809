import React from "react";

// styles
import styles from "./planDetails.module.css";

// components
import { PrimaryButton } from "../../../../buttons/buttons";
import SkeletonText from "../../components/Loading/SkeletonText";

// screens
import ClientPlanOptionForm from "../paymentDetails/ClientPlanOptionForm";

// date utilities
import { formatDate } from "../../utils/dateUtils";

const ClientMemberShipInformation = (props) => {
  const {
    setShowPaymentInfo,
    showPaymentInfo,
    stripePlanInformation,
    setSelectedOption,
    selectedOption,
    handleManageSubscription,
  } = props;
  
  const {
    plan = null,
    next_payment = null,
    cancel_at_period_end = false,
    cancel_at = null,
  } = stripePlanInformation || {};

  // Determine if the plan information is valid
  const isValidPlan =
    plan && typeof plan === "string" && plan.trim().length > 0;
  const isYearly = isValidPlan ? plan.toLowerCase().includes("year") : false;

  // Set renewal term and current plan based on the plan value
  const renewalTerm = isValidPlan ? plan : null;
  const currentPlan = isValidPlan ? (isYearly ? "Yearly" : "Monthly") : null;

  // Determine whether to show renewal date or subscription end date
  const endOrRenewalDateLabel =
    cancel_at_period_end && cancel_at ? "Subscription Ends:" : "Renewal Date:";
  const endOrRenewalDateValue =
    cancel_at_period_end && cancel_at ? cancel_at : next_payment;

  // Helper function to render SkeletonText or actual content
  const renderContent = (content) => {
    if (!content || (typeof content === "string" && content.trim() === "")) {
      return (
        <SkeletonText
          width="70%"
          height="1.2em"
          className={styles.skeletonMargin}
        />
      );
    }
    return content;
  };

  // Helper function to render date or SkeletonText
  const renderDate = (dateValue) => {
    const formattedDate = formatDate(dateValue);
    if (formattedDate === "Invalid Date" || !dateValue) {
      return (
        <SkeletonText
          width="70%"
          height="1.2em"
          className={styles.skeletonMargin}
        />
      );
    }
    return formattedDate;
  };
  return (
    <div>
      {!showPaymentInfo ? (
        <>
          <h3 className={styles.membershipTitle}>Membership Details</h3>
          <ul className={styles.memberShipListContainer}>
            <li>
              <span className={styles.label}>Current Plan:</span>
              <span className={styles.value}>{renderContent(currentPlan)}</span>
            </li>
            <li>
              <span className={styles.label}>Renewal Term:</span>
              <span className={styles.value}>{renderContent(renewalTerm)}</span>
            </li>
            <li>
              <span className={styles.label}>{endOrRenewalDateLabel}</span>
              <span className={styles.value}>
                {renderDate(endOrRenewalDateValue)}
              </span>
            </li>
          </ul>
        </>
      ) : (
        <ClientPlanOptionForm
          handleManageSubscription={handleManageSubscription}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          stripePlanInformation={stripePlanInformation}
          showPaymentInfo={showPaymentInfo}
          setShowPaymentInfo={setShowPaymentInfo}
          paymentDate={endOrRenewalDateValue}
        />
      )}

      <div className={styles.btnContainer}>
        {!showPaymentInfo && (
          <PrimaryButton
            className={styles.manageButton}
            onClick={() => {
              setShowPaymentInfo(!showPaymentInfo);
            }}
            data-testID="manage-subscription-button"
          >
            Manage Subscription
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export default ClientMemberShipInformation;
