import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import treoIcon from "../Admin/header-logo.svg";
import styles from "./Sidebar.module.css";
import { logout } from "../../store/actions/authActions";
import { connect } from "react-redux";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import portalRectangle from "../../images/portalRectangle.png";
import portalMessage from "../../images/PortalMessage.png";
import portalFourRectangle from "../../images/PortalFourRectangle.png";
import portalAccount from "../../images/portalAccount.png";
import logoutIcon from "../../images/portalLogout.png";

const Sidebar = (props) => {
  const { logout, location } = props;
  const [isOpen, setIsOpen] = useState(false); // Controls mobile sidebar
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Controls the dashboard dropdown
  const [isDashboardDropdownOpen, setDashboardDropdownOpen] = useState(false);

  // Determine if we're on any "/portal/dashboard" route
  const isDashboardRoute = location.pathname.includes("/portal/dashboard");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
      if (window.innerWidth >= 1000) setIsOpen(false);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    try {
      logout().then(() => window.location.assign("/"));
    } catch (err) {
      console.log(err);
    }
  };

  // Close sidebar on link click in mobile mode
  const handleLinkClick = () => {
    if (isMobile) {
      setIsOpen(false);
    }
  };

  // Toggle the dashboard dropdown manually
  const toggleDashboardDropdown = () => {
    setDashboardDropdownOpen((prev) => !prev);
  };

  // Automatically open the dashboard dropdown if we are on a dashboard route
  useEffect(() => {
    setDashboardDropdownOpen(isDashboardRoute);
  }, [isDashboardRoute]);

  return (
    <>
      {/* Hamburger Button for Mobile */}
      {isMobile && (
        <button className={styles.hamburger} onClick={() => setIsOpen(!isOpen)}>
          ☰
        </button>
      )}

      <div
        className={`${styles.sidebarContainer} ${
          isMobile && isOpen ? styles.showSidebar : ""
        }`}
      >
        {/* Logo Area */}
        <div className={styles.sidebarLogo}>
          <img
            src={treoIcon}
            alt="Treo Wellness Logo"
            className={styles.sidebarLogoIcon}
          />
        </div>

        {/* Navigation Links */}
        <ul className={styles.sidebarLinks}>
          {/* Dashboard Dropdown */}
          <li className={styles.sidebarLinkItem}>
            {/* Top-level Dashboard link */}
            <div
              className={`${styles.sidebarLink} ${
                isDashboardRoute
                  ? styles.current
                  : isDashboardDropdownOpen
                  ? styles.dashboardHovered
                  : ""
              }`}
              onClick={toggleDashboardDropdown}
            >
              <img
                src={portalRectangle}
                width={32}
                height={32}
                alt="Dashboard"
              />
              <p className={styles.sidebarLinkText}>Dashboard</p>
              <span className={styles.dropdownArrow}>
                {/* Render both arrow icons and crossfade via CSS */}
                <span
                  className={
                    isDashboardDropdownOpen
                      ? styles.arrowUpVisible
                      : styles.arrowUpHidden
                  }
                >
                  <IoIosArrowUp size={21} />
                </span>
                <span
                  className={
                    !isDashboardDropdownOpen
                      ? styles.arrowDownVisible
                      : styles.arrowDownHidden
                  }
                >
                  <IoIosArrowDown size={21} />
                </span>
              </span>
            </div>

            {/* Sub-menu */}
            <ul
              className={`${styles.dropdownMenu} ${
                isDashboardDropdownOpen ? styles.open : ""
              }`}
            >
                     <li>
                <NavLink
                  to="/portal/dashboard/overview"
                  activeClassName={styles.dropdownCurrent}
                  className={styles.dropdownItem}
                  onClick={handleLinkClick}
                >
                  Overview
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/portal/dashboard/activity"
                  activeClassName={styles.dropdownCurrent}
                  className={styles.dropdownItem}
                  onClick={handleLinkClick}
                >
                  Activity
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/portal/dashboard/content"
                  activeClassName={styles.dropdownCurrent}
                  className={styles.dropdownItem}
                  onClick={handleLinkClick}
                >
                  Content
                </NavLink>
              </li>
       
              <li>
                <NavLink
                  to="/portal/dashboard/reports"
                  activeClassName={styles.dropdownCurrent}
                  className={styles.dropdownItem}
                  onClick={handleLinkClick}
                >
                Achievements
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Other main links */}
          <li className={styles.sidebarLinkItem}>
            <NavLink
              to="/portal/support-request"
              activeClassName={styles.current}
              className={styles.sidebarLink}
              onClick={handleLinkClick}
            >
              <img
                src={portalMessage}
                width={32}
                height={32}
                alt="Support Request"
              />
              <p className={styles.sidebarLinkText}>Support Request</p>
            </NavLink>
          </li>

          <li className={styles.sidebarLinkItem}>
            <NavLink
              to="/portal/resources"
              activeClassName={styles.current}
              className={styles.sidebarLink}
              onClick={handleLinkClick}
            >
              <img
                src={portalFourRectangle}
                width={32}
                height={32}
                alt="Resources"
              />
              <p className={styles.sidebarLinkText}>Resources</p>
            </NavLink>
          </li>

          <li className={styles.sidebarLinkItem}>
            <NavLink
              to="/portal/account"
              activeClassName={styles.current}
              className={styles.sidebarLink}
              onClick={handleLinkClick}
            >
              <img src={portalAccount} width={28} height={28} alt="Account" />
              <p className={styles.sidebarLinkText}>Account</p>
            </NavLink>
          </li>
        </ul>

        {/* Footer / Log Out */}
        <div className={styles.sidebarLogout} onClick={handleLogout}>
          <img src={logoutIcon} width={28} height={32} alt="Log Out" />
          <p className={styles.sidebarLinkText}>Log Out</p>
        </div>
      </div>
    </>
  );
};

const actions = { logout };

export default withRouter(connect(null, actions)(Sidebar));
