import React from 'react';
import styles from './PortalPagination.module.css';
import prevIcon from "../../images/portalPaginationPrevIcon.png";
import nextIcon from "../../images/portalPaginationNextIcon.png";

const PortalPagination = ({ currentPage, totalPages, handlePageChange }) => {
  return (
    <div className={styles.pagination}>
      <button
        className={styles.prevButton}
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <img src={prevIcon} alt="previous" />
        {"Prev"}
      </button>
      <div className={styles.pgBtnContainer}>
        {Array.from({ length: totalPages }, (_, index) => index + 1).map(
          (page) => (
            <button
              key={page}
              className={currentPage === page ? styles.activePage : ""}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          )
        )}
      </div>
      <button
        className={styles.prevButton}
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {"Next"}
        <img src={nextIcon} alt="Next" />
      </button>
    </div>
  );
};

export default PortalPagination;
