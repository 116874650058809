import React from "react";
import styles from "./InputField.module.css";

const InputField = ({
  label,
  icon,           // Optional icon (e.g., <img src="..." alt="icon" />)
  required = false, // Determines if the field is required
  name,
  value,
  onChange,
  placeholder,
  type = "text",
  options = [],
  error,          // Error message if any
  readOnly = false,
  labelStyle = {},  // Custom styles for the label
  inputStyle = {},  // Custom styles for the input field
}) => {
  return (
    <div className={styles.inputContainer}>
      <div className={styles.labelErrorWrapper}>
        {label && (
          <label
            className={`${styles.label} ${required ? styles.required : ""}`}
            style={labelStyle} // Apply custom label styles
          >
            <span className={styles.labelText}>{label}</span>
          </label>
        )}
        {error && <span className={styles.errorText}>{error}</span>}
      </div>

      <div className={styles.inputWrapper}>
        {icon && <span className={styles.inputIcon}>{icon}</span>}
        
        {type === "select" ? (
          <select
            name={name}
            value={value}
            onChange={onChange}
            disabled={readOnly}
            className={`${styles.select} ${icon ? styles.inputWithIcon : ""} ${error ? styles.error : ""}`}
            style={inputStyle} // Apply custom input styles
          >
            <option value="">Select</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            className={`${styles.input} ${icon ? styles.inputWithIcon : ""} ${error ? styles.error : ""}`}
            style={inputStyle} // Apply custom input styles
          />
        )}
      </div>
    </div>
  );
};

export default InputField;
