import {
  LastingWeightLossIcon,
  StrongerLeanerBodyIcon,
  HeartBrainHealthIcon,
  MoreEnergyLessStressIcon,
  MakeActivityHabitIcon,
  HealthierFoodChoicesIcon,
  BetterBloodPressureIcon,
  BetterBloodSugarIcon
} from '../../serverVariables/progressIcons';

export const mapHabitAverages = (habits) => {
  if (!habits) return [];
  
  const standardHabits = [
    'sleep',
    'physical activity',
    'vegetables & fruit',
    'sweetened drinks',
    'sweetened foods',
    'eating occasions',
    'protein servings',
    'mealtime spacing',
    'strength building activity',
    'sprint intensity',
    'stress releasing activity'
  ];

  return habits.map(habit => {
    const value = habit.average_value || 0;
    let progress = 0;
    const habitTitle = habit.tile.toLowerCase();
    const scaleEnd = habit.scale_end || 0;
    
    // Check if this is a custom habit
    const isCustom = !standardHabits.includes(habitTitle);
    
    // For habits where less is better
    const isInverseHabit = ['sweetened drinks', 'sweetened foods', 'eating occasions'].includes(habitTitle);
    
    if (isInverseHabit) {
      // For inverse habits, 0 is 100% progress, and scale_end is 0% progress
      progress = value === 0 ? 100 : Math.max(0, 100 - ((value / scaleEnd) * 100));
    } else {
      // For regular habits, progress is the percentage of scale_end achieved
      progress = scaleEnd > 0 ? (value / scaleEnd) * 100 : 0;
    }

    return {
      ...habit,
      title: habit.tile,
      progress: progress !== null ? Math.min(100, Math.max(0, progress)) : null,
      isCustom,
      unit: habit.unit || 'min'
    };
  });
};

export const getHabitColor = (habitTitle) => {
  const title = habitTitle.toLowerCase();
  switch(title) {
    case 'sleep':
    case 'physical activity':
      return '#4E88A1'; // Blue
    case 'mealtime spacing':
    case 'eating occasions':
      return '#EEBF38'; // Yellow
    case 'vegetables & fruit':
    case 'sweetened drinks':
    case 'protein':
    case 'sweetened foods':
      return '#6BA543'; // Green
    default:
      return '#4E88A1'; // Blue
  }
};

export const getHeaderBackground = (monthlyScore) => {
  if (!monthlyScore) return ['#FF8C29', '#FFD369'];
  const score = Math.ceil((monthlyScore / 1500) * 100);

  if (score >= 0 && score < 50) {
    return ['#ff182d', '#ff9429'];
  } else if (score >= 50 && score < 90) {
    return ['#ff8c29', '#ffd369']; 
  } else {
    return ['#399c39', '#87c33f'];
  }
};

export const getHealthGoalIcon = (goalName) => {
  switch (goalName) {
    case "Lasting Weight Loss":
      return LastingWeightLossIcon;
    case "Build a Stronger Leaner Body":
      return StrongerLeanerBodyIcon;
    case "Improve Heart and Cognitive Health":
      return HeartBrainHealthIcon;
    case "More Energy, Less Stress":
      return MoreEnergyLessStressIcon;
    case "Make Physical Activity a Regular Habit":
      return MakeActivityHabitIcon;
    case "Healthier Food Choices":
      return HealthierFoodChoicesIcon;
    case "Better Blood Pressure":
      return BetterBloodPressureIcon;
    case "Better Blood Sugar":
      return BetterBloodSugarIcon;
    default:
      return null;
  }
};