import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

// styles
import styles from "./contentThumbnail.module.css";

// actions
import { recordContentView } from "../../../store/actions/pathwaysActions";
import { checkAlerts } from "../../../store/actions/alertActions";
import {
  getDailyScore,
  setChangedScoreFlag,
} from "../../../store/actions/wellnessScoreActions";
import { setContentFavorite } from "../../../store/actions/contentActions";

// components
import { ReactComponent as PlayIcon } from "../../../images/play_2.svg";
import { ReactComponent as AudioIcon } from "../../../images/headphones.svg";
import { ReactComponent as PdfIcon } from "../../../images/file-pdf-solid.svg";
import { ReactComponent as LinkIcon } from "../../../images/external-link-alt-solid.svg";
import { ReactComponent as WatchedIcon } from "../../../images/icons/Icon_Watched.svg";
import LoveReaction from "../../community/loveReaction/loveReaction";
import { PrimaryButtonSm } from "../../buttons/buttons";

const ContentThumbnail = (props) => {
  // props
  const {
    id,
    title,
    img,
    to,
    length,
    format,
    externalWarning,
    isNew,
    className,
    favorite,
    preventRedirect,
    collectionLabel,
    contentCount,
    watched,
  } = props;
  // actions
  const { checkAlerts, getDailyScore, setChangedScoreFlag } = props;
  // local
  const [warning, setWarning] = useState(false);
  const [isFav, setIsFav] = useState(favorite);
  const [height, setHeight] = useState(0);
  const thumbnailRef = useRef();

  useEffect(() => {
    watchWidth();
    window.addEventListener("resize", watchWidth);
    return () => window.removeEventListener("resize", watchWidth);
  }, [watchWidth]);

  function watchWidth() {
    if (!thumbnailRef.current) return;
    const w = thumbnailRef.current.clientWidth * 0.75;
    setHeight(w);
  }

  const chooseFormat = (val) => {
    if (val === "audio")
      return (
        <div className={styles.audio}>
          <AudioIcon />
        </div>
      );
    if (val === "video")
      return (
        <div className={styles.play}>
          <PlayIcon />
        </div>
      );
    if (val === "pdf")
      return (
        <div className={styles.pdf}>
          <PdfIcon />
        </div>
      );
    if (val === "link")
      return (
        <div className={styles.link}>
          <LinkIcon />
        </div>
      );
  };

  const showNew = () => {
    if (isNew) {
      return (
        <span className={styles.new}>
          <span className={styles.newDot} />
          New
        </span>
      );
    }
  };

  async function recordView(contentId) {
    const res = await recordContentView(contentId);
    if (res && res.result.alert_waiting) {
      setChangedScoreFlag(parseInt(res.result.score.score));
      checkAlerts();
      getDailyScore();
    }
  }

  async function makeFavorite(contentId) {
    setIsFav(!isFav);
    setContentFavorite(contentId, format === "collection");
  }

  if (format === "link" && preventRedirect) {
    return (
      <div className="cur:p">
        <div
          ref={thumbnailRef}
          className={`${className} ${styles.container}`}
          data-testID="audio-video-tile-link-pr"
        >
          <Link to={to} className={styles.overlayLink} />
          <div
            className={styles.thumbnail}
            style={{ backgroundImage: `url(${img})`, height: `${height}px` }}
          />
          <LoveReaction
            callback={() => makeFavorite(id)}
            active={isFav}
            color="transparent"
            stroke="#ffffff"
            animatedColor="#e5466c"
            animatedStroke="#transparent"
            className={styles.icon}
          />
          {showNew()}
          <h5 className={styles.title}>{title}</h5>
          <span className={styles.length}>
            {chooseFormat(format)}
            {length ? <>{length} min</> : null}
            {watched ? <WatchedIcon className={styles.watched} /> : null}
          </span>
        </div>
        <Modal
          show={warning}
          onHide={() => setWarning(false)}
          className={styles.warning}
        >
          <div className="padding-15 t-a:c">
            <p>
              <i className="fas fa-exclamation-triangle m-r:1" />
              External links are disabled for this kiosk display. Sign up today
              and get full access on your own device.
            </p>
            <PrimaryButtonSm onClick={() => setWarning(false)}>
              Close
            </PrimaryButtonSm>
          </div>
        </Modal>
      </div>
    );
  }

  if (format === "link" && externalWarning) {
    return (
      <div className="cur:p">
        <div
          ref={thumbnailRef}
          className={`${className} ${styles.container}`}
          data-testID="audio-video-tile-link-EW"
        >
          <Link to={to} className={styles.overlayLink} />
          <div
            className={styles.thumbnail}
            style={{ backgroundImage: `url(${img})`, height: `${height}px` }}
          />
          <LoveReaction
            callback={() => makeFavorite(id)}
            active={isFav}
            color="transparent"
            stroke="#ffffff"
            animatedColor="#e5466c"
            animatedStroke="#transparent"
            className={styles.icon}
          />
          {showNew()}
          <h5 className={styles.title}>{title}</h5>
          <span className={styles.length}>
            {chooseFormat(format)}
            {length ? <>{length} min</> : null}
            {watched ? <WatchedIcon className={styles.watched} /> : null}
          </span>
        </div>
        <Modal
          show={warning}
          onHide={() => setWarning(false)}
          className={styles.warning}
        >
          <div className="padding-15 t-a:c">
            <p>
              <i className="fas fa-exclamation-triangle" /> Following this link
              opens a new browser tab and sends you to a website outside of the
              Treo Platform
            </p>
            <a
              className={styles.primaryLink}
              href={to}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setWarning(false);
                recordView(id);
              }}
            >
              Continue
            </a>
          </div>
        </Modal>
      </div>
    );
  }

  if (format === "link") {
    return (
      <div>
        <div
          ref={thumbnailRef}
          className={`${className} ${styles.container}`}
          data-testID="audio-video-tile-link"
        >
          <a
            href={to}
            className={styles.overlayLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => recordView(id)}
          />
          <div
            className={styles.thumbnail}
            style={{ backgroundImage: `url(${img})`, height: `${height}px` }}
          />
          <LoveReaction
            callback={() => makeFavorite(id)}
            active={isFav}
            color="transparent"
            stroke="#ffffff"
            animatedColor="#e5466c"
            animatedStroke="#transparent"
            className={styles.icon}
          />
          {showNew()}
          <h5 className={styles.title}>{title}</h5>
          <span className={styles.length}>
            {chooseFormat(format)}
            {length ? <>{length} min</> : null}
            {watched ? <WatchedIcon className={styles.watched} /> : null}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div ref={thumbnailRef} className={`${className} ${styles.container}`}>
      <Link to={to} className={styles.overlayLink} />
      <div
        className={styles.thumbnail}
        style={{ backgroundImage: `url(${img})`, height: `${height}px` }}
      />
      <LoveReaction
        callback={() => makeFavorite(id)}
        active={isFav}
        color="transparent"
        stroke="#ffffff"
        animatedColor="#e5466c"
        animatedStroke="#transparent"
        className={styles.icon}
      />
      {showNew()}
      <h5 className={styles.title}>{title}</h5>
      {format === "collection" ? (
        <span className={styles.collections}>
          {contentCount} {collectionLabel}
        </span>
      ) : (
        <span className={styles.length}>
          {chooseFormat(format)}
          {length ? <>{length} min</> : null}
          {watched ? <WatchedIcon className={styles.watched} /> : null}
        </span>
      )}
    </div>
  );
};

const actions = {
  checkAlerts,
  getDailyScore,
  setChangedScoreFlag,
};

export default connect(null, actions)(ContentThumbnail);
