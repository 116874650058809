import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import queryString from "query-string";
import QRCode from "react-qr-code";

// actions
import {verifyPassword, setupMFA, confirmMFA} from '../../store/actions/authActions';
import {fetchSelf} from "../../actions/action_user";

// styles
import styles from "./mfaSetup.module.css";
import helpers from '../helperStyles.module.css';
import {colors} from "../colors";

// images & icons
import {ReactComponent as QRIcon} from '../../images/qr_code.svg';
import {ReactComponent as TextBubbleIcon} from '../../images/text_bubble.svg';
import {ReactComponent as CopyIcon} from '../../images/copy_icon.svg';

// components
import {PrimaryButton} from "../buttons/buttons";
import ClientHeader from "../clientHeader/clientHeader";

// components


const SCREENS = {
    PROMPT: 'PROMPT',
    PASSWORD: 'PASSWORD',
    QR_CODE: 'QR_CODE',
    CONFIRM: 'CONFIRM',
    COMPLETE: 'COMPLETE',
}

const MFASetup = props => {
    // props
    const method = queryString.parse(props.location.search).method;
    // actions
    const {fetchSelf} = props;

    // state
    const {user} = props;
    // local
    const [screen, setScreen] = useState(SCREENS.PROMPT);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [secret, setSecret] = useState(null);
    const [confirmation, setConfirmation] = useState('');
    const [confirmError, setConfirmError] = useState(false);
    const [backups, setBackups] = useState(null);

    // reset the state when screen loads
    useEffect(() => {
        setScreen(SCREENS.PROMPT)
    }, []);

    async function submitPassword() {
        const {valid_password} = await verifyPassword(password.trim());
        const result = await setupMFA(method, password);

        if (valid_password) {
            if(method === 'qr_code') {
                setSecret(result);
                setScreen(SCREENS.QR_CODE);
            } else {
                setScreen(SCREENS.CONFIRM);
            }
        } else {
            setPasswordError(true);
        }
    }

    async function submitMFACode() {
        const result = await confirmMFA(confirmation, method);
        if (result.backup_codes) {
            setBackups(result.backup_codes);
            setScreen(SCREENS.COMPLETE)
            fetchSelf();
        } else if (result.error) {
            setConfirmError(true);
        }
    }

    // determine which prompt to show a user based on the incoming mfa method
    function resolveMethod(methodName) {
        if (methodName === 'qr_code') {
            return (
                <>
                    <h5 className={`no-margin ${helpers.textCenter}`}>Use an authenticator app to enable Multi-Factor Authentication</h5>
                    <QRIcon width={60} height={60} fill={colors.grey}/>
                    <div className={helpers.padding_vertical_1}>
                        <div>
                            <h5 className='no-margin'>iOS:</h5>
                            <div onClick={() => window.open('https://apps.apple.com/us/app/twilio-authy/id494168017', '_blank')}>
                                <p>Download Authy from the iOS app store <span className='text-primary'>here</span></p>
                            </div>
                        </div>
                        <div className={helpers.padding_1}/>
                        <div>
                            <h5 className='no-margin'>Android:</h5>
                            <div onClick={() => window.open('https://play.google.com/store/apps/details?id=com.authy.authy&hl=en_US', '_blank')}>
                                <p>Download Authy from the Play Store <span className='text-primary'>here</span></p>
                            </div>
                        </div>
                    </div>
                    <p className={helpers.textCenter}>Once you have downloaded the app of your choice click continue</p>
                    <PrimaryButton onClick={() => setScreen(SCREENS.PASSWORD)}>Continue</PrimaryButton>
                </>
            )
        }
        if (methodName === 'text_message') {
            return (
                <>
                    <h5 className={`no-margin ${helpers.textCenter}`}>Setup Multi-Factor Authentication with SMS Texting</h5>
                    <TextBubbleIcon width={70} height={47} fill={colors.grey}/>
                    <p className={helpers.textCenter}>A text will be sent to the phone number ending in {String(user.phone).substring(6)}</p>
                    <PrimaryButton onClick={() => setScreen(SCREENS.PASSWORD)}>Continue</PrimaryButton>
                </>
            )
        }
        if (methodName === 'email') {
            return (
                <>
                    <h5 className={`no-margin ${helpers.textCenter}`}>Setup Multi-Factor Authentication with Email</h5>
                    <i
                        style={{color: '#ccc', fontSize: '60px'}}
                        className="fa fa-envelope fa-lg"
                    />
                    <p className={helpers.textCenter}>An email will be sent to {user.email} with the 1 time verification
                        code</p>
                    <PrimaryButton onClick={() => setScreen(SCREENS.PASSWORD)}>Continue</PrimaryButton>
                </>
            )
        }
    }

    // form to prompt user to fill out password before continuing
    function resolvePassword() {
        return (
            <div className={styles.passwordForm}>
                <h5 className={`no-margin ${helpers.textCenter}`}>Confirm your password</h5>
                <p className='text-center'>We need to verify your identity  before enabling MFA on your account</p>
                <div>
                    <input type='password' value={password} onChange={e => setPassword(e.target.value)}/>
                    {passwordError ?
                        <p className={helpers.padding_top_1}>The password you entered is incorrect</p>
                        : null
                    }
                </div>
                <PrimaryButton onClick={submitPassword}>Continue</PrimaryButton>
            </div>
        )
    }

    function dashQRSecret(val) {
        let dashed = '';
        val.split('').forEach((char, index) => {
            const plusOne = index+1;
            if (plusOne%4 === 0 && index !== val.length-1 ) {
                dashed += `${char}-`
            } else {
                dashed += char
            }
        });
        return dashed;
    }

    // display a screen with a qr code and secret
    function showQRCode() {
        if(!secret) return;
        return (
            <div className={styles.qrContainer}>
                <h5 className={styles.qrTitle}>Setup Authentication</h5>
                <p className={helpers.textCenter}>Scan the QR code below in your app</p>
                <div className={styles.qrCode}>
                    <QRCode
                        size={200}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={secret.url}
                        viewBox={`0 0 256 256`}
                    />
                </div>
                <div>
                    <p className={helpers.textCenter}>Can't see your code?</p>
                    <p className={helpers.textCenter}>Manually enter the code below into the app (without dashes)</p>
                </div>
                <div className={styles.secret}>
                    <p className={`${helpers.textCenter} ${{flexWrap: 'wrap'}}`}>{dashQRSecret(secret.secret)}</p>
                    <div className={styles.copy} onClick={() => navigator.clipboard.writeText(secret.secret)}>
                        <CopyIcon width={20} height={20} stroke={colors.grey}/>
                    </div>
                </div>
                <PrimaryButton onClick={() => {setScreen(SCREENS.CONFIRM)}}>Continue</PrimaryButton>
            </div>
        )
    }

    // display a screen to confirm a MFA code
    function confirmCode() {
        return (
            <div className={styles.passwordForm}>
                <h5 className={`no-margin`}>Confirm authentication code</h5>
                {method === 'qr_code' ? <p>Enter the code that was generated by your authenticator app below</p> : null}
                {method === 'text_message' ? <p>Enter the code that was sent to your phone below</p> : null}
                {method === 'email' ? <p>Enter the code that was sent to your email below</p> : null}
                <div>
                    <input value={confirmation} onChange={val => setConfirmation(val.target.value)}/>
                    {confirmError ? <p className={helpers.padding_top_1}>The code you entered is incorrect</p> : null}
                </div>
                <PrimaryButton onClick={submitMFACode}>Continue</PrimaryButton>
            </div>
        )
    }

    // show screen indicating successful MFA setup
    function setupComplete() {
        return (
            <div className={styles.qrContainer}>
                <h5 className={styles.qrTitle}>Setup Authentication</h5>
                <p className={helpers.textCenter}>Authentication Successful</p>
                <p className={helpers.textCenter}>Multi-Factor Authentication has been enabled for this account and your
                    login is more secure</p>
                <p className={helpers.textCenter}>Save your recovery codes</p>
                <h5 className={`no-margin ${helpers.textCenter}`}>The codes below can be used one time to access your
                    account if you lose access to the account or device that was used to setup MFA.</h5>
        <div className={styles.codesContainer}>
                    {backups.map(val => <p key={val} className={styles.code}>{val}</p>)}
                    <div className={styles.copy} onClick={() => navigator.clipboard.writeText(backups.toString().replaceAll(',', ', '))}>
                        <p style={{color: colors.greyDark}}>Copy</p>
                        <CopyIcon width={20} height={20} stroke={colors.grey}/>
                    </div>
                </div>
                <PrimaryButton onClick={() => props.history.push('/member/privacy-security')}>Finish</PrimaryButton>
            </div>
        )
    }
    return (
        <>
            <div className="client-container">
                <ClientHeader bypassRedirect backUrl={`/member/privacy-security`}/>
            </div>

            <div className={`${helpers.container} ${helpers.align_items} ${helpers.flexDiv}`}>
                <div className={styles.container}>
                    {screen === SCREENS.PROMPT ? resolveMethod(method) : null}
                    {screen === SCREENS.PASSWORD ? resolvePassword() : null}
                    {screen === SCREENS.QR_CODE ? showQRCode() : null}
                    {screen === SCREENS.CONFIRM ? confirmCode() : null}
                    {screen === SCREENS.COMPLETE ? setupComplete() : null}
                </div>
            </div>
        </>
    )
}

function mapStateToProps({user}) {
    return {user}
}

const actions = {
    fetchSelf,
}

export default connect(mapStateToProps, actions)(MFASetup);