import React from 'react';
import { Link } from 'react-router-dom';
import { isNil } from 'lodash';

// styles
import styles from './clientTile.module.css';

// icons
import {HabitTileIcon} from "../habitTileIcon/habitTileIcon";

const ClientTile = props => {
    // props
    const { tile, openLog } = props;

    const plan = tile.plan.length === 1 ? tile.plan[0].description : 'activity';
    return (
        <div className={styles.tileContainer}>
            <div className={styles.tileBorder}>
                <div className={`${styles.tileTop} tour-tileHeader`} onClick={tile.tile.tile_status_id === 1 ? () => openLog(tile) : () => {}}>
                    <div className={styles.tileIcon}>
                        <HabitTileIcon tile={tile.plan[0].description}/>
                    </div>
                    <div>
                        <h4 className='t-t:c m:0'>{plan}</h4>
                        <p className='t-t:c m:0'>{tile.tile.tile_category}</p>
                    </div>
                </div>
                <div className={styles.tileBottom}>
                    { tile.tile.tile_status_id === 1 ? logFooter(tile, openLog) : staticFooter(tile) }
                </div>
            </div>
        </div>
    )
};

export default ClientTile;

function logFooter(tile, openLog) {
    const red = '#fc6033';
    const yellow = '#efbf39';
    const green = '#74a94f';
    const grey = '#9c9c9c';
    const checkmark = 'fas fa-check-circle';
    const dash = 'fas fa-minus-circle';
    const link = `/member/tile/${tile.tile.id}`;
    
    let iconClass = dash;
    let color = grey;
    let selectedPlan = tile.plan[0];
    if (tile.plan.length > 1) {
        tile.plan.forEach(plan => {
            if (!isNil(plan.todays_wellness_score_earned)) iconClass = checkmark;
            if (plan.score_color === 'red' && color !== yellow && color !== green){
                color = red;
                iconClass = checkmark;
                selectedPlan = plan;
            }
            if (plan.score_color === 'yellow' && color !== green) {
                color = yellow;
                iconClass = checkmark;
                selectedPlan = plan;
            }
            if (plan.score_color === 'green') {
                color = green;
                iconClass = checkmark;
                selectedPlan = plan;
            }
        })
    } else if (tile.plan.length ===1) {
        if (!isNil(selectedPlan.todays_wellness_score_earned)) iconClass = checkmark;
        if (selectedPlan.score_color === 'red'){
            color = red;
            iconClass = checkmark;
        } else if (selectedPlan.score_color === 'yellow') {
            color = yellow;
            iconClass = checkmark;
        } else if (selectedPlan.score_color === 'green') {
            color = green;
            iconClass = checkmark;
        }
    }
    
    return (
        <>
            <i style={{color}} className={`${styles.logIcon} ${iconClass}`}/>
            <div className={styles.logContainer} data-testID="tileContainerlog">
                <Link to={link} className={styles.logScore} >
                    <span style={{color}} className={styles.currentScore}>{color !== grey ? '+' : ''}{selectedPlan.todays_wellness_score_earned || 0}</span>
                    <span className={styles.potentialScore}> /&nbsp;20</span>
                </Link>
                <Link to={link} className={`${styles.button} ${styles.trendsLink}`}>Trends</Link>
                <button onClick={() => openLog(tile)} className={`${styles.button} ${styles.logButton} tour-tileBtn`} data-testID="logButton">Log</button>
            </div>
        </>
    )
}

function staticFooter(tile) {
    return (
        <div  className={styles.logContainer}>
            <Link to={`/member/tile/${tile.tile.id}`} className={`${styles.button} ${styles.trendsLink}`}>Trends</Link>
        </div>
    )
}