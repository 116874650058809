import React, {useState, useRef, useEffect} from 'react';

// styles
import styles from './conversion.module.css';
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";
import { BoxContainer } from '../../../containers/boxContainer/boxContainer';
import MemberAssessmentList from '../memberAssessmentList/MemberAssessmentList';

const Conversion = props => {
    // props
    const {data, filters, date, query, setQuery, coaches} = props;
    // local
    const container = useRef(0);
    const [containerState, setContainerState] = useState(container.current);

    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [data]);

    function onResize() {
        if (container.current) {
            const size =  container.current.clientWidth-280;
            setContainerState(size);
        }
    }

    console.log(data)

    if (!data) {
        return (
            <div>
                <GraphPlaceholder/>
            </div>
        )
    }
    let total = data.total;
    if (!total) total = 1;
    return (
        <div ref={container}>
            <div className={styles.row}>
                <div className={styles.barLabel}>Onboarding - Assessment</div>
                <div className={styles.barContainer}>
                    <div style={{maxWidth: `${(parseInt(containerState)*(data.onboarding/total))}px`}} className={`${styles.bar} ${styles.onboarding}`}/>
                    <span>{data.onboarding} <strong>({Math.floor((data.onboarding/total)*100)}%)</strong></span>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.barLabel}>Active - Not Coached</div>
                <div className={styles.barContainer}>
                    <div style={{maxWidth: `${(parseInt(containerState)*(data.active_not_coached/total))}px`}} className={`${styles.bar} ${styles.notCoached}`}/>
                    <span>{data.active_not_coached} <strong>({Math.floor((data.active_not_coached/total)*100)}%)</strong></span>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.barLabel}>Active - Coached</div>
                <div className={styles.barContainer}>
                    <div style={{maxWidth: `${(parseInt(containerState)*(data.coached/total))}px`}} className={`${styles.bar} ${styles.coached}`}/>
                    <span>{data.coached} <strong>({Math.floor((data.coached/total)*100)}%)</strong></span>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.barLabel}>Total</div>
                <div className={styles.barContainer}>
                    <div style={{maxWidth: `${data.total > 0 ? containerState : 0}px`}} className={`${styles.bar} ${styles.total}`}/>
                    <span>{data.total}</span>
                </div>
            </div>

            <div className={styles.exportRow} data-testID='member-goal-habit'>
                    <MemberAssessmentList filters={filters}  date={date} query={query} setQuery={setQuery} coaches={coaches}/>
            </div>
        </div>
    )
}

export default Conversion;