import React from "react";
import PortalCard from "../portalCard/PortalCard";
import styles from "./PortalTopContent.module.css";
import Loading from "../Loading/Loading";

// Helper function: convert seconds to "X min Y sec" format.
const formatDuration = (seconds) => {
  if (!seconds) return "";
  const sec = parseInt(seconds, 10);
  const minutes = Math.floor(sec / 60);
  const remainingSeconds = sec % 60;
  return `${minutes} min ${remainingSeconds} sec`;
};

function portalTopContent({
  data,
  onSortChange,
  currentSort,
  categories,
  selectedCategory,
  onCategoryChange,
  searchText,
  onSearchChange,
  onSearchSubmit,
  currentPage,
  totalPages,
  onPageChange,
  loading,
}) {
  // Ensure that data and categories are arrays.
  const safeData = Array.isArray(data) ? data : [];
  const safeCategories = Array.isArray(categories) ? categories : [];
  // Fallback for selectedCategory if undefined.
  const currentCat = selectedCategory || "All Categories";

  // When a header is clicked, toggle the sort order for that key.
  const handleSort = (key) => {
    // If the current sort key isn't the same as the clicked key,
    // reset the sort to descending immediately.
    if (!currentSort || !currentSort.startsWith(key)) {
      onSortChange(`${key}-desc`);
    } else {
      // Otherwise, toggle the sort order.
      const newSort =
        currentSort === `${key}-desc` ? `${key}-asc` : `${key}-desc`;
      onSortChange(newSort);
    }
  };

  // Helper to display sort indicator.
  // If no currentSort exists, default to a down arrow ("▼") for all columns.
  const getSortIndicator = (key) => {
    if (!currentSort || !currentSort.startsWith(key)) {
      return "▼";
    }
    return currentSort.endsWith("asc") ? "▼" : "▲";
  };

  return (
    <PortalCard className={styles.portalCard}>
      {loading && (
        <div className={styles.tableLoadingOverlay}>
          <Loading />
        </div>
      )}
      <h2 style={{ fontSize: "1.4rem", marginBottom: "1rem" }}>Top Content</h2>
      {/* Search, Category Dropdown & Search Button */}
      <div className={`${styles.content} ${!loading ? styles.loaded : ""}`}>
        <div className={styles.filters} style={{}}>
          <input
            type="text"
            placeholder="Search Content"
            value={searchText}
            onChange={(e) => onSearchChange(e.target.value)}
            className={styles.searchInput}
            style={{ fontSize: "1.1rem", padding: "9px", minWidth: "150px" }}
          />
          <select
            id="categorySelect"
            value={currentCat}
            onChange={(e) => onCategoryChange(e.target.value)}
            className={styles.filterSelect}
            style={{ minWidth: "150px", fontSize: "1.1rem", padding: "9px" }}
          >
            <option value="All Categories">All Categories</option>
            {safeCategories.map((cat) => (
              <option key={cat.category_id} value={cat.category_id}>
                {cat.category_name || ""}
              </option>
            ))}
          </select>
          <div>
            <button
              onClick={onSearchSubmit}
              className={styles.searchButton}
              style={{ fontSize: "1.1rem", padding: "10px" }}
            >
              Search
            </button>
          </div>
        </div>

        {/* Table */}
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr
                className={styles.headerRow}
                style={{ fontSize: "1.1rem", textAlign: "center" }}
              >
                <th className={styles.thumbnailColumn}>Thumbnail</th>
                <th className={styles.thCell}>Content</th>
                <th
                  className={styles.thCell}
                  onClick={() => handleSort("access")}
                  style={{ cursor: "pointer" }}
                >
                  Access{" "}
                  <span style={{ fontSize: "8px" }}>
                    {" "}
                    {getSortIndicator("access")}{" "}
                  </span>
                </th>
                <th
                  className={styles.thCell}
                  onClick={() => handleSort("duration")}
                  style={{ cursor: "pointer" }}
                >
                  Avg. Duration{" "}
                  <span style={{ fontSize: "8px" }}>
                    {" "}
                    {getSortIndicator("duration")}{" "}
                  </span>
                </th>
                <th
                  className={styles.thCell}
                  onClick={() => handleSort("views")}
                  style={{ cursor: "pointer" }}
                >
                  <span>Views</span>{" "}
                  <span style={{ fontSize: "8px" }}>
                    {" "}
                    {getSortIndicator("views")}{" "}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "1.1rem" }}>
              {safeData.map((item, index) => (
                <tr
                  key={item.id || index}
                  className={
                    index % 2 === 0 ? styles.tbodyRowEven : styles.tbodyRowOdd
                  }
                >
                  <td className={styles.thumbnailColumn}>
                    {item.thumbnail ? (
                      <img
                        src={item.thumbnail}
                        alt={item.title}
                        className={styles.thumbnail}
                      />
                    ) : (
                      "No Image"
                    )}
                  </td>
                  <td className={styles.tdCell}>{item.title || ""}</td>
                  <td className={styles.tdCell}>{item.access || 0}</td>
                  <td className={styles.tdCell}>
                    {formatDuration(item.average_duration)}
                  </td>
                  <td className={styles.tdCell}>{item.views || 0}</td>
                </tr>
              ))}
              {safeData.length === 0 && (
                <tr>
                  <td colSpan="5" className={styles.noData}>
                    No data found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div
          className={styles.pagination}
          style={{ fontSize: "1.1rem", textAlign: "center", marginTop: "1rem" }}
        >
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`${styles.paginationButton} ${
              currentPage === 1 ? styles.disabledButton : styles.enabledButton
            }`}
          >
            Previous
          </button>
          <span className={styles.pageInfo}>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`${styles.paginationButton} ${
              currentPage === totalPages
                ? styles.disabledButton
                : styles.enabledButton
            }`}
          >
            Next
          </button>
        </div>
      )}
    </PortalCard>
  );
}

export default portalTopContent;
