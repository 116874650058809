import React from 'react';
import { format } from 'date-fns';
import { ReactComponent as ActivityEarningsIcon } from '../../../images/icons/Activity_Earnings_Icon.svg';
import styles from '../UserProgress.module.css';

// Activity Earnings Tile Component for Progress Page
export const ActivityEarnings = ({
  selectedDate,
  engagingEarnings,
  loggingEarnings,
  bonusEarnings
}) => {
  return (
    <div className={styles.activityEarningsTile}>
      <div className={styles.iconHabitBackground} style={{ backgroundColor: 'rgba(255, 190, 0, 0.15)' }} />
      <div className={styles.iconHabit}>
        <ActivityEarningsIcon width={26} height={20} />
      </div>
      <span className={`${styles.earningsTileTitle} ${styles['fw-600']}`}>{format(selectedDate, 'MMMM')} Activity Earnings</span>
      
      <div className={styles.earningsRow}>
        <div className={styles.earningItem}>
          <span className={`${styles.earningLabel} ${styles['fw-600']}`}>Engaging</span>
          <span className={styles.earningSubLabel}>With Content</span>
          <span className={`${styles.earningValue} ${styles['fw-700']}`} style={{ color: '#6BA543' }}>{engagingEarnings} pts.</span>
        </div>
        
        <div className={styles.divider} />
        
        <div className={styles.earningItem}>
          <span className={`${styles.earningLabel} ${styles['fw-600']}`}>Logging</span>
          <span className={styles.earningSubLabel}>Daily Habits</span>
          <span className={`${styles.earningValue} ${styles['fw-700']}`} style={{ color: '#4E88A1' }}>{loggingEarnings} pts.</span>
        </div>
        
        <div className={styles.divider} />
        
        <div className={styles.earningItem}>
          <span className={`${styles.earningLabel} ${styles['fw-600']}`}>Bonus</span>
          <span className={styles.earningSubLabel}>Points</span>
          <span className={`${styles.earningValue} ${styles['fw-700']}`} style={{ color: '#f7b637' }}>{bonusEarnings} pts.</span>
        </div>
      </div>
    </div>
  );
};
