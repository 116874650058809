import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

// styles
import styles from './codeVerification.module.css';

// actions
import {login} from "../../../store/actions/authActions";
import {BasicButton, PrimaryButton} from "../../buttons/buttons";
import {STATUSES} from "../../../serverVariables/statuses";

// images & icons

// components

const CodeVerification = props => {
    //props
    const {history, location} = props;
    // actions
    const {login} = props;
    // state
    const {multifactor, appState, user} = props;
    // local
    const [saveLogin, setSaveLogin] = useState(false);
    const [code, setCode] = useState("");
    const [disableLogin, setDisableLogin] = useState(false);
    const [loginError, setLoginError] = useState('');
    const disableSendAgain = useRef(0)
    const [disableSendAgainState, setDisableSendAgainState] = useState(0);

    // forward a user to the appropriate place
    useEffect(() => {
        if (appState.appReady && !appState.isLoggedIn && location && location.state && location.state.from && location.state.from.search) {
            history.push({search: location.state.from.search});
        }
        if (location.state && window.location.href.lastIndexOf('localhost') > 0) return;
        if (user && appState.isLoggedIn && appState.appReady) {
            if(user.permission_id === 1){
                if(user.user_status_id < STATUSES.onboarding_coach_consult_pending){
                    //client is in onboarding
                    history.push({
                        pathname : `/member/onboard-assessment`,
                    });
                }else{
                    // Forward to dashboard
                    let url = { pathname: `/member/clientDash/${user.username}` };

                    if (location.state && location.state.from && location.state.from.search){
                        url.search = location.state.from.search;
                    }

                    if (location.state && location.state.from) {
                        const fromPathname = location.state.from.pathname;

                        // Check if the URL matches /account/notifications
                        const pattern = /.*\/account\/notifications/gm;
                        const isNotifications = pattern.test(fromPathname);

                        // Check if the URL matches /member/clientDash/{user.email}/assessments
                        const patternAssessment = /\/member\/clientDash\/[^/]+\/assessments/;
                        const isAssessments = patternAssessment.test(fromPathname);

                        if (isNotifications) {
                            url = location.state.from;
                        } else if (isAssessments) {
                            url = location.state.from;
                        }
                    }
                    history.push(url);
                }
            }else if(user.permission_id === 2){
                history.push({
                    pathname: '/dashboard/analytics/user'
                });
            }else{
                history.push({
                    pathname:'/dashboard/analytics/user'
                });
            }
        }
    }, [ appState ]);

    useEffect(() => {
        document.body.style.background = '#fff';
        return () => document.body.style.background = null;
    },[]);

    function submit(e) {
        e.preventDefault();
        try {
            setDisableLogin(true);
            setLoginError('');
            login({email: multifactor.email, password: multifactor.password, code, save: saveLogin})
                .catch(e => {
                    if (e.response.data.error) {
                        setLoginError(e.response.data.error);
                        setDisableLogin(false);
                    }
                });
        } catch (e) {
            console.error('invalid');
        }
    }

    // allow user to send another message every 30s
    function sendAgain() {
        if (disableSendAgain.current > 0) return;
        disableSendAgain.current = 60;
        const interval = setInterval(() => {
            disableSendAgain.current -= 1;
            setDisableSendAgainState(disableSendAgain.current);
            if (disableSendAgain.current < 1) clearInterval(interval);
        }, 1000);
        try {
            setLoginError('');
            login({email: multifactor.email, password: multifactor.password})
                .catch(e => {
                    if (e.response.data.error) {
                        setLoginError(e.response.data.error);
                    }
                });
        } catch (e) {
            console.error('invalid');
        }
    }

    function hideEmail(val) {
        const indexOfAt = val.lastIndexOf('@');
        return `${val[0]}${'*'.repeat(indexOfAt-2)}${val.substring(indexOfAt-1)}`
    }

    function showSendAgain() {
        // only show on text message and email
        if (multifactor.method === 'text_message' || multifactor.method === 'email') {
            // determine what prompt to show if disabled
            if (disableSendAgainState > 0 ) {
                return (
                    <p>A message was sent again. Please wait {disableSendAgainState} seconds to try sending again.</p>
                )
            } else {
                return (
                    <div onClick={sendAgain}>
                        <p className='text-primary'>Didn't receive code? Click to send again.</p>
                    </div>
                )
            }
        }
    }
    return (
        <div className={styles.main}>
            <div className={styles.green}>
                <img className={styles.logo} src="https://treo.s3.us-east-2.amazonaws.com/assets/Logo_White.png"
                     alt="Logo"/>
                <div className={styles.headingContainer}>
                    <h1 className={styles.heading1}>Change is Possible with Treo Wellness</h1>
                    <h2 className={styles.heading2}>We are your whole-person wellbeing app designed to help you discover
                        your healthy lifestyle. Get started today!</h2>
                </div>
            </div>
            <div className={styles.white}>
                <div className={styles.formSelector}>
                    <form onSubmit={submit} className={styles.formContainer}>
                        <div className={styles.form}>
                            <div>
                                <p className={styles.formTitle}>Two-Step Verification</p>
                                {multifactor.method === 'text_message' ?
                                    <p>Multifactor security has been added this account. Please enter the One Time Password
                                        (OTP) that was sent to your phone ending in {multifactor.phone}</p> : null}
                                {multifactor.method === 'email' ? <p>Multifactor security has been added this account. Please enter the One Time Password (OTP) that was sent to your email {hideEmail(multifactor.email)}</p> : null}
                                {multifactor.method === 'qr_code' ? <p>Multifactor security has been added this account. Please enter the One Time Password (OTP) that was generated with your authenticator app.</p> : null}
                            </div>
                            <div>
                                <label htmlFor="email">One Time Password</label>
                                <input type="text" id='code' name='code' value={code}
                                       onChange={e => setCode(e.target.value)}/>
                                {loginError ? <span className={'text-danger'}>{loginError}</span> : null}
                                <div className={styles.save}>
                                    <input className={styles.saveInput} type="checkbox" checked={saveLogin} onChange={e => setSaveLogin(e.target.checked)}/>
                                    <span>Remember this device for 30 days</span>
                                </div>
                            </div>
                            <div>
                                <div className={styles.formSubmit}>
                                    <BasicButton type='button' onClick={() => history.push('/')}>Back</BasicButton>
                                    <PrimaryButton disabled={disableLogin} type='submit' onClick={submit}>Submit</PrimaryButton>
                                </div>
                                {showSendAgain()}
                                <div className={styles.backupButton}>
                                    <button type='button' onClick={() => history.push('/mfa-backup-code')}>Lost your device? Use a one time code.</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps({multifactor, appState, user}) {
    return {multifactor, appState, user}
}

const actions = {
    login,
};

export default withRouter(connect(mapStateToProps, actions)(CodeVerification));