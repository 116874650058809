import {MULTIFACTOR_RESET, MULTIFACTOR_UPDATE} from "../storeConstants";

const initialState = {
    email: '',
    password: '',
    method: '',
    phone: '',
}

export function multifactorReducer(state = initialState, action) {
    switch (action.type) {
        case MULTIFACTOR_UPDATE:
            return action.payload;
        case MULTIFACTOR_RESET:
            return {...initialState};
        default:
            return state;
    }
}