import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { isNil } from "lodash";

// styles
import styles from "./clientResult.module.css";

// components
import PhotoContainer from "../profilePhotos/container";
import { RANKS_IDS } from "../../serverVariables/ranks";

const ClientResult = (props) => {
  // props
  const { client } = props;
  function showRank() {
    let style;
    if (!client.game) return;
  
    if (client.game.rank_id === RANKS_IDS.BRONZE) {
      style = styles.bronze;
    }
    if (client.game.rank_id === RANKS_IDS.SILVER) {
      style = styles.silver;
    }
    if (client.game.rank_id === RANKS_IDS.GOLD) {
      style = styles.gold;
    }
    if (client.game.rank_id === RANKS_IDS.PLATINUM) {
      style = styles.platinum;
    }

    return (
      <div
        className={[
          styles.rank,
          style,
        ].join(" ")}
      >
        {client.game.rank_name}
      </div>
    );
  }
  return (
    <Link
      to={`/client/${client.username}/overview`}
      className={styles.container}
      data-testID='member'
    >
      {showRank()}
      <div>
        <PhotoContainer
          image={client.profileImage}
          score={client.wellnessScore.today}
          username={client.username}
          url_path={`client/${client.username}/overview`}
        />
      </div>
      <div className={styles.profile}>
        <p className={styles.name}>
          {client.firstName}&nbsp;{client.lastName}
          {client.client && client.client.demo_member ? (
            <span className="badge-warning m-l:1">Demo Account</span>
          ) : null}
        </p>
        <p className={styles.org}>
          {client.organization ? (
            <span>
              {client.organization.name} ({client.organization.id})
            </span>
          ) : (
            "(no org)"
          )}
        </p>
        <p className={styles.meta}>
          <span className={styles.label}>Last login</span>
          <span>
            {client.lastLogin
              ? moment(client.lastLogin).format("MM/DD/YY")
              : "-"}
          </span>
        </p>
        <p className={styles.meta}>
          <span className={styles.label}>Last Msg</span>
          <span>
            {client.lastCommunication
              ? moment(client.lastCommunication).format("MM/DD/YY")
              : "-"}
          </span>
        </p>
      </div>
      <div className={styles.break} />
      <div className={styles.averages}>
        <div>
          <h3 className="m:0">
            {!isNil(client.wellnessScore.today)
              ? client.wellnessScore.today
              : "-"}
          </h3>
          <p>Today</p>
        </div>
        <div>
          <h3 className="m:0">
            {!isNil(client.wellnessScore.weekly)
              ? client.wellnessScore.weekly
              : "-"}
          </h3>
          <p>7 day</p>
        </div>
        <div>
          <h3 className="m:0">
            {!isNil(client.wellnessScore.month)
              ? client.wellnessScore.month
              : "-"}
          </h3>
          <p>30 day</p>
        </div>
      </div>
      <div className={styles.lifetime}>
        <div>
          <h3 className="m:0">
            {!isNil(client.wellnessScore.lifetime)
              ? client.wellnessScore.lifetime
              : "-"}
          </h3>
          <p>Lifetime</p>
        </div>
      </div>
    </Link>
  );
};

export default ClientResult;
