import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';

// actions
import {fetchActivePolicies, fetchPolicyVersion} from "../../store/actions/policyActions";

// styles
import styles from './privacyPolicy.module.css';

// components
import {links} from "../signUp/termsOfService/additionalLinks";
import {BasicButton} from "../buttons/buttons";
import Loading from "../Loading/Loading";

const PrivacyPolicy = props => {
    // local
    const [currentVersion, setCurrentVersion] = useState({text: ''});

    useEffect(() => {
        document.body.style.background = '#fff';
        return () => document.body.style.background = null;
    },[]);

    useEffect(() => {
        async function prepare() {
            let versionId;
            try {
                const TOS = await fetchActivePolicies();
                TOS.forEach(val => {
                    if (val.title === 'Privacy Policy') versionId = val.active_version_id;
                })
                console.log(TOS);
                const version = await fetchPolicyVersion(versionId);
                setCurrentVersion(version[0]);

            } catch (e) {
                console.log(e);
            }
        }

        prepare();
    }, []);

    return (
        <div className={styles.main}>
            <div className={styles.green}>
                <img className={styles.logo} src="https://treo.s3.us-east-2.amazonaws.com/assets/Logo_White.png"
                     alt="Logo"/>
            </div>

            {!currentVersion.text ?
                <div className='padding-standard'> <Loading/> </div>
                :
                <div className='container'>
                    <h1 className={styles.heading}>Privacy Policy</h1>

                    <div
                        className={styles.text}
                        dangerouslySetInnerHTML={{__html: currentVersion.text}}
                    />

                    <div className='text-center'>
                        <BasicButton onClick={() => props.history.push('/')}>Home</BasicButton>
                    </div>
                </div>
            }
        </div>
    )
}

export default withRouter(PrivacyPolicy);