import React, {useState} from 'react';
import {connect} from 'react-redux';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import {Modal} from "react-bootstrap";


// styles
import styles from './badgePath.module.css';

// images
import {ReactComponent as Path} from '../../../images/BadgesPathway.svg';
import {ReactComponent as Path_Faded} from '../../../images/BadgesPathway_Faded.svg';
import {ReactComponent as PathCenter} from '../../../images/BadgesPathwayCenter.svg';
import {ReactComponent as PathCenter_Faded} from '../../../images/BadgesPathwayCenter_Faded.svg';
import {ReactComponent as BadgeBackground} from '../../../images/BadgeBackground.svg';
import {ReactComponent as BackgroundLeft} from '../../../images/BadgesPathwayBackground_Left26.svg';
import {ReactComponent as BackgroundRight} from '../../../images/BadgesPathwayBackground_RightA12.svg';
import {ReactComponent as BackgroundRight2} from '../../../images/BadgesPathwayBackground_RightB10.svg';
import {ReactComponent as FlagIcon} from "../../../images/flag.svg";
import {ReactComponent as FlagLeftIcon} from "../../../images/flag_left.svg";

// components
import Badge from "../../badge/badge";
import SingleBadge from "../singleBadge/singleBadge";
import {RANKS_IDS} from "../../../serverVariables/ranks";
import Ranks from "../Ranks/ranks";

const BadgePath = props => {
    // props
    const {data, changeRank, rank, rankName} = props;
    // local
    const [show, setShow] = useState({show: false});


    function openBadge(level) {
        setShow({
            reactions: level.reactions,
            name: level.name,
            earned: level.achievement_date,
            score: level.month_score ? level.month_score : 'New',
            description: level.description,
            show: true,
        });
    }

    function closeBadge() {
        setShow({
            ...show,
            show: false,
        });
    }

    function previousBadge(val) {
        if (val === RANKS_IDS.SILVER) return 'Bronze';
        if (val === RANKS_IDS.GOLD) return 'Silver';
        if (val === RANKS_IDS.PLATINUM) return 'Gold';
    }

    if (!data ||
        (data && !data.levels) ||
        (data && data.levels && !data.levels.filter(val => val.achievement_date).length)
    ) {
        return (
            <>
                <Ranks setRank={changeRank} rank={rank} rankName={rankName}/>
                <div className={styles.rankContainer}>
                    <img className={styles.rankLocked} src={'https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Locked200x200.png'} alt="rank"/>
                    <p className={styles.rankText}>Unlock all the badges in the {previousBadge(data.rank.id)} Rank to unlock the {data.rank.name} Rank</p>
                </div>
            </>
        )
    }

    return (
       <>
           <Ranks setRank={changeRank} rank={rank} rankName={rankName}/>
           <div className={styles.container}>
               {/* Modal */}
               <Modal show={show.show} onHide={closeBadge}>
                   <div className={styles.window}>
                       <div className={`${styles.exit}`} onClick={closeBadge}>&times;</div>
                       <SingleBadge badge={show}/>
                   </div>
               </Modal>

               {rank === RANKS_IDS.BRONZE ?
                   <Path className={`${styles.path} ${styles.offsetPath}`}/>
                   :
                   <Path_Faded className={`${styles.path} ${styles.offsetPath}`}/>
               }
               {rank === RANKS_IDS.BRONZE ?
                   <PathCenter className={styles.path}/>
                   :
                   <PathCenter_Faded className={styles.path}/>
               }
               <BackgroundLeft className={styles.backgroundLeft}/>
               <BackgroundRight className={styles.backgroundRight}/>
               <BackgroundRight2 className={styles.backgroundRight2}/>
               <div className={styles.badgeContainer} data-testID="badgePathTrail">
                   {data.levels.map((level, i) => {
                       let position = styles._center;
                       let direction = 'right';
                       if (i%2 === 1) {
                           position = styles._left;
                           direction = 'left';
                       }
                       if (i%2 === 0) {
                           position = styles._right;
                       }
                       if (i === 12) {
                           position = styles._center2;
                           direction = 'center';
                       }
                       if (i === 0) {
                           position = styles._center;
                           direction = 'center';
                       }

                       return (
                           <div id={level.name.toLowerCase()} key={level.name} className={`${styles.badgeRow} ${position} ${level.unlocked ? 'unlocked':''}`}>
                               {level.unlocked ?
                                   <Badge className={styles.image} badge={level.name} onClick={() => openBadge(level)}/>
                                   : <img onClick={() => openBadge({})} className={styles.image} src={'https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Locked200x200.png'} alt=""/>
                               }
                               <BadgeBackground className={styles.underlay}/>
                               {level.unlocked ?
                                   <div className={`${styles.flag} ${direction === 'left' ? styles._left : direction === 'right' ? '' : styles._center}`}>
                                       <div className={styles.earned}>
                                           <div><strong>{level.name}</strong></div>
                                           <div>Earned {format(parseISO(level.achievement_date), 'MM/dd/yy')}</div>
                                       </div>
                                       {direction === 'right' || direction === 'center' ?
                                           <FlagIcon className={`${styles.triangle}`}/>
                                           :
                                           <FlagLeftIcon className={`${styles.triangle}`}/>
                                       }
                                   </div>
                                   : null
                               }
                           </div>
                       )
                   })}
               </div>
           </div>
       </>
    )
}

function mapStateToProps({user}){
    return {user}
}

export default connect(mapStateToProps)(BadgePath);