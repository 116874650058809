import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getISODay } from 'date-fns'

// styles
import styles from './clientTileDashboard.module.css';

// components
import ClientTile from "./clientTile";

const dayOfWeekMap = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
};

const ClientTileDashboard = props => {
    // props
    const { pathwayLink, tiles, openLog } = props;
    return (
        <div>
            <div className={styles.heading}>
                <h4 className={styles.focus} data-testID="Display-Day-Focus">{dayOfWeekMap[getISODay(new Date())]}'s Focus</h4>
                { pathwayLink ? <Link to={pathwayLink} className={styles.healthGoal} data-testID="health-goal-myHabit">My Health Goal</Link> : null}
            </div>
            <div className={styles.tileContainer} data-testID="My-Habit-Tiles">
                { tiles.map((tile, i) => {
                    return (
                        <div key={tile.tile.id}  className={`${styles.tile} ${i===0? 'tour-tile' : ''}`} data-testID={`habit-tile-${i}`}>
                            <ClientTile tile={tile} openLog={openLog}/>
                        </div>
                    )
                }) }
            </div>
        </div>
    )
};

export default connect()(ClientTileDashboard);